<template>
  <section class="mt-10">
    <h4 class="text-center text-md-left">{{ label }}</h4>

    <v-divider
      class="mb-5 mt-1 border-opacity-100"
      color="accent"
      thickness="1"
    ></v-divider>
    <DocumentSelectFromList
      :files="files"
      v-model="selected"
    ></DocumentSelectFromList>
    <v-form ref="form" @submit.prevent="submitForm">
      <v-row>
        <v-col cols="12" md="12">
          <v-file-input
            :rules="fileRules"
            color="accent"
            v-model="file"
            :label="label"
            show-size
            chips
            :multiple="multiple"
          ></v-file-input>
        </v-col>
      </v-row>
      <v-btn block :loading="isLoading" color="grey-darken-3" type="submit"
        >Dokument hochladen</v-btn
      >
    </v-form>
  </section>
</template>

<script>
import ApiService from "@/services/api.service.js";
import DocumentSelectFromList from "@/components/property/documents/DocumentSelectFromList.vue";
import { fetchFilesByTags } from "@/services/property.service.js";
import { useAuthUserStore } from "@/stores/modules/auth.module.js";
import { useNotificationStore } from "@/stores/modules/notification.module";

export default {
  setup() {
    const authStore = useAuthUserStore();
    const hasRole = authStore.hasRole(["ROLE_ADMIN", "ROLE_AGENT"]);
    const notificationStore = useNotificationStore();
    return {
      hasRole,
      notificationStore,
    };
  },
  name: "DocumentUploadAsFormField",
  components: { DocumentSelectFromList },
  props: {
    label: String,
    propertyId: String,
    tags: Array,
    modelValue: Array,
    multiple: Boolean,
  },
  computed: {
    selected: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  data() {
    return {
      files: [],
      file: null,
      isLoading: false,
      fileRules: [
        (value) => {
          return (
            !value || !value.length || value[0].size < 15000000 || "max. 15 MB"
          );
        },
        // (value) => value.length > 0 || "Ein Dokument ist erforderlich",
      ],
    };
  },
  methods: {
    async submitForm() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.uploadFile();
        this.$refs.form.reset();
      }
    },
    async uploadFile() {
      const url = `property/${this.propertyId}/files/upload/sorted`;
      let formData = new FormData();

      if (this.file && Array.isArray(this.file)) {
        for (let i = 0; i < this.file.length; i++) {
          formData.append("files", this.file[i]);
        }
      } else if (this.file) {
        formData.append("files", this.file);
      }
      formData.append("tags", this.tags);
      this.isLoading = true;

      try {
        const response = await ApiService.postWithAdditionalHeader(
          url,
          formData,
          {
            "Content-Type": "multipart/form-data",
          }
        );
        this.files.push(...response.data);
      } catch (error) {
        this.notificationStore.pushError(
          "Fehler beim Hochladen des Dokuments",
          error
        );
      } finally {
        this.isLoading = false;
        this.file = null;
      }
    },
    async fetchFiles() {
      this.files = await fetchFilesByTags(this.propertyId, this.tags);
    },
  },
  created() {
    this.fetchFiles();
  },
};
</script>
