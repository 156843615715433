<template>
  <div class="py-5 d-flex flex-column">
    <div class="d-flex" style="flex-basis: 1fr 1fr; gap: 20px">
      <v-text-field label="Vorname" v-model="user.firstName"></v-text-field>
      <v-text-field label="Nachname" v-model="user.lastName"></v-text-field>
    </div>
    <v-text-field
      v-if="!edit"
      label="Email"
      v-model="user.email"
    ></v-text-field>
    <div class="d-flex justify-space-between" v-else>
      <v-text-field
        hint="Die Email sollte nur im Notfall geändert werden."
        :disabled="editEmail"
        label="Email"
        v-model="user.email"
      ></v-text-field
      ><v-btn
        class="ml-1"
        color="grey-darken-3"
        @click="clickEditEmail"
        icon="mdi-pencil"
      ></v-btn>
    </div>
  </div>
</template>

<script>
import { CreateUserRequestSimple } from "@/models/create/CreateUserRequestSimple";

export default {
  name: "CreateUserSimpleForm",
  props: {
    edit: Boolean,
    modelValue: CreateUserRequestSimple,
  },
  data() {
    return {
      editEmail: true,
    };
  },
  methods: {
    clickEditEmail() {
      // if(this.editEmail)this.editEmail=!this.editEmail
      if (confirm("Willst du wirklich die Email anpassen?"))
        this.editEmail = !this.editEmail;
    },
  },
  emits: ["update:modelValue"],
  computed: {
    user: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
};
</script>

<style scoped></style>
