<template>
<v-dialog v-model="showC" max-width="500px" :close-on-content-click="false">
  <template #activator="{props}">
    <slot name="activator" v-bind="props">
      <v-btn icon v-bind="props"><v-icon>mdi-account-plus</v-icon></v-btn>
    </slot>
  </template>
  <v-card>
    <v-card-title v-text="title"></v-card-title>
    <v-card-text>
      <SelectUser :label="label" :multiple="multiple" v-model="modelValueC">
        <template #no-data>
          <slot name="no-data"></slot>
        </template>
      </SelectUser>
      <slot name="content">

      </slot>
    </v-card-text>
    <v-card-actions>
      <slot name="actions">
      </slot>
    </v-card-actions>
  </v-card>

</v-dialog>
</template>

<script>
import SelectUser from "@/components/form/SelectUser";
export default {
  name: "SelectUserDialog",
  components: {SelectUser},
  props:{
    label:String,
    show:Boolean,
    multiple:Boolean,
    modelValue: Number,
    title:String
  }, emits: ['update:modelValue','update:show'],
  computed:{
    showC:{
      get(){
        return this.show
      },set(value){
        this.$emit('update:show', value);
      }
    },
    modelValueC:{
      get(){
        return this.modelValue
      },set(value){
        this.$emit('update:modelValue', value);
      }
    }
  },
}
</script>

<style scoped>

</style>
