<template>
  <SelectUserDialog
    title="Interessenten finden"
    v-model:show="show"
    v-model="newInterestedUser"
  >
    <template #activator="props">
      <v-btn color="accent" block v-bind="props"
        >Neuen Interessenten hinzufügen</v-btn
      >
    </template>
    <template #content>
      <div v-if="createUser">
        <CreateUserSimpleForm v-model="userToCreate"> </CreateUserSimpleForm>
      </div>
      <NotifyUser v-model="notificationMail" />
      <v-slider
        v-model="buyIntention"
        :max="10"
        :min="1"
        step="1"
        thumb-label="always"
        thumb-size="24"
        label="Kaufabsicht"
      />
      <v-textarea v-model="note" label="Notiz" outlined rows="3" />
    </template>
    <template #no-data>
      <v-list-item>
        <template #title>
          <span>Keinen passenden User in Datenbank gefunden</span>
        </template>

        <v-btn
          size="small"
          class="mt-2 bg-accent"
          block
          @click="createUser = true"
          >Jetzt anlegen</v-btn
        >
      </v-list-item>
    </template>
    <template #actions>
      <v-btn class="bg-accent" block @click="addInterestedUser"
        >Interessent anlegen</v-btn
      >
    </template>
  </SelectUserDialog>
</template>

<script>
import SelectUserDialog from "@/components/form/SelectUserDialog.vue";
import { CreateUserRequestSimple } from "@/models/create/CreateUserRequestSimple";
import CreateUserSimpleForm from "@/components/user/CreateUserSimpleForm.vue";
import NotifyUser from "@/components/mail/NotifyUser.vue";
import NotificationMail from "@/models/mail";
import { addInterestToProperty } from "@/services/saleProcess/interest.service";
import { useNotificationStore } from "@/stores/modules/notification.module";

export default {
  name: "AddInterestToProperty",
  components: { NotifyUser, CreateUserSimpleForm, SelectUserDialog },
  setup() {
    const notificationStore = useNotificationStore();

    return {
      notificationStore,
    };
  },
  data() {
    return {
      userToCreate: new CreateUserRequestSimple({}),
      createUser: false,
      newInterestedUser: null,
      notificationMail: new NotificationMail({}),
      buyIntention: 0,
      note: null,
      show: false,
    };
  },
  props: {
    propertyId: String,
    agent: Boolean,
  },
  methods: {
    async addInterestedUser() {
      if (this.createUser) {
        this.userToCreate.customText = this.notificationMail.text;
        this.userToCreate.sendEmail = this.notificationMail.send;
      }
      try {
        let user = await addInterestToProperty(
          this.propertyId,
          this.newInterestedUser,
          this.buyIntention,
          this.note,
          this.userToCreate,
          this.agent,
          this.notificationMail.send,
          this.notificationMail.text
        );
        this.$emit("newInterestedUser", user);
        this.show = false;
      } catch (error) {
        this.notificationStore.pushError(
          "Es ist ein Fehler aufgetreten.",
          error
        );
      }
    },
  },
};
</script>

<style scoped></style>
