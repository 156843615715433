<script>
import { useAuthUserStore } from "@/stores/modules/auth.module";
import ApiService from "@/services/api.service";

export default {
  name: "DocumentItem",
  props: {
    showIcon: {
      type: Boolean,
      default: true,
    },
    file: Object,
    allTags: Array,
    hideTags: Boolean,
    color: String,
    maxWidth: Number,
  },
  data() {
    return {
      bytes: [],
      newTags: [],
      filterTags: [],
    };
  },
  setup() {
    const authStore = useAuthUserStore();
    const hasRole = authStore.hasRole(["ROLE_ADMIN", "ROLE_AGENT"]);
    return {
      hasRole,
    };
  },
  computed: {
    getIcon() {
      //   console.log("fileType:", fileType);
      switch (this.file.type) {
        case "application/pdf":
          return "mdi-file-pdf-box";
        case "image/png":
          return "mdi-file-image";
        case "image/jpg":
          return "mdi-file-image";
        case "image/jpeg":
          return "mdi-file-image";
        case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
          return "mdi-file-document";
        default:
          return "mdi-file";
      }
    },
  },
  methods: {
    async downloadFile() {
      let id = this.file.id;
      let name = this.file.filename;
      try {
        const response = await ApiService.post(
          "file/get/bytes",
          { text: id },
          { responseType: "blob" }
        );
        const blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", name); // set the file name and extension here
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error(error);
      }
    },
    async saveTags() {
      //   console.log("selectedTags:", this.newTags, id);
      if (!this.file) return;
      try {
        const response = await ApiService.post("file/tags/set", {
          id: this.file.id,
          tags: this.newTags,
        });
        // console.log(this.newTags);
        if (response.status === 200) {
          this.updateFileTags(this.file.id, this.newTags);
        }
      } catch (error) {
        console.error(error);
      }
    },
    updateFileTags(id, newTags) {
      const file = this.file;
      if (file) {
        file.tags = newTags.map((tagKey) =>
          this.allTags.find((tag) => tag.key === tagKey)
        );
      }
    },
    openDialog() {
      this.newTags = this.file.tags.map((tag) => tag.key);
    },
  },
};
</script>

<template>
  <v-list-item
    class="pa-0"
    :color="color"
    :max-width="maxWidth"
    rounded
    :title="file.filename"
  >
    <template v-if="showIcon" v-slot:prepend>
      <v-avatar color="grey-darken-1">
        <v-icon :icon="getIcon" color="white"></v-icon>
      </v-avatar>
    </template>
    <template v-slot:append>
      <v-dialog width="auto" scrollable v-if="hasRole & !hideTags">
        <template v-slot:activator="{ props: activatorProps }">
          <v-btn
            icon="mdi-tag-edit-outline"
            color="grey-darken-3"
            size="small"
            class="mx-2"
            variant="flat"
            v-bind="activatorProps"
            @click="openDialog"
          ></v-btn>
        </template>

        <template v-slot:default="{ isActive }">
          <v-card
            prepend-icon="mdi-tag-outline"
            :title="`Tags für ${file.filename}`"
          >
            <v-divider class="mt-3"></v-divider>

            <v-card-text style="height: 300px">
              <v-checkbox
                v-for="tag of allTags"
                :key="tag.key"
                v-model="newTags"
                :label="tag.name"
                :value="tag.key"
              ></v-checkbox>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-btn text="Abbrechen" @click="isActive.value = false"></v-btn>

              <v-spacer></v-spacer>

              <v-btn
                color="surface-variant"
                text="Speichern"
                variant="flat"
                @click="
                  saveTags;
                  isActive.value = false;
                "
              ></v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
      <v-btn
        icon="mdi-file-download-outline"
        color="grey-darken-2"
        size="x-small"
        class="mx-2"
        variant="flat"
        @click="downloadFile"
      ></v-btn>
    </template>

    <v-chip
      v-for="tag of file.tags"
      :key="tag.key"
      size="x-small"
      color="grey"
      class="mr-1 mb-1"
    >
      {{ tag.name }}
    </v-chip>
  </v-list-item>
</template>

<style scoped></style>
