<template>
  <div>
    <v-tabs v-model="tab" class="mb-5">
      <v-tab color="accent">Im Besitz</v-tab>
      <v-tab color="accent">Beobachtung</v-tab>
      <v-tab color="accent">Entdecken</v-tab>
    </v-tabs>

    <v-window v-model="tab">
      <v-window-item>
        <OwnedProperties />
      </v-window-item>
      <v-window-item>
        <SavedProperties />
      </v-window-item>
      <v-window-item>
        <DiscoverProperties />
      </v-window-item>
    </v-window>
  </div>
</template>

<script>
import DiscoverProperties from "@/components/property/DiscoverProperties.vue";
import SavedProperties from "@/components/property/SavedProperties.vue";
import OwnedProperties from "@/components/property/OwnedProperties.vue";
export default {
  name: "PropertyListUser",
  components: { OwnedProperties, SavedProperties, DiscoverProperties },
  data() {
    return {
      tab: null,
    };
  },
  watch: {
    tab(newTab) {
      this.$emit("update:tab", newTab);
    },
    $route() {
      this.tab = this.$route.query.tab || 0;
    },
  },
  props: {
    properties: Array,
  },
};
</script>

<style scoped></style>
