<template>

  <SideBoard v-model="showSideBoard">
    <template #content>
      <CreateBuilding @success="showSideBoard = false" v-if="clickedBuilding" :click-event="buildingClickEvent" :property="clickedBuilding"/>
    </template>
  </SideBoard>
    <InfoBoard :property="property" v-model="showInfo"/>

  <MapComponent :shrink="showSideBoard ||showInfo" @buildingClick="handleBuildingClick" @propertyClick="handlePropertyClick" v-model="location"></MapComponent>

</template>

<script>
import MapComponent from "@/components/MapComponent";
import InfoBoard from "@/components/InfoBoard";
import SideBoard from "@/components/SideBoard";
import CreateBuilding from "@/components/property/CreateBuilding";

export default {
  name: "HomeView",
  components: {CreateBuilding, SideBoard, InfoBoard,MapComponent},
  data(){
    return{
      buildingClickEvent:null,
      property:null,
      showInfo:false,
      showSideBoard:false,
      clickedBuilding:null,
      location:{
        lat: 48.151128223000306,
        lng: 11.556244519262124,
        bearing: 0,
        pitch: 0,
        zoom: 2
      }
    }
  },
  methods:{
    handleBuildingClick(e){
      this.showSideBoard=true;
      console.log("Buidling: ",e.features);
      if(e) this.buildingClickEvent = e;
      if(e.features)this.clickedBuilding=e.features[0];

    },
    handlePropertyClick(e){
      this.showInfo=!this.showInfo;
      console.log(e.features[0]);
      this.property=e.features[0]
    }
  }
}
</script>

