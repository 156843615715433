<template>
  <section class="mt-8">
    <!-- <v-combobox
      append-icon="mdi-filter-outline"
      :items="allTags"
      label="Tags filtern"
      v-model="filterTags"
      item-title="tag.name"
      item-value="tag.key"
      density="comfortable"
      multiple
      chips
      clearable
      @keydown.enter.prevent
    ></v-combobox> -->
    <v-card class="elevation-0">
      <v-list
        class="text-left d-flex flex-column flex-wrap ga-2"
        rounded
        v-if="users.length != 0"
      >
        <v-card-text style="max-height: 700px" class="ma-0 pa-0">
          <template v-for="(user, index) of users" :key="user.id">
            <v-list-item rounded class="ma-0 px-3 py-0">
              <v-row class="ma-0 pa-0">
                <v-col cols="12" sm="4" md="12" lg="4">
                  <h3 class="mb-2">
                    {{ user.name }}
                    <v-chip v-if="user.status" :color="user.color">{{
                      user.status
                    }}</v-chip>
                  </h3>
                </v-col>
                <v-col cols="12" sm="4" md="12" lg="4">
                  <p class="mb-2">
                    <v-icon color="grey-lighten-1" class="mr-1"
                      >mdi-email-outline</v-icon
                    >
                    <a :href="'mailto:' + user.email" class="text-accent">{{
                      user.email
                    }}</a>
                  </p>
                  <p>
                    <v-icon color="grey-lighten-1" class="mr-1"
                      >mdi-phone-outline</v-icon
                    >
                    <a :href="'tel:' + user.phone" class="text-accent">
                      {{ user.phone }}</a
                    >
                  </p>
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                  md="12"
                  lg="4"
                  class="text-left text-sm-right text-md-left text-lg-right"
                >
                  <v-btn
                    icon="mdi-check"
                    color="grey-darken-3"
                    size="small"
                    class="ma-1"
                    variant="flat"
                    v-tooltip:top="{
                      text: 'als Interessent markieren',
                      openDelay: 1000,
                    }"
                  ></v-btn>
                  <v-btn
                    icon="mdi-close"
                    color="grey-darken-3"
                    size="small"
                    class="ma-1"
                    variant="flat"
                    v-tooltip:top="{
                      text: 'als nicht relevant markieren',
                      openDelay: 1000,
                    }"
                  ></v-btn>
                  <!-- <v-btn
                    icon="mdi-dots-horizontal"
                    color="grey-darken-3"
                    size="small"
                    class="ma-1"
                    variant="flat"
                  ></v-btn> -->
                  <v-menu location="bottom" transition="slide-y-transition">
                    <template v-slot:activator="{ props }">
                      <v-btn
                        v-bind="props"
                        icon="mdi-dots-horizontal"
                        color="grey-darken-3"
                        size="small"
                        class="ma-1"
                        variant="flat"
                        v-tooltip:top="{
                          text: 'weitere Optionen',
                          openDelay: 1000,
                        }"
                      ></v-btn>
                    </template>

                    <v-list>
                      <v-list-item> offen</v-list-item>

                      <v-list-item> warten auf Rückruf </v-list-item>
                    </v-list>
                  </v-menu>
                </v-col>
              </v-row>
            </v-list-item>

            <v-divider
              class="mx-2 my-2"
              v-if="index < users.length - 1"
            ></v-divider>
          </template>
        </v-card-text>
      </v-list>

      <div class="my-16 text-center" v-else>Noch keine Einträge vorhanden</div>
    </v-card>
  </section>
</template>

<script>
export default {
  name: "LongList",
  props: {
    propertyId: String,
  },
  data() {
    return {
      users: [
        {
          id: 1,
          name: "Max Mustermann",
          email: "user1@example.com",
          phone: "123-456-7890",
          status: "offen",
          color: "primary",
        },
        {
          id: 2,
          name: "Ben Dover",
          email: "user2@example.com",
          phone: "234-567-8901",
          status: "interessent",
          color: "green",
        },
        {
          id: 3,
          name: "Axel Schweiß",
          email: "user3@example.com",
          phone: "345-678-9012",
          status: "nicht relevant",
          color: "red",
        },
        {
          id: 4,
          name: "John Doe",
          email: "user4@example.com",
          phone: "456-789-0123",
          status: "warten auf rückruf",
          color: "grey",
        },
        {
          id: 5,
          name: "Jane Smith",
          email: "user5@example.com",
          phone: "567-890-1234",
        },
        {
          id: 6,
          name: "Michael Johnson",
          email: "user6@example.com",
          phone: "678-901-2345",
        },
        {
          id: 7,
          name: "Chuck Norris",
          email: "chuck@example.com",
          phone: "789-012-3456",
        },
        {
          id: 8,
          name: "Barbara Streisand",
          email: "barbara@example.com",
          phone: "890-123-4567",
        },
        {
          id: 9,
          name: "Elvis Presley",
          email: "elvis@example.com",
          phone: "901-234-5678",
        },
        {
          id: 10,
          name: "Marilyn Monroe",
          email: "marilyn@example.com",
          phone: "012-345-6789",
        },
      ],
    };
  },
};
</script>

<style scoped>
a.text-accent:hover {
  opacity: 0.7;
}
</style>
