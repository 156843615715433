const ESaleProcessStatus = {
    PENDING:{de:"Nicht festgelegt",en:"Offer pending"},
    INDIKATIVES_ANGEBOT:{de:"Indikatives Angebot",en:"Indicative offer"},
    EXCLUSIVE_OFFER:{de:"Exklusives Angebot",en:"Exclusive offer"},
    DUE_DILIGENCE:{de:"Due Diligence",en:"Due Diligence"},
    PREISVERHANDLUNG:{de:"Preisverhandlung",en:"Price negotiation"},
    NOTARTERMIN:{de:"Notartermin",en:"Notary appointment"},
    UEBERGABE:{de:"Übergabe",en:"Handover"},
    VERKAUFT:{de:"Verkauft",en:"Sold"},
    ARCHIVED:{de:"Archiviert",en:"Archived"}
}
export default ESaleProcessStatus;
