import { defineStore } from "pinia";
//import {useMapStore} from "@/stores/modules/map.module";

const state = () => ({
  leftDrawer: true,
});
export const useNavStore = defineStore("navStore", {
  state: state,
  actions: {
    toggleLeftDrawer() {
      //const mapStore = useMapStore();
      this.leftDrawer = !this.leftDrawer;
      //setTimeout(()=>{
      //   mapStore.map.resize();
      // },200)
    },
  },
  mutations: {
    setLeftDrawer(state, value) {
      state.leftDrawer = value;
    },
  },
});
