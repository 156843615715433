<script>
import {completeSaleOfProperty} from "@/services/property.service";
import SelectUser from "@/components/form/SelectUser.vue";

export default {
  name: "CompleteSaleProcess",
  components: {SelectUser},
  props:{
    propertyId:Number
  },
  data(){
    return{
      show:false,
      newOwner:null
    }
  },
  methods: {
    finish() {
     completeSaleOfProperty(this.propertyId, this.newOwner).then(() => {
       this.show = false;
       this.router.push("/agent/properties");
     });
    }
  }
}
</script>

<template>
  <v-dialog v-model="show" max-width="300">
    <template #activator="{props}">
      <v-btn class="my-5" color="accent" block v-bind="props">Verkaufsprozess abschließen</v-btn>
    </template>
    <v-card title="Sind Sie sicher?">
      <v-card-text>
        <p>Mit klick auf OK wird der Verkaufsprozess <b>umfänglich abgeschlossen.</b> </p><p>Die Immobilie wird als verkauft gekennzeichnet und <b>archiviert</b>.
        </p>
        <p>Eine neue Immobilie wird generiert, die den nächsten Verkauf repräsentieren kann, wenn nötig.
        </p>
        <v-divider class="my-3"/>
        <p>Wer wird der neue Besitzer?</p>
        <SelectUser class="my-2" icon="mdi-account" label="Neuer Besitzer" v-model="newOwner"></SelectUser>
      </v-card-text>
      <v-card-actions>
        <v-btn :disabled="!newOwner" @click="finish">OK</v-btn>
        <v-btn @click="show=false">Abbrechen</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped>
p b{
  color: rgb(var(--v-theme-accent))!important
}
</style>