import MapMeta from "@/models/MapMeta";

export default class createBuildingRequest{
    location;
    label;
    address;
    meta=new MapMeta({});

   plotArea;

    usableArea;

    ownerOccupation;

    rented;

    saleGrossPrice;

    courtageSalePercent;
    courtageBuyPercent;

    constructionDate;

    lastRefurbishmentDate;

    //Nettomiete
    grossPotentialIncome;

    renterId;
    renter;

    constructor({label,location,id,address,mapMeta,plotArea,
                    usableArea,
                    ownerOccupation,
                    rented,
                    saleGrossPrice,
                    courtageSalePercent,
                    courtageBuyPercent,
                    constructionDate,
                    lastRefurbishmentDate,
                    //Nettomiete
                    grossPotentialIncome,
                    renterId,
                    renter}) {
        this.label=label;
        this.id=id;
        this.location=location;
        this.address= address;
        this.meta=mapMeta;
        this.plotArea=plotArea;
        this.usableArea=usableArea;
        this.ownerOccupation=ownerOccupation;
        this.rented=rented;
        this.saleGrossPrice=saleGrossPrice;
        this.courtageSalePercent=courtageSalePercent;
        this.courtageBuyPercent=courtageBuyPercent;
        this.constructionDate=constructionDate;
        this.lastRefurbishmentDate=lastRefurbishmentDate;
        this.grossPotentialIncome=grossPotentialIncome;
        this.renterId=renterId;
        this.renter=renter;

    }
}
