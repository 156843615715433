<script>
import { createOffer } from "@/services/saleProcess/offer.service";
import CreateOffer from "@/components/property/offer/CreateOffer.vue";

export default {
  name: "CreateOfferDialog",
  components: { CreateOffer },
  data() {
    return {
      show: false,
      offer: {
        purchasePrice: null,
        validUntil: null,
      },
    };
  },
  props: {
    propertyId: String,
  },
  methods: {
    createOffer() {
      const data = createOffer(this.offer, this.propertyId);
      this.$emit("offerCreated", data);

      this.show = false;
    },
  },
};
</script>

<template>
  <v-dialog max-width="500px" v-model="show">
    <template v-slot:activator="{ props }">
      <v-btn
        :block="$vuetify.display.smAndDown ? true : false"
        class="mt-3 bg-accent"
        variant="text"
        v-bind="props"
        >Angebot erstellen</v-btn
      >
    </template>
    <v-card>
      <v-card-text>
        <CreateOffer
          v-if="offer"
          v-model:offer="offer"
          @submit="createOffer"
          submitText="Angebot erstellen"
        ></CreateOffer>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<style scoped></style>
