<script>
import {addLogbookEntry} from "@/services/logbook.service";
import CreateLogBookPlaceholderUser from "@/components/logbook/CreateLogBookPlaceholderUser.vue";

export default {
  name: "CreateLogbookEntry",
  components: {CreateLogBookPlaceholderUser},
  data(){
    return{
      text:"",
      dialog:false
    }
  },
  props:{
    propertyId:String
  },
  methods: {
    addEntry(text, propertyId) {
      addLogbookEntry({text: text}, propertyId).then((response) => {
        this.$emit("entry-added",response);
        this.dialog=false;
        this.text=null;
      });
    }
  }
}
</script>

<template>
<v-dialog max-width="500" v-model="dialog">
  <template #activator="{ props }">
    <v-list-item v-bind="props" color="primary">
      <template #prepend>
        <v-icon>mdi-plus</v-icon>
      </template>
      <template #title>
        Logbuch Eintrag hinzufügen
      </template>
    </v-list-item>
  </template>
  <v-card>
    <v-card-title>Eintrag hinzufügen</v-card-title>
    <v-card-text>
      <v-textarea v-model="text" label="Text" required></v-textarea>
      <CreateLogBookPlaceholderUser @addUser="text = text+= ' '+$event"/>
    </v-card-text>
    <v-card-actions>
      <v-btn @click="addEntry(text, propertyId)" color="accent">Hinzufügen</v-btn>
      <v-btn @click="dialog = false">Abbrechen</v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
</template>

<style scoped>

</style>