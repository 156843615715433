<template>
  <v-list class="bg-transparent" align="left" v-if="logbook">
    <LogbookEntry v-for="(entry,$index) of entries" :index="$index+1" :key="entry.id" :entry="entry"/>
    <CreateLogbookEntry v-if="logbook && !readonly" :propertyId="logbook.propertyId" @entry-added="logbook= $event"/>
  </v-list>
</template>

<script>
import {getLogbook} from "@/services/logbook.service";
import LogbookEntry from "@/components/logbook/LogbookEntry";
import CreateLogbookEntry from "@/components/logbook/CreateLogbookEntry.vue";

export default {
  name: "LogbookItem",
  components: {CreateLogbookEntry, LogbookEntry},
  props:{
    propertyId:Number,
    readonly:Boolean
  },
  data(){
    return{
      logbook:null
    }
  },
  computed:{
    entries(){
      return [...this.logbook.entries].sort((x,y) => x.timestamp - y.timestamp);
    }
  },
  async created() {
    if(this.propertyId) this.logbook = await getLogbook(this.propertyId);
  }
}
</script>

<style scoped>

</style>