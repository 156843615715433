import ApiService from "@/services/api.service";

export async function getProfile() {
    const response = await ApiService.get("user/profile/get");
    return response.data;
}

export async function updateAvatar(bytes){

    let blob = new Blob([bytes], {type: 'image/jpeg'}); // adjust the MIME type as needed
let formData = new FormData();
formData.append('files', blob, 'filename.jpg'); // adjust the filename as needed
const response = await ApiService.postWithAdditionalHeader("user/profile/avatar/update", formData, { 'Content-Type': 'multipart/form-data', 'Accept' : 'application/json'});
console.log("Response",response);
return response.data;
}

export async function updateProfileInfo(info){
    const response = await ApiService.post("user/profile/info/update", info);
    return response.data;
}
