<script>
import EnumDisplay from "@/components/helpers/EnumDisplay.vue";
import EAppointmentType from "@/components/enums/EAppointmentType";
export default {
  name: "AppointmentListItem",
  components: { EnumDisplay },
  props: {
    appointment: Object,
  },
  data() {
    return {
      appointmentTypes: EAppointmentType,
    };
  },
  computed: {
    time() {
      let moment = require("moment");
      moment.locale("de");
      let end = moment(this.appointment.end);
      let start = moment(this.appointment.start);
      var duration = moment.duration(end.diff(start));
      return (
        moment(this.appointment.start).calendar() +
        " (Dauer: " +
        duration.asHours().toFixed(1) +
        "h)"
      );
    },
    chipColor() {
      switch (this.appointment.appointmentType) {
        case "VIEWING":
          return "light-blue";
        case "MEETING":
          return "deep-purple-lighten-2";
        case "NOTARY_APPOINTMENT":
          return "green";
        case "HANDOVER":
          return "orange";
        case "RETURN":
          return "red";
        default:
          return "grey";
      }
    },
  },
};
</script>

<template>
  <v-list-item :subtitle="time" class="text-left d-flex flex-column">
    <template #title>
      <div class="d-flex flex-column align-start ga-1 mb-2">
        <span class="text-h5" v-text="appointment.subject"></span>
        <v-chip :color="chipColor" size="x-small">
          <EnumDisplay
            :value="appointment.appointmentType"
            :enum-object="appointmentTypes"
        /></v-chip>
      </div>
    </template>
  </v-list-item>
  <slot></slot>
</template>

<style scoped></style>
