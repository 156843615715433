<script>
import AppointmentListItem from "@/components/appointment/AppointmentListItem.vue";
import CalendarView from "../calendar/CalendarView.vue";

export default {
  name: "AppointmentList",
  components: { AppointmentListItem, CalendarView },
  data() {
    return {
      openedGroups: ["Kommende Termine"],
      tab: 0,
      today: new Date(),
    };
  },
  props: {
    appointments: Array,
  },
  computed: {
    kommendeTermine() {
      return this.appointments.filter(
        (appointment) => new Date(appointment.start) > this.today
      );
    },
    abgelaufeneTermine() {
      return this.appointments.filter(
        (appointment) => new Date(appointment.start) < this.today
      );
    },
  },
  created() {
    console.log("Appointments", this.appointments);
  },
};
</script>

<template>
  <!-- TODO Nur kommente Termine anzeigen, andere nur bei Klick auf "abgelaufenen Termine anzeigen" -->
  <v-tabs v-model="tab">
    <v-tab>Liste</v-tab>
    <v-tab>Kalender</v-tab>
  </v-tabs>
  <v-window v-model="tab">
    <v-window-item>
      <v-list v-model:opened="openedGroups" v-if="appointments.length > 0">
        <v-list-group value="Kommende Termine" expand-icon="mdi-chevron-down">
          <template v-slot:activator="{ props }">
            <v-list-item v-bind="props">
              <template #title>
                <small
                  style="
                    color: rgba(
                      var(--v-theme-on-surface),
                      var(--v-medium-emphasis-opacity)
                    );
                    display: flex;
                    font-size: 0.875rem;
                    font-weight: 400;
                    line-height: 1.375rem;
                  "
                  >Kommende Termine</small
                >
              </template>
            </v-list-item>
          </template>
          <div
            class="d-flex flex-column align-center ga-3 text-center"
            v-if="kommendeTermine && kommendeTermine.length <= 0"
          >
            <v-icon color="grey-lighten-1" size="large"
              >mdi-calendar-check-outline</v-icon
            >
            <span>Keine bevorstehenden Termine</span>
          </div>
          <AppointmentListItem
            v-for="(appointment, index) of kommendeTermine"
            :key="appointment.id"
            :appointment="appointment"
            ><v-divider
              v-if="index < kommendeTermine.length - 1"
              class="mx-6 mt-2"
            ></v-divider
          ></AppointmentListItem>
        </v-list-group>
        <v-list-group
          value="Abgelaufene Termine"
          expand-icon="mdi-chevron-down"
          style="max-height: 500px; overflow-y: auto"
        >
          <template v-slot:activator="{ props }">
            <v-list-item v-bind="props">
              <template #title>
                <small
                  style="
                    color: rgba(
                      var(--v-theme-on-surface),
                      var(--v-medium-emphasis-opacity)
                    );
                    display: flex;
                    font-size: 0.875rem;
                    font-weight: 400;
                    line-height: 1.375rem;
                  "
                  >Abgelaufene Termine</small
                >
              </template>
            </v-list-item>
          </template>
          <div
            v-if="abgelaufeneTermine && abgelaufeneTermine.length <= 0"
            class="d-flex flex-column align-center ga-3 text-center"
          >
            <v-icon color="grey-lighten-1" size="large"
              >mdi-calendar-check-outline</v-icon
            >
            <span>Keine abgelaufenen Termine</span>
          </div>
          <AppointmentListItem
            v-for="(appointment, index) in abgelaufeneTermine"
            :key="appointment.id"
            :appointment="appointment"
            ><v-divider
              v-if="index < abgelaufeneTermine.length - 1"
              class="mx-6 mt-2"
            ></v-divider
          ></AppointmentListItem>
        </v-list-group>
      </v-list>
      <v-list v-else>
        <div class="d-flex flex-column align-center ga-3 text-center">
          <v-icon color="grey-lighten-1" size="large"
            >mdi-calendar-check-outline</v-icon
          >
          <span>Keine Termine</span>
        </div>
      </v-list>
    </v-window-item>
    <v-window-item
      ><CalendarView :appointments="appointments"></CalendarView>
    </v-window-item>
  </v-window>
</template>

<style scoped></style>
