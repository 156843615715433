<script>
import {getSingleProperty} from "@/services/property.service";

export default {
  name: "LogbookClickableBuilding",
  props:{
    id:String,
    label:String
  },
  methods:{
    async load() {
      if (!this.data) {
        this.loading = true;
        let data = await getSingleProperty(this.id);
        this.data = data;
        this.loading = false;
      }
    }
  },
  data(){
    return {
      loading:false,
      data:null,
      show: false
    }
  }
}
</script>

<template>
  <v-menu v-model="show" offset-y>
    <template #activator="{ props }">
      <a @click="load" class="text-accent cursor-pointer" v-bind="props">
        <span>{{label}}</span>
      </a>
    </template>
    <v-skeleton-loader v-if="loading" type="list-item-two-line" />
    <v-list v-else>
      <v-list-item>
        <v-list-item-title>{{data.label}} {{data.propertyType}}</v-list-item-title>
        <v-list-item-subtitle>{{data?.address?.street}} {{data?.address?.zipCode}} {{data?.address?.city}}</v-list-item-subtitle>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<style scoped>

</style>