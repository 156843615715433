<template>
  <v-navigation-drawer width="600" location="right" temporary v-model="value">

    <div v-if="mapProperty" class="px-5 py-10">

      <PropertyDataAndFacts v-if="propertyObject" :name="mapProperty?.label" :data-and-facts="propertyObject.dataAndFacts"/>
      <LogbookButton v-if="propertyObject" :property-id="propertyObject.id">
        <template #activator="props">
          <v-btn block v-bind="props"><v-icon>mdi-book-open-outline</v-icon> Logbuch öffnen</v-btn>
        </template>
      </LogbookButton>
      <v-btn v-if="propertyObject" block color="accent" :href="'/property/'+propertyObject.id">Mehr Details</v-btn>
    </div>
  </v-navigation-drawer>
</template>
<script>
//TODO Add component to get whole property with all data and (wrap it in new Component named "ka") wichtigste Daten darstellen & verlinken auf PropertyDetails
import {getDataAndFacts} from "@/services/property.service";
import PropertyDataAndFacts from "@/components/property/details/PropertyDataAndFacts";
import LogbookButton from "@/components/logbook/LogbookButton";

export default {
  name: 'InfoBoard',
  components: {LogbookButton, PropertyDataAndFacts},
  props: ['modelValue','property'],
  emits: ['update:modelValue'],
  data(){
    return{
      propertyObject:null,
      tab:null
    }
  },
  created() {
    if(this.mapProperty) this.getPropertyById(this.mapProperty.propertyId);
  },
  watch:{
    property(){
      this.getPropertyById(this.mapProperty.propertyId);
    }
  },
  computed: {
    mapProperty(){
      if(!this.property) return null;
      return this.property.properties;
    },
    value: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    }
  },
  methods:{
    async getPropertyById(id) {
      let data = await getDataAndFacts(id);
      console.log("PropertyData",data);
      this.propertyObject=data;
    }
  }
}
</script>
