<template>
  <div>
    <UserInsights />
    <v-row>
      <v-col cols="12" md="6">
        <v-sheet rounded class="my-5 pa-5 black lighten-2">
          <v-list-item class="pa-0" align="left">
            <template #title>Eingang</template>
            <template #subtitle>April 2024</template>
            <template #prepend>
              <div
                class="rounded"
                style="
                  padding: 10px;
                  margin-right: 15px;
                  background-color: white;
                "
              >
                <v-icon color="accent">mdi-chart-box</v-icon>
              </div>
            </template>
          </v-list-item>
          <div class="text-left py-5">
            <h4 class="font-weight-thin">Dokumente/ Vertäge</h4>
            <v-list>
              <v-list-item rounded elevation="1" class="py-4">
                <template #prepend>
                  <v-icon color="red">mdi-file-pdf-box</v-icon>
                </template>
                <template #title> Kaufvertrag_238882187.pdf </template>
                <template #subtitle> Max Mustermann </template> </v-list-item
              ><v-list-item rounded elevation="1" class="py-4">
                <template #prepend>
                  <v-icon color="red">mdi-file-pdf-box</v-icon>
                </template>
                <template #title> Kaufvertrag_238882187.pdf </template>
                <template #subtitle> Max Mustermann </template> </v-list-item
              ><v-list-item rounded elevation="1" class="py-4">
                <template #prepend>
                  <v-icon color="red">mdi-file-pdf-box</v-icon>
                </template>
                <template #title> Kaufvertrag_238882187.pdf </template>
                <template #subtitle> Max Mustermann </template>
              </v-list-item>
            </v-list>
          </div>
        </v-sheet>
      </v-col>
      <v-col cols="12" md="6">
        <v-sheet
          rounded
          class="d-flex flex-column ga-6 justify-space-between my-md-5 pa-5 text-left black lighten-2"
        >
          <div>
            <MeetingItem
              v-if="closestAppointment"
              :meeting="closestAppointment"
            />
            <div
              class="d-flex flex-column align-center ga-3 text-center"
              v-else
            >
              <v-icon color="grey-lighten-1" size="large"
                >mdi-calendar-check-outline</v-icon
              >
              <span>Keine bevorstehenden Termine</span>
            </div>
          </div>
          <div
            v-if="closestAppointment"
            class="my-5 d-flex flex-wrap ga-3 justify-space-between"
          >
            <div class="d-flex ga-1">
              <v-dialog max-width="500">
                <template v-slot:activator="{ props: activatorProps }">
                  <v-btn
                    v-bind="activatorProps"
                    size="small"
                    icon="mdi-clock-outline"
                    v-tooltip:top="{
                      text: 'Neue Zeit vorschlagen',
                      openDelay: 1000,
                    }"
                    tile
                    rounded
                    color="accent"
                    :variant="suggestButtonVariant"
                    :loading="loading"
                  ></v-btn>
                </template>

                <template v-slot:default="{ isActive }">
                  <v-card title="Neue Zeit vorschlagen">
                    <v-card-text>
                      <div class="py-5">
                        <KreaInput
                          type="datetime-local"
                          label="Start"
                          v-model:input-value="suggest.start"
                        />
                        <KreaInput
                          type="datetime-local"
                          label="Ende"
                          v-model:input-value="suggest.end"
                        />
                      </div>
                    </v-card-text>

                    <v-card-actions>
                      <v-btn
                        text="Abbrechen"
                        @click="isActive.value = false"
                      ></v-btn>

                      <v-spacer></v-spacer>

                      <v-btn
                        class="mx-auto"
                        color="accent"
                        :loading="loading"
                        @click="
                          handleAppointmentActionClick('suggest', suggest);
                          isActive.value = false;
                        "
                        :disabled="!suggest.start || !suggest.end"
                        >Senden</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </template>
              </v-dialog>

              <v-btn
                @click="handleAppointmentActionClick('decline', false)"
                size="small"
                icon="mdi-cancel"
                v-tooltip:top="{
                  text: 'Termin ablehnen',
                  openDelay: 1000,
                }"
                tile
                rounded
                :loading="loading"
                color="accent"
                :variant="declineButtonVariant"
              ></v-btn>

              <v-btn
                @click="handleAppointmentActionClick('accept', false)"
                size="small"
                icon="mdi-check"
                :loading="loading"
                v-tooltip:top="{
                  text: 'Termin annehmen',
                  openDelay: 1000,
                }"
                tile
                rounded
                color="accent"
                :variant="acceptButtonVariant"
              ></v-btn>
            </div>
            <v-btn
              v-if="closestAppointment.host.id === userId"
              @click="cancelAppointment"
              color="red-darken-3"
              variant="tonal"
              :loading="loading"
              size="large"
              :block="$vuetify.display.mdAndDown ? true : false"
              class="text-capitalize"
              >Absagen</v-btn
            >
          </div>
        </v-sheet>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import KreaInput from "@/components/KreaInput.vue";
import {
  getClosestAppointment,
  sendAppointmentAction,
} from "@/services/appointment.service";
import MeetingItem from "@/components/meetings/MeetingItem.vue";
import ApiService from "@/services/api.service";
import { useNotificationStore } from "@/stores/modules/notification.module";
import NotificationElephant from "@/models/notification";
import { useAuthUserStore } from "@/stores/modules/auth.module";
import UserInsights from "@/components/dashboard/UserInsights.vue";
export default {
  setup() {
    const notificationStore = useNotificationStore();
    const authUserStore = useAuthUserStore();
    const userId = authUserStore.user.id;
    return {
      notificationStore,
      userId,
    };
  },
  name: "DashboardOverview",
  components: { MeetingItem, KreaInput, UserInsights },
  data() {
    return {
      loading: false,
      closestAppointment: null,
      suggest: {
        start: null,
        end: null,
        timezone: null,
      },
    };
  },
  computed: {
    suggestButtonVariant() {
      const participant = this.closestAppointment.participants.find(
        (p) => p.user.id === this.userId
      );
      return participant && participant.acceptStatus === "NEW_TIME"
        ? "flat"
        : "outlined";
    },

    declineButtonVariant() {
      const participant = this.closestAppointment.participants.find(
        (p) => p.user.id === this.userId
      );
      return participant && participant.acceptStatus === "DECLINED"
        ? "flat"
        : "outlined";
    },
    acceptButtonVariant() {
      const participant = this.closestAppointment.participants.find(
        (p) => p.user.id === this.userId
      );
      return participant && participant.acceptStatus === "ACCEPTED"
        ? "flat"
        : "outlined";
    },
  },
  methods: {
    async getClosestAppointment() {
      this.closestAppointment = await getClosestAppointment();
      console.log("Closest Appointment", this.closestAppointment);
    },
    async handleAppointmentActionClick(action, suggest) {
      this.loading = true;
      await sendAppointmentAction(this.closestAppointment.id, action, suggest);
      this.getClosestAppointment();
      this.loading = false;
    },
    async cancelAppointment() {
      this.loading = true;
      try {
        await ApiService.post(
          `appointment/cancel/${this.closestAppointment.id}`
        );
        this.getClosestAppointment();
        this.notificationStore.push(
          new NotificationElephant({
            message: "Termin erfolgreich abgesagt.",
            color: "SUCCESS",
          })
        );
      } catch (error) {
        this.notificationStore.pushError("Fehler beim Absagen des Termins.");
      } finally {
        this.loading = false;
      }
    },
  },
  created() {
    this.getClosestAppointment();
    let userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.suggest.timezone = userTimezone;
  },
};
</script>

<style scoped></style>
