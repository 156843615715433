<template>
  <div>
    <p>von <UserChip :user="process.mandant"/></p>
    <p> Status : <EnumDisplay :value="process.status" :enum-object="saleProcessStatus"/></p>
  </div>
</template>
<script>
import UserChip from "@/components/helpers/UserChip.vue";
import ESaleProcessStatus from "@/components/enums/ESaleProcessStatus";
import EnumDisplay from "@/components/helpers/EnumDisplay.vue";
export default {
  name: 'SaleProcessSmallInfo',
  components: {EnumDisplay, UserChip},
  data(){
    return{
      saleProcessStatus: ESaleProcessStatus
    }
  },
  props: {
    process: Object
  }
}
</script>
