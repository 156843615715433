const EUsageType = {
    NOT_APPLIED: { en: "Not applied", de: "Not applied" },
    RETAIL_BUILDING: { en: "Retail building", de: "Einzelhandelsgebäude" },
    SHOWROOM: { en: "Showroom", de: "Ausstellungsraum" },
    ART_GALLERY: { en: "Art gallery", de: "Kunstgalerie" },
    KIOSK: { en: "Kiosk", de: "Kiosk" },
    BANK: { en: "Bank", de: "Bank" },
    POST: { en: "Post", de: "Post" },
    PUBS_BARS: { en: "Pubs Bars", de: "Kneipen Bars" },
    RESTAURANT_GASTRONOMIE: { en: "Restaurant Gastronomie", de: "Restaurant Gastronomie" },
    OTHER_RETAIL_BUILDING: { en: "Other retail building", de: "Sonstiges Einzelhandelsgebäude" },
    PASSAGE_SHOPPING_GALLERY: { en: "Passage shopping gallery", de: "Passage Einkaufsgalerie" },
    INTEGRATED_SHOPPING_CENTER: { en: "Integrated shopping center", de: "Integriertes Einkaufszentrum" },
    FACTORY_OUTLET_CENTER: { en: "Factory outlet center", de: "Fabrikverkaufszentrum" },
    OTHER_SHOPPING_CENTER: { en: "Other shopping center", de: "Sonstiges Einkaufszentrum" },
    RETAIL_MARKET: { en: "Retail market", de: "Einzelhandelsmarkt" },
    RETAIL_MARKET_CENTER: { en: "Retail market center", de: "Einzelhandelsmarktzentrum" },
    CONSUMER_MARKET: { en: "Consumer Market", de: "Verbrauchermarkt" },
    SELF_SERVICE_GENERAL_STORE: { en: "Self service general store", de: "Allgemeiner Selbstbedienungsladen" },
    DEPARTMENT_STORE: { en: "Department store", de: "Kaufhaus" },
    GENERAL_STORE: { en: "General Store", de: "Allgemeines Geschäft" },
    OTHER_RETAIL_BUILDINGS: { en: "Other retail buildings", de: "Sonstige Einzelhandelsgebäude" },
    DETACHED_OFFICE_BUILDING: { en: "Detached office building", de: "Freistehendes Bürogebäude" },
    NON_DETACHED_OFFICE_BUILDING: { en: "Non detached office building", de: "Nicht freistehendes Bürogebäude" },
    OFFICE_PARK_LOCATION: { en: "Office park location", de: "Standort eines Büroparks" },
    OTHER_OFFICE_BUILDING: { en: "Other office building", de: "Anderes Bürogebäude" },
    FACTORY: { en: "Factory", de: "Fabrik" },
    PRODUCTION: { en: "Production", de: "Produktion" },
    WAREHOUSE_LOGISTIC: { en: "Warehouse logistics", de: "Lagerlogistik" },
    OTHER_INDUSTRY_PROPERTY: { en: "Other industry property", de: "Sonstige Industrieimmobilie" },
    DETACHED_HOUSE: { en: "Detached house", de: "Freistehendes Haus" },
    SEMI_DETACHED_HOUSE: { en: "Semi detached house", de: "Doppelhaushälfte" },
    TERRACE_HOUSE: { en: "Terrace house", de: "Terrassenhaus" },
    DOUPLEX_MAISONETTE: { en: "Douplex maisonette", de: "Maisonette-Doppelhaushälfte" },
    APARTMENT_BUILDING_ONE_ENTRANCE: { en: "Apartment building one entrance", de: "Mehrfamilienhaus mit einem Eingang" },
    APARTMENT_BUILDING_SEPARATE_ENTRANCES: { en: "Apartment building separate entrances", de: "Mehrfamilienhaus getrennte Eingänge" },
    SENIOR_HOUSING_WITHOUT_CARE: { en: "Senior housing without care", de: "Seniorenwohnungen ohne Pflege" },
    NURSING_HOME: { en: "Nursing home", de: "Pflegeheim" },
    HOLIDAY_PROPERTY: { en: "Holiday property", de: "Ferienimmobilie" },
    GARDEN_CITY: { en: "Garden city", de: "Gartenstadt" },
    OTHER_RESIDENTIAL_BUILDINGS: { en: "Other residential buildings", de: "Sonstige Wohngebäude" },
    HOTEL: { en: "Hotel", de: "Hotel" },
    LEISURE_PARK: { en: "Leisure park", de: "Freizeitpark" },
    CINEMA: { en: "Cinema", de: "Kino" },
    THEATRE: { en: "Theatre", de: "Theater" },
    HOLIDAY_RESORT: { en: "Holiday resort", de: "Ferienanlage" },
    RETAIL_OTHER: { en: "Retail other", de: "Einzelhandel Sonstiges" },
    CLINIC: { en: "Clinic", de: "Klinik" },
    MEDICAL_CENTRE: { en: "Medical centre", de: "Medizinisches Zentrum" },
    RESEARCH_EDUCATION: { en: "Research education", de: "Forschung und Lehre" },
    CAR_PARK: { en: "Car park", de: "Parkhaus" },
    UNBUILT_AREA: { en: "Unbuilt area", de: "Unbebautes Gebiet" },
    EXHIBITION_AND_CONVENTION_CENTRE: { en: "Exhibition and convention centre", de: "Ausstellungs- und Kongresszentrum" },
    LABORATORY: { en: "Laboratory", de: "Laboratorium" },
    OTHER_USAGE: { en: "Other usage", de: "Sonstige Nutzung" },
    FARMLAND: { en: "Farmland", de: "Ackerland" },
    FORREST: { en: "Forrest", de: "Wälder" },
    OTHER: { en: "Other", de: "Sonstiges" }
};

export default EUsageType;
