import ApiService from "@/services/api.service";

export async function addPropertyRelation(propertyId,userId,type){
    const response = await ApiService.get("property/"+propertyId+"/relation/add/user/"+userId+"/type/"+type);
    return response.data;
}

export async function getMaklersOfProperty(propertyId){
    const response = await ApiService.get("property/"+propertyId+"/makler/list");
    return response.data;
}

export async function removePropertyRelation(propertyId,userId,type){
    const response = await ApiService.get("property/"+propertyId+"/relation/remove/user/"+userId+"/type/"+type);
    return response.data;
}