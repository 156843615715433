<template>
  <v-dialog v-model="show" persistent fullscreen>
    <v-sheet>
      <v-row style="height: 100vh">
        <v-col
          style="height: 100vh"
          cols="12"
          md="6"
          class="align-content-center justify-center"
        >
          <router-view />
        </v-col>

        <v-col
          v-if="$vuetify.display.mdAndUp"
          class="bg-accent d-flex align-center justify-center"
          cols="12"
          md="6"
        >
          <dotlottie-player
            src="https://lottie.host/89a8691d-824c-488e-be28-a81060cf4dde/FYMNpuAThb.json"
            background="transparent"
            speed="1"
            style="width: 500px; height: 500px"
            loop
            autoplay
          ></dotlottie-player>
        </v-col>
      </v-row>
    </v-sheet>
  </v-dialog>
</template>

<script>
export default {
  name: "AuthView",
  data() {
    return {
      show: true,
    };
  },
  components: {},
  mounted() {
    const script = document.createElement("script");
    script.src =
      "https://unpkg.com/@dotlottie/player-component@latest/dist/dotlottie-player.mjs";
    script.type = "module";
    document.head.appendChild(script);
  },
};
</script>

<style scoped></style>
