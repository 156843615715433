<script>
import {useMapStore} from "@/stores/modules/map.module";
import {useMaklerPropertyStore} from "@/stores/modules/makler.property.module";
import PropertyItemAgent from "@/components/agent/property/PropertyItemAgent.vue";

export default {
  name: "ArchivedPropertiesPagableList",
  components: {PropertyItemAgent},
  setup(){
    const propertyStore = useMaklerPropertyStore();
    const mapStore  = useMapStore();
    return{
      propertyStore,mapStore
    }
  },
  created() {
    this.locationLoading=true;
    this.mapStore.getUserPositionLngLat.then((res) => {
      this.locationLoading=false;
      this.propertyStore.fetchAvailableProperties(0,res);
    });
  },
  data(){
    return{
      locationLoading:false
    }
  },
  computed:{
    userLocation(){
      return this.mapStore.userPosition;
    },
    properties(){
      return this.propertyStore.archivedProperties.content;
    },
    page(){
      return this.propertyStore.archivedProperties.currentPage;
    },
    loading(){
      return this.propertyStore.archivedProperties.loading;
    }
  },
  methods:{
    loadPage(page){
      this.propertyStore.fetchArchivedProperties(page,this.userLocation);
    }
  }
}
</script>

<template>
  <v-skeleton-loader
      v-if="loading"
      type="image"
  >

  </v-skeleton-loader>
  <div v-else-if="!properties || properties.length<=0">
    <div class="text-center">Keine Immobilien</div>
  </div>
  <div v-else style="gap:15px" class="d-flex justify-start flex-column">
    <PropertyItemAgent v-for="property of properties" :key="property.id" :property="property"/>
    <v-pagination :start="0" v-model="page.number" :length="page.totalPages" @update:modelValue="loadPage"/>
  </div>

</template>

<style scoped>

</style>