<template>
  <div>
    <v-autocomplete
      :multiple="multiple"
      v-if="!initialLoading"
      :prepend-icon="icon"
      v-model="modelValueC"
      :items="items"
      hide-details
      v-model:search="search"
      :label="label"
      :density="density"
      :loading="loading"
      item-value="id"
      no-filter
      @update:modelValue="selectItem"
      @keypress.enter="fetchItems"
      item-title="name"
      @update:search="fetchItems"
    >
      <template #selection="{ item }">
        <div class="text-truncate"><span v-text="item.raw.name"></span><span>({{ item.raw.email }})</span></div>
      </template>
      <template #no-data>
        <slot name="no-data">
          <v-list-item class="pa-2" v-if="alreadySearched">
            <template #title>Nichts gefunden</template>
            <template #subtitle
              >Stattdessen einen neuen Nutzer erstellen?</template
            >
            <template #append>
              <v-btn
                @click="
                  () => {
                    this.showCreateUserDialog = !this.showCreateUserDialog;
                    this.searchLocked = this.search;
                  }
                "
                text="ja"
              ></v-btn>
            </template>
          </v-list-item>
          <div class="pa-2" v-else>Suche nach einem User</div>
        </slot>
      </template>
      <template #item="{ props, item }">
        <v-list-item v-bind="props" :title="item.raw.name">
          <template #prepend>
            <UserAvatar :user="item.raw" />
          </template>
          <template #subtitle>
            <span v-text="item.raw.email"></span>
          </template>
        </v-list-item>
      </template>
    </v-autocomplete>
    <CreateUserSimpleDialog
      @userCreated="userCreated"
      :pre-defined-email="searchLocked"
      v-model="showCreateUserDialog"
    />
  </div>
</template>

<script>
import { getUsersByIds, searchForUser } from "@/services/user.service";
import UserAvatar from "@/components/user/Avatar";
import CreateUserSimpleDialog from "@/components/user/CreateUserSimpleDialog";

export default {
  name: "SelectUser",
  components: { CreateUserSimpleDialog, UserAvatar },
  data() {
    return {
      items: [],
      search: null,
      searchLocked: null,
      loading: false,
      initialLoading: false,
      alreadySearched: false,
      showCreateUserDialog: false,
    };
  },
  async created() {
    if (this.modelValue) {
      this.initialLoading = true;
      console.log("Model", this.modelValue);
      let users = null;
      if (!Array.isArray(this.modelValue)) {
        users = await getUsersByIds([this.modelValue]);
      } else {
        users = await getUsersByIds(this.modelValue);
      }
      console.log("User", users);
      this.items = users;
      this.initialLoading = false;
    }
  },
  props: {
    label: {
      type: String,
      default: "Suche",
    },
    icon: {
      type: String,
      default: "mdi-magnify",
    },
    density: {
      type: String,
      default: "compact",
    },
    multiple: Boolean,
    modelValue: Number,
  },
  emits: ["update:modelValue"],
  computed: {
    modelValueC: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  methods: {
    async userCreated(user) {
      console.log("user", user);
      this.items.push(user);
      await this.selectItem(user.id);
      this.showCreateUserDialog = false;
    },
    async selectItem(selectedItem) {
      this.loading = true;
      this.modelValueC = selectedItem;
      this.loading = false;
    },
    async fetchItems(searchQuery) {
      this.loading = true;
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(async () => {
        try {
          if (!searchQuery) return;
          this.items = await searchForUser(searchQuery);
        } catch (error) {
          console.error("Error fetching items:", error);
        } finally {
          this.alreadySearched = true;
          this.loading = false;
        }
      }, 800); // Debounce time to prevent too many API call
    },
  },
};
</script>

<style scoped></style>
