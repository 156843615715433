<script>
import {getUserContactCard} from "@/services/user.service";

export default {
name: "UserContactCard",
  props:{
  userId:Number,
  },
  data() {
    return {
      user: null,
      show:false,
    }
  },
  methods:{
    async getUser(){
      this.user = await getUserContactCard(this.userId);
      console.log("got user",this.user);
    }
  },
  watch: {
    show: {
      handler: function () {
        if (this.show && !this.user) this.getUser();
      },
      immediate: true
    }
  },
  computed: {
    registerDate() {
      if (this.user) return new Date(this.user.registerDate).toLocaleDateString();
      return null;
    },
    userRoles() {
      let roles = [];
      for(let role of this.user.roles){
        switch (role){
          case "ROLE_ADMIN":
            roles.push("Admin");
            break;
          case "ROLE_USER":
            roles.push("User");
            break;
          case "ROLE_MAKLER":
            roles.push("Makler");
            break;
          default:
            roles.push(role);
        }
      }
      return roles;
    }
  }
}
</script>

<template>
  <v-dialog v-model="show" max-width="500">
    <template #activator="{props}">
      <slot name="activator" v-bind="props"></slot>
    </template>
    <v-card v-if="user">
      <v-img
          v-if="user.avatar"
          height="250"
          :src="'data:image/png;base64,'+user.avatar"
          cover
      ></v-img>
      <v-card-title class="pb-0">
        <span v-text="user.name"></span>
      </v-card-title>
      <v-card-subtitle>
        <span style="font-size:0.6rem" class="mr-1" v-for="role in userRoles" :key="role" v-text="role"></span>
      </v-card-subtitle>
      <v-card-text>
        <v-row>
          <v-col cols="12" v-if="user.email">
            <v-icon class="mr-1" size="small">mdi-mail</v-icon><span v-text="user.email"></span>
          </v-col>
          <v-col cols="12" v-if="user.mobilePhone">
            <v-icon class="mr-1" size="small">mdi-phone</v-icon><span v-text="user.mobilePhone"></span>
          </v-col>
        </v-row>
        <v-divider color="accent" class="my-5"/>
        <v-row>
          <v-col v-if="user.address">
            <p>Adresse</p>
            <p v-text="user.address"></p>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <small class="text-disabled">Mitglied seit <span v-text="registerDate"></span></small>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<style scoped>

</style>