<script>
import moment from "moment/moment";
import MeetingParticipantAvatar from "@/components/meetings/MeetingParticipantAvatar.vue";
import EAppointmentType from "@/components/enums/EAppointmentType.js";

export default {
  name: "MeetingItem",
  data() {
    return {
      EAppointmentType,
    };
  },
  components: { MeetingParticipantAvatar },
  props: {
    meeting: {
      type: Object,
      required: true,
    },
  },
  methods: {
    openMeeting() {
      let url = this.meeting.location;
      if (!url.startsWith("http://") && !url.startsWith("https://")) {
        url = "https://" + url;
      }
      window.open(url, "_blank");
    },
    isUrl(str) {
      const pattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
          "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
          "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$",
        "i"
      ); // fragment locator
      return !!pattern.test(str);
    },
  },
  computed: {
    totalDuration() {
      const startDate = moment(this.meeting.start);
      const endDate = moment(this.meeting.end);
      return endDate.diff(startDate);
    },
    elapsedTime() {
      const now = moment();
      const startDate = moment(this.meeting.start);
      if (now.isBefore(startDate)) {
        return 0;
      } else {
        return now.diff(startDate);
      }
    },
    progress() {
      return (this.elapsedTime / this.totalDuration) * 100;
    },
    elapsedTimeDisplay() {
      const duration = moment.duration(this.elapsedTime);
      return `${duration.hours()}h ${duration.minutes()}m ${duration.seconds()}s`;
    },
    // chipColor() {
    //   switch (this.meeting.appointmentType) {
    //     case "VIEWING":
    //       return "light-blue";
    //     case "MEETING":
    //       return "deep-purple-lighten-2";
    //     case "NOTARY_APPOINTMENT":
    //       return "green";
    //     case "HANDOVER":
    //       return "orange";
    //     case "RETURN":
    //       return "red";
    //     default:
    //       return "grey";
    //   }
    // },
    date() {
      if (moment(this.meeting.start).isSame(this.meeting.end, "day")) {
        return (
          moment(this.meeting.start).format("dd[.] DD.MM.YYYY HH:mm") +
          " - " +
          moment(this.meeting.end).format("HH:mm")
        );
      } else {
        return (
          moment(this.meeting.start).format("dd[.] DD.MM.YYYY HH:mm") +
          " - " +
          moment(this.meeting.end).format("dd[.] DD.MM.YYYY HH:mm")
        );
      }
    },
  },
};
</script>

<template>
  <div class="text-left" v-if="meeting">
    <v-list-item class="pa-0 mb-5" align="left">
      <template #title>Anstehender Termin</template>
      <template #subtitle
        ><v-chip v-if="meeting.appointmentType" size="x-small">{{
          EAppointmentType[meeting.appointmentType].de
        }}</v-chip></template
      >
      <template #prepend>
        <div
          class="rounded"
          style="padding: 10px; margin-right: 15px; background-color: white"
        >
          <v-icon color="accent">mdi-account-group</v-icon>
        </div>
      </template>
    </v-list-item>
    <!-- <v-divider
      thickness="1"
      class="my-2 border-opacity-100"
      color="accent"
    ></v-divider> -->
    <h3 class="mb-1" v-text="meeting.subject"></h3>
    <div>
      <v-icon color="grey-lighten-1" class="mr-1" size="x-small"
        >mdi-clock-outline</v-icon
      ><small v-text="date"></small>
    </div>

    <v-btn
      v-if="isUrl(meeting.location)"
      prepend-icon="mdi-video-outline"
      class="text-right my-2"
      @click="openMeeting"
      target="_blank"
      :loading="loading"
      size="small"
      variant="tonal"
      color="accent"
      >Meeting beitreten</v-btn
    >
    <div v-else-if="meeting.location !== 'No location'">
      <v-icon color="grey-lighten-1" size="x-small" class="mr-1"
        >mdi-map-marker-outline</v-icon
      >
      <small v-text="meeting.location"></small>
    </div>
    <!-- <v-progress-linear v-model="progress" class="my-5" height="15px" rounded color="accent"/> -->
    <div class="d-flex mt-3">
      <MeetingParticipantAvatar
        style="margin-right: -15px"
        v-for="participant in meeting.participants"
        :key="participant.id"
        :participant="participant"
      />
    </div>
  </div>
</template>

<style scoped></style>
