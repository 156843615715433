<template>
  <span v-text="display"></span>
</template>

<script>
export default {
  name: "EnumDisplay",
  props: {
    enumObject: Object,
    value: String,
  },
  computed: {
    display() {
      return this.getValue("de", this.value);
    },
  },
  methods: {
    getValue(language, value) {
      if (!value) return "-";
      if (this.enumObject[value]) {
        return this.enumObject[value][language];
      } else {
        return value;
      }
    },
  },
};
</script>

<style scoped></style>
