import { createRouter, createWebHistory } from "vue-router";
import HomeView from "@/views/HomeView";
import AuthView from "@/views/AuthView";
import { useAuthUserStore } from "@/stores/modules/auth.module";
import LoginComponent from "@/components/auth/LoginComponent";
import PropertyView from "@/views/PropertyView";
import PropertyDetailsView from "@/views/PropertyDetailsView.vue";
import DashboardView from "@/views/DashboardView";
import LogbookView from "@/views/LogbookView";
import AgentWrapper from "@/views/agent/AgentWrapper";
import AgentPropertyView from "@/views/agent/AgentPropertyView";
import AgentPropertyDetails from "@/views/agent/AgentPropertyDetails";
import TestComponent from "@/views/TestComponent";
import AdminWrapper from "@/views/admin/AdminWrapper.vue";
import ProfileView from "@/views/ProfileView.vue";
import { useNotificationStore } from "@/stores/modules/notification.module";


function guardMyRoute(to, from, next) {
  const authUserStore = useAuthUserStore();
  let isAuthenticated = authUserStore.status.loggedIn;
  if (to.name !== "Login" && !isAuthenticated) {
    next("/a/login");
  } else {
    next();
  }
}

function adminRoute(to, from, next) {
  const authUserStore = useAuthUserStore();
  console.log("admin route", authUserStore.user);
  if ((to.name !== "Login") & !authUserStore.hasRole(["ROLE_ADMIN"])) {
    console.log("Not allowed");
    notAllowed();
    next("/");
  } else {
    next();
  }
}
function notAllowed() {
  console.log("not allowed");
  const notificationStore = useNotificationStore();
  notificationStore.pushError("Sie sind für diese Seite nicht berechtigt.");
}

function maklerRoute(to, from, next) {
  const authUserStore = useAuthUserStore();
  if (
    (to.name !== "Login") &
    !authUserStore.hasRole(["ROLE_MAKLER", "ROLE_ADMIN"])
  ) {
    console.log("Not allowed");
    notAllowed();
    next("/");
  } else {
    next();
  }
}

const routes = [
  {
    path: "/profile",
    name: "Profil",
    component: ProfileView,
    redirect: { name: "Overview" },
    children: [
      {
        path: "",
        name: "Persönliches Profil",
        component: () => import("@/components/profile/ProfileSettingsView.vue"),
      },
      {
        path: "info",
        name: "Persönliche Informationen",
        component: () => import("@/components/profile/EditUserInfo.vue"),
      },
    ],
  },
  {
    path: "/admin",
    name: "Admin",
    redirect: { name: "AdminUserList" },
    beforeEnter: adminRoute,
    component: AdminWrapper,
    children: [
      {
        path: "users",
        name: "AdminUserList",
        component: () => import("@/views/admin/AdminUserListView.vue"),
      },
    ],
  },
  {
    path: "/appointment/:id/:action",
    name: "Appointment",
    props: true,
    meta: { appBarBackButton: false, anonymous: true },
    component: () => import("@/components/meetings/MeetingActions.vue"),
  },
  {
    path: "/appointment/:id",
    name: "AppointmentView",
    props: true,
    component: () => import("@/views/SingleAppointmentView.vue"),
  },
  { path: "/test", component: TestComponent },
  { path: "/", component: HomeView, beforeEnter: guardMyRoute },
  { path: "/properties", component: PropertyView, beforeEnter: guardMyRoute },
  {
    path: "/agent",
    beforeEnter: maklerRoute,
    component: AgentWrapper,
    children: [
      {
        name: "PropertyOverview",
        path: "properties",
        component: AgentPropertyView,
      },
      {
        name: "PropertyDetailsAgent",
        path: "property/:id",
        props: true,
        component: AgentPropertyDetails,
      },
    ],
  },
  {
    path: "/property/:id",
    props: true,
    component: PropertyDetailsView,
    beforeEnter: guardMyRoute,
  },
  { path: "/dashboard", component: DashboardView, beforeEnter: guardMyRoute },
  { path: "/logbook/:id", component: LogbookView, props: true },
  {
    path: "/a",
    name: "Auth",
    component: AuthView,
    children: [
      {
        name: "Login",
        path: "login",
        component: LoginComponent,
      },
    ],
    props: true,
  },
  {
    path: "/logout",
    meta: { appBarBackButton: false },
    name: "Logout",
    component: () => import("@/components/auth/Logout"),
  },
  {
    path: "/property/:propertyId/edit/",
    name: "PropertyEdit",
    props: true,
    component: () => import("@/views/EditPropertyView.vue"),
    redirect: { name: "BasicsEdit" },
    children: [
      {
        path: "basic",
        name: "BasicsEdit",
        props: true,
        component: () =>
          import("@/components/property/edit/overall/BasicsEdit.vue"),
      },
      {
        path: "financials",
        name: "FinancialsEdit",
        props: true,
        component: () =>
          import("@/components/property/edit/overall/FinancialsEdit.vue"),
      },
      {
        path: "rental-details",
        props: true,
        name: "RentalDetailsEdit",
        component: () =>
          import("@/components/property/edit/overall/RentalDetailsEdit.vue"),
      },
      {
        path: "valuation",
        props: true,
        name: "ValuationEdit",
        component: () =>
          import("@/components/property/edit/overall/ValuationEdit.vue"),
      },
      {
        path: "address",
        props: true,
        name: "AddressEdit",
        component: () =>
          import("@/components/property/edit/overall/AddressEdit.vue"),
      },
      {
        path: "files",
        props: true,
        name: "FilesEdit",
        component: () =>
          import("@/components/property/edit/overall/files/FilesEdit.vue"),
      },
      {
        path: "mandate-contract",
        props: true,
        name: "MandateContractEdit",
        component: () =>
          import(
            "@/components/property/edit/overall/files/MandateContractEdit.vue"
          ),
      },
      {
        path: "gallery",
        props: true,
        name: "GalleryEdit",
        component: () =>
          import("@/components/property/edit/overall/files/GalleryEdit.vue"),
      },

      {
        path: "building-basic",
        name: "BuildingBasicsEdit",
        props: true,
        component: () =>
          import("@/components/property/edit/building/BuildingBasicsEdit.vue"),
      },

      {
        path: "equipment-building",
        name: "EquipmentBuildingEdit",
        props: true,
        component: () =>
          import(
            "@/components/property/edit/building/EquipmentBuildingEdit.vue"
          ),
      },
      {
        path: "energy-building",
        name: "EnergyBuildingEdit",
        props: true,
        component: () =>
          import("@/components/property/edit/building/EnergyBuildingEdit.vue"),
      },
      {
        path: "connect-building",
        name: "ConnectionBuildingEdit",
        props: true,
        component: () =>
          import(
            "@/components/property/edit/building/ConnectionBuildingEdit.vue"
          ),
      },
      {
        path: "units-building",
        props: true,
        name: "UnitsBuildingEdit",
        component: () =>
          import("@/components/property/unit/AddUnitWrapper.vue"),
      },
      {
        path: "unit-basic",
        props: true,
        name: "UnitBasicsEdit",
        component: () =>
          import("@/components/property/edit/unit/UnitBasicsEdit.vue"),
      },
      {
        path: "equipment-unit",
        props: true,
        name: "EquipmentUnitEdit",
        component: () =>
          import("@/components/property/edit/unit/EquipmentUnitEdit.vue"),
      },
      {
        path: "financials-unit",
        props: true,
        name: "FinancialsUnitEdit",
        component: () =>
          import("@/components/property/edit/unit/FinancialsUnitEdit.vue"),
      },
      {
        path: "rental-details-unit",
        props: true,
        name: "RentalDetailsUnitEdit",
        component: () =>
          import("@/components/property/edit/unit/RentalDetailsUnitEdit.vue"),
      },
      {
        path: "land-basic",
        props: true,
        name: "LandBasicsEdit",
        component: () =>
          import("@/components/property/edit/land/LandBasicsEdit.vue"),
      },
      // {
      //   path: "location",
      //   props: true,
      //   name: "LocationEdit",
      //   component: () =>
      //     import("@/components/property/edit/overall/MapLocationEdit.vue"),
      // },
      // {
      //   path: "gallery",
      //   props: true,
      //   name: "GalleryEdit",
      //   component: () =>
      //     import("@/components/property/edit/overall/files/GalleryEdit.vue"),
      // },
    ],
  },
  {
    path: "/u",
    name: "Anonym",
    component: () => import("@/views/anonym/AnonymousView"),
    children: [
      {
        path: "reset/password/u/:userId/t/:token",
        name: "Password Reset",
        props: true,
        meta: { appBarBackButton: false, anonymous: true },
        component: () => import("@/views/anonym/ForgotPasswordView"),
      },
      {
        path: "reset/password/request",
        name: "Request Password Reset",
        meta: { appBarBackButton: false, anonymous: true },
        component: () => import("@/views/anonym/RequestPasswordForgotView"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = to.meta?.title || "Rubikon";
  next();
});

export default router;
