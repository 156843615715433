import {defineStore} from "pinia";
import {findPolygonCenter, getUserLocationWithoutMapbox} from "@/services/map.service";
import mapboxgl from "mapbox-gl";

const state = () => ({
    map:null,
    show:true,
    selectedBuilding:null,
    userPosition:null,
    geolocate:null,
    rotating: false,
    rotationRequestId: null,
});
export const useMapStore = defineStore('mapStore',{
    state: state,

// getters
    getters: {
        getData(){
            return this.map.getSource("properties")._data;
        },
        hasMap(){
          return this.map!=null;
        },
        async getUserPositionLngLat() {
            if (this.userPosition) {
                return this.userPosition;
            } else {
                try{
                    return await getUserLocationWithoutMapbox();
                }catch (e) {
                    console.log("Error while getting user location",e)
                    return null;
                }
            }
        }
    },

// actions
    actions:{
        toggleMap(){
            this.show = !this.show;

        },
        removeMap() {

            if(!this.map) return;
                console.log("Remove map",this.map);
            this.map.remove();
            this.map = null;
            console.log("Remove map",this.map);
        },
        addGeoLocationControl(){
            this.geolocate = new mapboxgl.GeolocateControl({
                positionOptions: {
                    enableHighAccuracy: true,
                },
                showUserHeading:true,
                trackUserLocation: false,
                showAccuracyCircle:false,
            });
            this.map.addControl(this.geolocate);
            this.geolocate.on('geolocate', () => {
                this.stopRotation();
            });

        },
        addMarker(coordinates){
            new mapboxgl.Marker()
                .setLngLat(coordinates)
                .addTo(this.map);
        },
        flyToGeometry(geometry){
            this.stopRotation();
            console.log("Geometry",geometry)
            setTimeout(()=>{
            this.map.flyTo({
                center:geometry.coordinates,
                zoom:17,
                pitch:45
            });

            },200);
        },
        flyToBuilding(property){
            this.stopRotation();
          try{let coordinates = property?.address?.mapMarker?.coordinates;
          if(coordinates){
              this.flyToPolygon(JSON.parse(coordinates)[0]);
          }}catch (e) {
                console.log("Error while flying to building",e)
          }
        },
        flyToPoint(point){
          this.stopRotation();
            this.map.flyTo({
                center:point,
              zoom:17,
              pitch:45
          });
        },
        flyToPolygon(polygon){
            try {
                this.stopRotation();
                let center = findPolygonCenter(polygon);
                setTimeout(() => {
                    this.map.flyTo({
                        center: center,
                        zoom:17,
                        pitch:45
                    });
                }, 200);
            }catch (e) {
                console.log("Error while flying to polygon",e)
            }
        },
        updateData(data){
            let oldData = this.map.getSource("properties")._data;
            oldData.features.push(data);
            this.map.getSource("properties").setData(oldData);
            console.log("id data",data)
            this.map.setFeatureState(
                {sourceLayer: "building", source: "composite", id: data.properties.id},
                {registered: true}
            );
            this.map.moveLayer("add-3d-properties","add-3d-buildings");
        //    this.map.getSource("composite").setData(removeFeatures(this.map.getSource("composite")._data.features,this.map.getSource("properties")._data.features));
        },
        setMap(map){
            this.map=map;
        },
        selectBuilding(map,feature){
            let oldSelected = this.selectedBuilding;
            if(oldSelected) {
                map.setFeatureState(
                    {sourceLayer: oldSelected.sourceLayer, source: oldSelected.source, id: oldSelected.id},
                    {selected: false}
                );
            }

            map.setFeatureState(
                {sourceLayer: feature.sourceLayer, source: feature.source, id: feature.id},
                {selected: true}
            );
            this.selectedBuilding= feature;
            let center = findPolygonCenter(feature.geometry.coordinates[0]);

            setTimeout(()=>{
                map.flyTo({
                    center: center
                });
            },200);

        },
        rotateCamera(timestamp) {
            if (this.rotating) {
                this.map.rotateTo((timestamp / 100) % 360, { duration: 0 });
                this.map.setPitch(20);
                this.map.setZoom(2);
                this.rotationRequestId = requestAnimationFrame(this.rotateCamera);
            }
        },
        startRotation() {
            if (!this.rotating) {
                this.rotating = true;
                this.rotationRequestId = requestAnimationFrame(this.rotateCamera);
            }
        },
        stopRotation() {
            if (this.rotating) {
                this.rotating = false;
                cancelAnimationFrame(this.rotationRequestId);
            }
        }
    }
});