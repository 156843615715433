<script>
import CreateMandateContract from "@/components/property/contract/CreateMandateContract.vue";

export default {
  name: "UpdateMandateContractDialog",
  components: { CreateMandateContract },
  data() {
    return {
      show: false,
    };
  },
  props: {
    propertyId: String,
  },
  methods: {
    updateContract(data) {
      this.$emit("update", data);
      this.show = false;
    },
  },
};
</script>

<template>
  <v-dialog max-width="600" v-model="show">
    <template #activator="{ props }">
      <v-btn block class="my-2" v-bind="props">
        <span>Mandatsvertrag bearbeiten</span>
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="mx-2 mt-2">
        <span>Mandatsvertrag bearbeiten</span>
      </v-card-title>
      <v-divider
        class="mb-5 mx-6 mt-1 border-opacity-100"
        color="accent"
        thickness="1"
      ></v-divider>
      <v-card-text>
        <CreateMandateContract
          @update="updateContract"
          :propertyId="propertyId"
        ></CreateMandateContract>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<style scoped></style>
