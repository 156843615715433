const EContractStatus = {
  CREATED: { en: "Created", de: "Erstellt" },
  CONTRACT_MISSING: { en: "Contract missing", de: "Vertrag fehlt" },
  WAITING_FOR_SIGNATURE: {
    en: "Waiting for signature",
    de: "Warten auf Unterschrift",
  },
  SIGNED: { en: "Signed", de: "Unterschrieben" },
};

export default EContractStatus;
