import ApiService from "@/services/api.service";

export async function loadPropertySaleStates(propertyId){
    const response = await ApiService.get("sale/process/"+propertyId+"/get");
    return response.data;
}

export async function changeStatus(processId,status){
    const response = await ApiService.get("sale/process/"+processId+"/status/change/"+status);
    return response.data;
}

export async function changesNotes(processId,notes){
    const response = await ApiService.post("sale/process/"+processId+"/notes/save",{text:notes});
    return response.data;
}

export async function getMyPropertyStatus(propertyId){
    const response = await ApiService.get("sale/process/"+propertyId+"/info/my");
    console.log("getMyPropertyStatus",response)
    return response.data;
}

export async function getOwnerPropertyStatus(propertyId){
    const response = await ApiService.get("sale/process/"+propertyId+"/info/owner");
    return response?.data;
}
