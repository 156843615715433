<template>
  <v-card class="my-5 pa-3 black lighten-2">
    <div class="text-left mb-4">
      <h2 class="text-h6 font-weight-bold">Mein Immobilien Portfolio</h2>
    </div>
    <v-row>
      <v-col cols="4">
        <div class="d-flex flex-column ga-2">
          <v-card flat class="pa-3 bg-grey-darken-35">
            <p class="text-disabled mb-0">Eigene</p>
            <p class="text-h5 font-weight-bold mb-0">
              {{ data.ownPropertiesCount }}
            </p>
          </v-card>
          <v-card flat class="pa-3 bg-grey-darken-35">
            <p class="text-disabled mb-0">Gespeichert</p>
            <p class="text-h5 font-weight-bold mb-0">
              {{ data.savedPropertiesCount }}
            </p>
          </v-card>
        </div>
      </v-col>
      <v-col cols="8" class="pl-0">
        <v-card
          flat
          class="d-flex align-center justify-center h-100 pa-2 bg-grey-darken-35"
        >
          <BarChart
            v-if="!data.sumMarketValue <= 0"
            label-name="EUR"
            :data="chartData"
          ></BarChart>
          <span
            class="font-weight-medium d-flex align-center justify-center"
            v-else
            >Nicht genügend Daten vorhanden</span
          >
          <!-- <div class="d-flex justify-space-between align-center">
            <div
              class="bar-chart"
              style="width: 70%; height: 30px; background-color: #26a69a"
            ></div>
            <span class="text-caption">2.345.000 €</span>
          </div>
          <div class="d-flex justify-start mt-2">
            <span class="text-caption">630.000 €</span>
          </div> -->
        </v-card>
      </v-col>
    </v-row>
    <v-card flat class="mt-3 bg-grey-darken-35">
      <p class="text-body-1 text-left font-weight-bold ma-2">Meine Angebote</p>
      <div class="overflow-y-hidden pa-1">
        <v-row class="flex-nowrap" style="width: max-content">
          <v-col
            v-for="(offer, index) in offers"
            :key="index"
            cols="auto"
            class="pa-0 pl-2 py-2 ml-1 my-2"
          >
            <v-card
              @click="
                $router.push({
                  path: `/property/${offer.propertyId}`,
                  query: { tab: 'angebot' },
                })
              "
              v-tooltip:top="{
                text: 'Klicken, um Angebot anzusehen',
                openDelay: 2000,
              }"
              class="pa-2 bg-grey-darken-4"
            >
              <div class="d-flex ga-1 align-center mb-1">
                <span class="text-caption">{{ offer.label }}</span>
                <v-chip
                  :color="chipColor(offer.status)"
                  size="x-small"
                  class="font-weight-medium"
                >
                  <EnumDisplay
                    :enumObject="offerStatus"
                    :value="offer.status"
                  />
                </v-chip>
              </div>
              <p
                class="text-subtitle-2 text-left mb-0"
                :class="{
                  'text-decoration-line-through text-grey': offer.counterOffer,
                  'font-weight-bold': !offer.counterOffer,
                }"
              >
                <CurrencyDisplay :number="offer.purchasePrice" />
              </p>
              <div
                v-if="offer.counterOffer"
                class="text-subtitle-2 text-left font-weight-bold mb-0 d-flex justify-space-between align-center"
              >
                <CurrencyDisplay :number="offer.counterOffer" />
                <v-btn
                  @click="
                    $router.push({
                      path: `/property/${offer.propertyId}`,
                      query: { tab: 'angebot' },
                    })
                  "
                  icon="mdi-arrow-right"
                  tile
                  rounded
                  size="x-small"
                  color="accent"
                  variant="text"
                  v-tooltip:top="{
                    text: 'Gegenangebot ansehen',
                    openDelay: 300,
                  }"
                ></v-btn>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-card>
  </v-card>
</template>

<script>
import BarChart from "@/components/data/BarChart.vue";
import { useNotificationStore } from "@/stores/modules/notification.module";
import ApiService from "@/services/api.service";
import CurrencyDisplay from "@/components/helpers/CurrencyDisplay.vue";
import EnumDisplay from "@/components/helpers/EnumDisplay.vue";
import EOfferStatus from "@/components/enums/EOfferStatus.js";
export default {
  setup() {
    const notificationStore = useNotificationStore();
    return {
      notificationStore,
    };
  },
  name: "UserInsights",
  components: {
    BarChart,
    EnumDisplay,
    CurrencyDisplay,
  },
  data() {
    return {
      data: {},
      chartData: [],
      offers: [],
      offerStatus: EOfferStatus,
      // offers: [
      //   {
      //     name: "Gebäude 234",
      //     price: "750.000 €",
      //     status: "Offen",
      //     color: "green lighten-4",
      //     chipColor: "green",
      //   },
      //   {
      //     name: "Gebäude 136",
      //     price: "235.000 €",
      //     status: "Abgelehnt",
      //     color: "red lighten-4",
      //     chipColor: "red",
      //   },
      //   {
      //     name: "Gebäude 234",
      //     price: "1.259.000 €",
      //     status: "Offen",
      //     color: "green lighten-4",
      //     chipColor: "green",
      //   },
      //   {
      //     name: "Gebäude 234",
      //     price: "1.259.000 €",
      //     status: "Offen",
      //     color: "green lighten-4",
      //     chipColor: "green",
      //   },
      //   {
      //     name: "Gebäude 234",
      //     price: "1.259.000 €",
      //     status: "Offen",
      //     color: "green lighten-4",
      //     chipColor: "green",
      //   },
      //   {
      //     name: "Gebäude 234",
      //     price: "1.259.000 €",
      //     status: "Offen",
      //     color: "green lighten-4",
      //     chipColor: "green",
      //   },
      // ],
    };
  },
  methods: {
    async getUserInsights() {
      this.loading = true;
      try {
        const response = await ApiService.get("dashboard/user");
        this.data = response.data;
        this.offers = this.data.estateOffers;
        if (this.data.ownPropertiesCount > 1) {
          this.chartData = [
            {
              label: "Marktwert (gesamt)",
              average: this.data.averageMarketValue,
              value: this.data.sumMarketValue,
            },
          ];
        } else {
          this.chartData = [
            {
              label: "Marktwert (gesamt)",
              value: this.data.sumMarketValue,
            },
          ];
        }
        console.log("User Insights", this.data);
      } catch (error) {
        this.notificationStore.pushError("Fehler beim Laden der Daten.");
      } finally {
        this.loading = false;
      }
    },
    chipColor(status) {
      switch (status) {
        case "PENDING":
          return "yellow";
        case "DECLINED":
          return "red";
        case "WITHDRAWED":
          return "red";
        case "ACCEPTED":
          return "green";
        default:
          return "grey";
      }
    },
  },
  async created() {
    await this.getUserInsights();
  },
};
</script>

<style scoped>
.bar-chart {
  position: relative;
}
.bar-chart::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 5px;
  background-color: #80cbc4;
}
.bg-grey-darken-35 {
  background-color: #282727;
}
</style>
