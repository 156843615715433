<template>
  <div>
    <v-tabs v-model="tab" class="mb-5">
      <v-tab color="accent">Verfügbar</v-tab>
      <v-tab color="accent">Vermarktung</v-tab>
      <v-tab color="accent">Archiv</v-tab>
    </v-tabs>

    <v-window v-model="tab">
      <v-window-item>
        <div class="d-flex flex-column" style="gap: 10px">
          <AvailablePropertiesPagableList />
        </div>
      </v-window-item>
      <v-window-item>
        <AgentPropertiesPagableList />
      </v-window-item>
      <v-window-item>
        <ArchivedPropertiesPagableList />
      </v-window-item>
    </v-window>
  </div>
</template>

<script>
import AvailablePropertiesPagableList from "@/components/property/AvailablePropertiesPagableList.vue";
import AgentPropertiesPagableList from "@/components/agent/property/AgentPropertiesPagableList.vue";
import ArchivedPropertiesPagableList from "@/components/agent/property/ArchivedPropertiesPagableList.vue";
export default {
  name: "PropertyListAgent",
  components: {
    ArchivedPropertiesPagableList,
    AgentPropertiesPagableList,
    AvailablePropertiesPagableList,
  },
  data() {
    return {
      tab: null,
    };
  },
  watch: {
    tab(newVal) {
      if (newVal === 1 || newVal === 3) {
        this.$emit("changeSize", true);
      } else {
        this.$emit("changeSize", false);
      }
    },
  },
  props: {},
};
</script>

<style scoped></style>
