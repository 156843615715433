import {defineStore} from "pinia";
import {getProfile} from "@/services/profile.service";

const state = () => ({
    profile: {
        loading: false,
        content: null
    }
});

export const useProfileStore = defineStore('profileStore', {

    state: state,

    getters: {
    },

    actions: {
        async fetchProfile() {
            this.profile.loading = true;
            try {
                const response = await getProfile();
                this.profile.content = response;
                this.profile.loading = false;
            } catch (e) {
                this.profile.loading = false;
            } finally {
                this.profile.loading = false;
            }
        },
        async updateProfile(profile) {
            this.profile.loading = true;
            try {
                console.log(profile)
               // const response = await updateProfile(profile);
                //this.profile.content = response;
                this.profile.loading = false;
            } catch (e) {
                this.profile.loading = false;
            } finally {
                this.profile.loading = false;
            }
        }
    }
});