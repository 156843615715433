<template>
<span v-text="betterReadable"></span>
</template>

<script>
import {betterReadableNumber} from "@/services/helpers.service";

export default {
  name: "ReadableNumber",
  props:{
    number:Number
  },
  computed:{
    betterReadable(){
      if(!this.number) return "-";
      return betterReadableNumber(this.number)
    }
  }
}
</script>

<style scoped>

</style>