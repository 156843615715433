<script>
import DetailImage from "@/components/DetailImage.vue";

export default {
  name: "GalleryComponent",
  components: {DetailImage},
  props:{
    images:Array
  },
  methods:{
    getUrl(image){
      return window.URL.createObjectURL(image)
    },
  }
}
</script>

<template>
  <div>
      <v-slide-group
          class="py-4"
          center-active
          show-arrows
      >
        <v-slide-group-item>
          <v-overlay class="d-flex  justify-center align-center" v-for="(image,$index) of images" :key="$index" v-model="image.detail">
            <template #activator="{props}">
              <img v-bind="props" class="shrink mx-1" height="150" :src="image.image ? 'data:image/webp;base64,'+image.image : ''" style="opacity: 0.5;"/>
            </template>
            <DetailImage :show="image.detail" :small-image="image" :image-id="image.id"/>
          </v-overlay> </v-slide-group-item>
      </v-slide-group>
    </div>
</template>