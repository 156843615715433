<script>
import EnumDisplay from "@/components/helpers/EnumDisplay.vue";
import { changeStatus } from "@/services/property.service";

export default {
  name: "PropertyProcessState",
  components: { EnumDisplay },
  props: {
    state: String,
    propertyId: Number,
  },
  watch: {
    state: {
      handler() {
        if (this.state != null) {
          try {
            changeStatus(this.propertyId, this.state).then(() => {
              this.$emit("update:state", this.state);
            });
          } catch (e) {
            console.error(e);
          }
        }
      },
    },
  },
  computed: {
    stateC: {
      get() {
        return [this.state];
      },
      set(value) {
        if (Array.isArray(value) && value.length > 0)
          this.$emit("update:state", value[0]);
        else if (value) this.$emit("update:state", value);
      },
    },
    states() {
      return this.getEnumList("de", this.statesObject);
    },
  },
  methods: {
    select(state) {
      this.stateC = state;
    },
    getEnumList(language, enumObject) {
      const usageList = [];
      for (const key in enumObject) {
        if (Object.prototype.hasOwnProperty.call(enumObject, key)) {
          const value = enumObject[key];
          const item = {
            label: value[language],
            value: key,
          };
          usageList.push(item);
        }
      }
      return usageList;
    },
  },
  data() {
    return {
      show: false,
      selected: null,
      statesObject: {
        WARTEND: { en: "Wartend", de: "Wartend" },
        IN_EINWERTUNG: { en: "In Einwertung", de: "In Einwertung" },
        MANDATSVERGABE: { en: "Mandatsvergabe", de: "Mandatsvergabe" },
        IN_VORVERMARKTUNG: { en: "In Vorvermarktung", de: "In Vorvermarktung" },
        IN_VERMARKTUNG: { en: "In Vermarktung", de: "In Vermarktung" },
        DATENRAUM_WIRD_BEFUELLT: {
          en: "Datenraum wird befüllt",
          de: "Datenraum wird befüllt",
        },
        ANPRACHE_VON_POT_INTERESSENTEN: {
          en: "Ansprache von pot. Interessenten",
          de: "Ansprache von pot. Interessenten",
        },
        ANGEBOTSMANAGEMENT: {
          en: "Angebotsmanagement",
          de: "Angebotsmanagement",
        },
        VERKAUFSPHASE: { en: "Verkaufsphase", de: "Verkaufsphase" },
      },
    };
  },
};
</script>

<template>
  <v-dialog max-width="400px" scrollable v-model="show">
    <template #activator="{ props }">
      <v-chip
        style="opacity: 0.9"
        :disabled="state === 'VERKAUFT'"
        class="text-white mb-3"
        size="small"
        v-bind="props"
        >Status: <EnumDisplay :enum-object="statesObject" :value="state"
      /></v-chip>
    </template>

    <v-card :title="'Status:'">
      <v-card-text style="height: 200px">
        <v-list
          select-strategy="single-leaf"
          :items="states"
          item-value="value"
          item-title="label"
          @click="this.show = false"
          v-model:selected="stateC"
        >
        </v-list>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<style scoped></style>
