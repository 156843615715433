<template>
<div>
  <h2 class="text-left mb-5">Logbook of Property {{id}}</h2>
  <LogbookItem :property-id="Number(id)"/>
</div>
</template>

<script>
import LogbookItem from "@/components/logbook/LogbookItem";
export default {
  name: "LogbookView",
  components: {LogbookItem},
  props:{
    id:String
  }
}
</script>

<style scoped>

</style>