<script>
import {getValuationRequest, saveValuation} from "@/services/valuation.service";
import KreaNumberInput from "@/components/KreaNumberInput.vue";

export default {
  name: "CreateNewValuation",
  components: {KreaNumberInput},
  props:{
    propertyId: String,
  },
  emits: ["create"],
  data() {
    return {
      loaded:false,
      valuation: {
        fairValue: null,
        runningCosts: null,
        landValue: null,
        maintenanceExpenses: null,
      },
      rules: {
        required: (value) => !!value || "Eingabe erforderlich",
      },
    };
  },
  methods: {
    async save() {
      let valuation = await saveValuation(this.propertyId, this.valuation);
      this.$emit("create", valuation);
    },
    async setValuation() {
      if (this.loaded) return;
      console.log("Valuation 1", this.valuation);
      this.valuation = await this.getCreateValuationRequest(this.propertyId);
      console.log("Valuation", this.valuation);
    },
    async getCreateValuationRequest(propertyId) {
      return await getValuationRequest(propertyId);
    },
  },

}
</script>

<template>
<v-dialog>
  <template #activator="{props}">
    <v-btn @click="setValuation" v-bind="props">Neue Bewertung hinzufügen</v-btn>
  </template>
  <v-card>
    <v-card-title>
      <span class="headline">Neue Bewertung hinzufügen
      </span>
    </v-card-title>
    <v-card-text>
      <v-form>
        <KreaNumberInput
          v-model="valuation.fairValue"
          label="Fairer Wert"
          :rules="[rules.required]"
        ></KreaNumberInput>
        <KreaNumberInput
          v-model="valuation.runningCosts"
          label="Betriebskosten"
          :rules="[rules.required]"
        ></KreaNumberInput>
        <v-text-field
          v-model="valuation.landValue"
          label="Grundstückswert"
          :rules="[rules.required]"
        ></v-text-field>
        <KreaNumberInput
          v-model="valuation.maintenanceExpenses"
          label="Instandhaltungskosten"
          :rules="[rules.required]"
        ></KreaNumberInput>
        <v-btn @click="save" color="accent">Speichern</v-btn>
      </v-form>
    </v-card-text>
  </v-card>
</v-dialog>
</template>

<style scoped>

</style>