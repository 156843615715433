import authHeader from "@/services/auth-header";

const axios = require("axios");
import host from "./host";
import { useAuthUserStore } from "@/stores/modules/auth.module";
import { useNotificationStore } from "@/stores/modules/notification.module";
import NotificationElephant from "@/models/notification";
const API_URL = host + "/api/";
import router from "@/plugins/vue-router/router";
class ApiService {
  get(url) {
    let self = this;
    const authUserStore = useAuthUserStore();
    if (!authUserStore) {
      return new Promise(() => {
        return null;
      });
    }
      return axios({ url: API_URL + url, headers: authHeader() })
          .then((response) => {
            return response;
          })
          .catch(function (error) {
            if (error.response) {
              self.handleElephantError(error.response);
            }
          });

  }

  handleElephantError(error) {
    if (error.status === 401) {
      const authStore = useAuthUserStore();
      console.log("Unauthorisierter Zugriff", error);
      const authUserStore = useAuthUserStore();
      let refreshToken = authUserStore.user?.refreshToken;
      if (refreshToken) {
        console.log("Trying to refresh token for user", authUserStore.user);
        authUserStore
          .refreshLogin(authUserStore.user.refreshToken)
          .catch(function (error) {
            console.log("Error refreshing token", error);
            authStore.logout();
            console.log("Redirecting to login", router.currentRoute.value.path);
            router.push("/a/login?from="+router.currentRoute.value.path);
          });
        console.log("Token got refreshed successful");
      } else {
        console.log("no refresh token. Logging out.");
        authStore.logout();
        console.log("Redirecting to login", router.currentRoute.value.path);
        router.push("/a/login?from="+router.currentRoute.value.path);
      }
    }
    if (error.data?.show) {
      console.log("Error", error.data);
      const notificationStore = useNotificationStore();
      let data = error.data;
      notificationStore.push(
        new NotificationElephant({
          title: data.title,
          message: data.message,
          color: "ERROR",
        })
      );
    }
    throw error;
  }
  getUnauthorized(url) {
    return axios.get(API_URL + url);
  }
  postUnauthorized(url, data) {
    return axios.post(API_URL + url, data);
  }

  post(url, data) {
    let self = this;
    // eslint-disable-next-line no-console
    //console.log(API_URL+url+" Data "+data+"  HEader"+authHeader());

    const authUserStore = useAuthUserStore();
    if (!authUserStore) {
      return new Promise(() => {
        return null;
      });
    }
    return axios
      .post(API_URL + url, data, { headers: authHeader() })
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (error.response) {
          return self.handleElephantError(error.response);
        }
      });
  }
  postWithAdditionalHeader(url, data, additionalHeader) {
    let self = this;
    let headers = { headers: authHeader() };
    headers.headers = { ...headers.headers, ...additionalHeader };
    console.log("HEader", headers);
    // eslint-disable-next-line no-console
    //console.log(API_URL+url+" Data "+data+" HEader"+authHeader());
    return axios
      .post(API_URL + url, data, headers)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        if (error.response) {
          self.handleElephantError(error.response);
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
        }
      });
  }
  callOpenStreetMapApi(query) {
    return axios.get(
      "https://nominatim.openstreetmap.org/?addressdetails=1&q=" +
        query +
        "&format=json&limit=3"
    );
  }
  async checkServerState() {
    let response = await axios
      .get(host + "/actuator/health")
      .catch(function (error) {
        if (error.response) {
          const notificationStore = useNotificationStore();
          notificationStore.push(
            new NotificationElephant({
              title: "Keine Verbindung mit dem Server möglich",
              message:
                "Der Server ist entweder offline oder deine Verbindung ist schlecht.",
              color: "ERROR",
            })
          );

          return false;
        }
      });
    if (response && response.data && response.data.status === "UP") return true;
    else {
      const notificationStore = useNotificationStore();
      notificationStore.push(
        new NotificationElephant({
          title: "Keine Verbindung mit dem Server möglich",
          message: "Der Server ist offline.",
          color: "ERROR",
        })
      );

      return false;
    }
  }
}

export default new ApiService();
