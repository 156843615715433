<script>
import CreateAppointment from "@/components/appointment/CreateAppointment.vue";

export default {
  name: "CreateAppointmentDialog",
  components: {CreateAppointment},
  data() {
    return {
      show: false
    }
  },
  emits: ['terminCreated'],
  props:{
    type:String,
    propertyId:String,
    propertyLabel:String,
    userId:String
  },
}
</script>

<template>
<v-dialog max-width="400px" v-model="show">
  <template #activator="{props}">
    <slot name="activator" v-bind="props">
      <v-btn icon v-bind="props"><v-icon>mdi-calendar-plus</v-icon></v-btn>
    </slot>
  </template>
  <v-card>
    <v-card-title>Termin anlegen</v-card-title>
    <v-card-subtitle><span>{{type}} </span><span><span>Immobilie </span><span v-text="propertyId"></span></span></v-card-subtitle>
    <v-card-text>
      <CreateAppointment @termin-created="(e) => {this.$emit('terminCreated',e);this.show=false}" :type="type" :property-id="propertyId" :user-id="userId"/>
    </v-card-text>
  </v-card>
</v-dialog>
</template>

<style scoped>

</style>