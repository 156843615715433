<script>
import UserAvatar from "@/components/user/Avatar.vue";

export default {
  name: "UserChip",
  components: {UserAvatar},
  props:{
    user:Object
  },
}
</script>

<template>
  <v-menu v-if="user">
    <template #activator="{props}">
      <v-chip v-bind="props" size="small"><template #prepend></template><UserAvatar :user="user"/><span v-text="user.name"></span></v-chip>
    </template>
    <v-card>
    <v-list-item color="black" :subtitle="user.email">
      <template #title>
        <v-chip size="small" class="mr-2"># <span v-text="user.id"></span></v-chip><span v-text="user.name"></span>
      </template>
    </v-list-item>
    </v-card>
  </v-menu>

</template>

<style scoped>

</style>