import ApiService from "@/services/api.service";

export async function loadGallery(propertyId) {
    const response = await ApiService.get("property/"+propertyId+"/gallery/get");
    return response.data;
}

export async function getGalleryItem(id){
    const response = await ApiService.get("property/gallery/item/"+id);
    return response.data;
}

export async function uploadFilesToGallery(propertyId,request){
    const response = await ApiService.post("property/"+propertyId+"/gallery/upload",request);
    return response.data;
}

export async function deleteGalleryItem(id){
    const response = await ApiService.get("property/gallery/item/"+id+"/delete");
    return response.data;
}
export async function setGalleryThumbnail(id){
    const response = await ApiService.get("property/gallery/item/"+id+"/thumbnail/set");
    return response.data;
}
export async function getThumbnailOfProperty(id){
    const response = await ApiService.get("property/"+id+"/thumbnail/get");
    return response.data;
}