<script>
import DocumentList from "./documents/DocumentList.vue";
import { fetchFiles, fetchTags } from "@/services/property.service.js";
export default {
  name: "PropertyFilesPopup",
  components: { DocumentList },
  props: {
    property: Object,
    modelValue: Boolean,
  },
  data() {
    return {
      tags: [],
      files: [],
      show: false,
    };
  },
  methods: {
    async fetchTags() {
      this.tags = await fetchTags();
    },
    async fetchFiles() {
      this.files = await fetchFiles(this.property.id);
    },
  },
  created() {
    this.fetchTags();
    this.fetchFiles();
  },
  computed: {},
};
</script>

<template>
  <v-dialog max-width="600" v-model="show">
    <template #activator="{ props }">
      <slot name="activator" v-bind="props">
        <v-btn v-bind="props" variant="plain" icon
          ><v-icon>mdi-file-document-multiple-outline</v-icon></v-btn
        >
      </slot>
    </template>
    <v-card>
      <DocumentList
        customClass="mx-5"
        :files="files"
        :allTags="tags"
      ></DocumentList>
    </v-card>
  </v-dialog>
</template>

<style scoped></style>
