<script>
import QAndACommentsList from "@/components/property/qAndA/QAndACommentsList.vue";

export default {
  name: "QAndACommentsDialog",
  components: {QAndACommentsList},
  props:{
    commentsCount:{
      type:Number,
      required:true
    },
    questionName:{
      type:String,
      required:true
    },
    qAndAId: {
      type: Number,
      required: true
    }
  },
  data(){
    return{
      show:false,
    }
  }
}
</script>

<template>
<v-dialog v-model="show" max-width="500">
  <template #activator="{props}">
    <v-btn class="pa-0 float-right" style="height: 20px;width:20px" size="small"  icon variant="plain" v-bind="props">

      <v-badge v-if="commentsCount>0" :content="commentsCount" color="white">
        <v-icon>mdi-comment-outline</v-icon>

      </v-badge>
      <v-icon v-else>mdi-comment-outline</v-icon>
    </v-btn>
  </template>
  <v-card>
    <v-card-title v-text="questionName">

    </v-card-title>
    <v-card-text>
      <QAndACommentsList :load="show" :q-and-a-id="qAndAId"/>
    </v-card-text>
  </v-card>
</v-dialog>
</template>

<style scoped>

</style>