<template>
  <v-dialog max-width="500" v-model="show">
    <template #activator="{ props }">
      <slot name="activator" v-bind="props"> </slot>
    </template>
    <v-card>
      <v-card-text>
        <CreateUserSimple
          :predefined-email="preDefinedEmail"
          @cancel="show = false"
          @userCreated="userCreated"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import CreateUserSimple from "@/components/user/CreateUserSimple.vue";
export default {
  name: "CreateUserSimpleDialog",
  components: { CreateUserSimple },
  props: {
    modelValue: Boolean,
    preDefinedEmail: String,
  },
  data() {
    return {};
  },
  emits: ["update:modelValue", "userCreated"],
  computed: {
    show: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  methods: {
    userCreated(user) {
      this.$emit("userCreated", user);
      this.show = false;
    },
  },
};
</script>

<style scoped></style>
