import axios from 'axios';
import host from "./host";
const API_URL =host+'/api/auth/';
import ApiService from "@/services/api.service";

class AuthService {
    login(user) {
        return axios
            .post(API_URL + 'signin', user)
            .then(response => {

                return response.data;
            }).catch(function (error) {
                return error;
            });
    }

    logout() {
        localStorage.removeItem('user');
    }

    register(user) {
        return axios.post(API_URL + 'signup', user).then(response => {
            if (response.data.accessToken) {
                localStorage.setItem('user', JSON.stringify(response.data));
            }
            return response.data;
        }).catch(function (error) {
            if (error.response) {
                ApiService.handleElephantError(error.response);
            }
        });
    }

    refreshToken(refreshToken){
        return axios.post(API_URL + 'refresh', {refreshToken: refreshToken}).then(response => {
            if (response.data.accessToken) {
                localStorage.setItem('user', JSON.stringify(response.data));
            }
            return response.data;
        });
    }
}

export default new AuthService();
