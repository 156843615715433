import ApiService from "@/services/api.service";

export async function getValuationRequest(propertyId){
    let response = await ApiService.get("valuation/request/property/"+propertyId+"/get");
    return response.data;
}

export async function saveValuation(propertyId,createValuationRequest){
    let response = await ApiService.post("valuation/property/"+propertyId+"/create", createValuationRequest);
    return response.data;
}

export async function getValuation(propertyId){
    let response = await ApiService.get("valuation/property/"+propertyId+"/get");
    return response.data;
}