<script>
import {signContract} from "@/services/saleProcess/contract.service";

export default {
  name: "SignMandateContract",
  props: {
    propertyId: String
  },
  methods:{
    async sign() {
      if (confirm("Möchten Sie den Mandatsvertrag unterschreiben?")) {
        let data = await signContract(this.propertyId);
        this.$emit("update:contract", data);
      }
    }
  }
}
</script>

<template>
<div>
    <v-btn block class="my-2" @click="sign" color="accent">
      <span>Mandatsvertrag unterschreiben</span>
    </v-btn>
  </div>
</template>

<style scoped>

</style>