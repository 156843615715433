import ApiService from "@/services/api.service";
import {useAuthUserStore} from "@/stores/modules/auth.module";

export async function createOffer(offer,propertyId) {
    if(!propertyId) return null;
    const response = await ApiService.post("offer/property/"+propertyId+"/add", offer);
    return response.data;
}
export async function updateOffer(offer,propertyId){
    if(!propertyId) return null;
    const response = await ApiService.post("offer/property/"+propertyId+"/edit", offer);
    return response.data;

}
export async function getMyOfferOfProperty(propertyId) {
    const response = await ApiService.get("offer/property/"+propertyId+"/get/my");
    return response.data;
}

export async function withdrawOffer(propertyId) {
    const authUserStore = useAuthUserStore();
    const userId = authUserStore.user.id;
    const response = await ApiService.get("offer/property/"+propertyId+"/user/"+userId+"/withdraw");
    return response.data;
}
export async function acceptOffer(propertyId,userId) {
    const response = await ApiService.get("offer/property/"+propertyId+"/user/"+userId+"/accept");
    return response.data;
}
export async function declineOffer(propertyId,userId) {

    const response = await ApiService.get("offer/property/"+propertyId+"/user/"+userId+"/decline");
    return response.data;
}
export async function pendingOffer(propertyId,userId) {
    const response = await ApiService.get("offer/property/"+propertyId+"/user/"+userId+"/pending");
    return response.data;
}

export async function counterOffer(propertyId,userId,amount) {
    const response = await ApiService.post("offer/property/"+propertyId+"/user/"+userId+"/counter", {price:amount});
    return response.data;
}

