<script>
import { useMapStore } from "@/stores/modules/map.module";

export default {
  name: "ShowOnMapButton",
  methods: {
    showOnMap() {
      this.mapStore.flyToBuilding(this.property);
    },
  },
  props: {
    property: Object,
  },
  setup() {
    const mapStore = useMapStore();
    return { mapStore };
  },
};
</script>

<template>
  <v-btn
    @click.stop="showOnMap"
    v-tooltip:top="{
      text: 'auf Karte anzeigen',
      openDelay: 1000,
    }"
    tile
    rounded
    color="accent"
    size="small"
    icon
    ><v-icon size="small" class="">mdi-map-outline</v-icon></v-btn
  >
</template>

<style scoped></style>
