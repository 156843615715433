<template>
  <div class="chat-item"
       :class="{ 'chat-item-right': comment.author.id === loggedInUser.id, 'chat-item-left': comment.author.id !== loggedInUser.id }">
    <div class="d-flex justify-space-between align-center" style="gap:15px">
      <div class="chat-item-author" :style="{ color: authorColor }">{{ comment.author.name }}</div>

    </div>
    <div class="d-flex align-end" style="gap:15px">
      <div style="width:100%;gap:5px" class="d-flex justify-space-between align-end">

        <div class="chat-item-text">{{ comment.text }}</div>
        <div class="chat-item-timestamp">{{ betterDate }}</div>
      </div>
    </div>

  </div>
</template>
<script>
import moment from "moment/moment";
import {useAuthUserStore} from "@/stores/modules/auth.module";
export default {
  name: 'QAndACommentItem',
  components: {},
  setup() {
    const authUserStore = useAuthUserStore();
    const loggedInUser = authUserStore.user;
    return {
      loggedInUser
    }
  },
  props: {
    comment: Object
  },
  computed: {
    betterDate() {
      return moment(this.comment.timestamp).format("HH:mm")
    }
  }
}
</script>
<style>

.chat-item {
  max-width: 60%;
  margin-bottom: 15px;
  padding: 10px;
  border-radius: 10px;
  clear: both;
  line-height: 1.3;
  position: relative;
}

.chat-item-right {
  align-self: flex-end;
  background-color: rgb(var(--v-theme-accent));
  float: right;
}

.chat-item-left {
  align-self: flex-start;
  background-color: rgb(var(--v-theme-accent));
  float: left;
}

.chat-item-author {
  font-weight: bold;
  font-size: 0.9em;
  text-align: left;
}

.chat-item-text {
  word-wrap: break-word;
}

.chat-item-timestamp {
  font-size: 0.7em;
  bottom: 0;
  color: #364b38;
  text-align: right;
}
</style>