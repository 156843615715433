<script>
import {getOwnerPropertyStatus} from "@/services/saleProcess/process.service";
import UserChip from "@/components/helpers/UserChip.vue";
import EnumDisplay from "@/components/helpers/EnumDisplay.vue";
import SaleProcessSmallInfo from "@/components/property/owner/SaleProcessSmallInfo.vue";

export default {
  name: "SaleInfoForOwner",
  components: {SaleProcessSmallInfo, EnumDisplay, UserChip},
  data(){
    return{
      info:null,
      statesObject: {
      WARTEND: {en: "Wartend", de: "Wartend"},
      IN_EINWERTUNG: {en: "In Einwertung", de: "In Einwertung"},
      MANDATSVERGABE: {en: "Mandatsvergabe", de: "Mandatsvergabe"},
      IN_VORVERMARKTUNG: {en: "In Vorvermarktung", de: "In Vorvermarktung"},
      IN_VERMARKTUNG: {en: "In Vermarktung", de: "In Vermarktung"},
      DATENRAUM_WIRD_BEFUELLT: {en: "Datenraum wird befüllt", de: "Datenraum wird befüllt"},
      ANPRACHE_VON_POT_INTERESSENTEN: {
        en: "Ansprache von pot. Interessenten",
            de: "Ansprache von pot. Interessenten"
      },
      ANGEBOTSMANAGEMENT: {en: "Angebotsmanagement", de: "Angebotsmanagement"},
      VERKAUFSPHASE: {en: "Verkaufsphase", de: "Verkaufsphase"}
    }
    }
  },
  props:{
    propertyId:Number
  },
  created() {
    this.getInfo();
  },
  methods:{
    async getInfo() {
      this.info = await getOwnerPropertyStatus(this.propertyId)
    }
  },
}
</script>

<template>
  <div v-if="info">
    <v-table>
      <tbody>
      <tr v-for="(makler,$index) of info.makler" :key="makler.id">
        <td>
          Makler {{$index+1}}
        </td>
        <td>
          <UserChip :user="makler"/>
        </td>
      </tr>
      <tr>
        <td>
          Status
        </td>
        <td>
          <EnumDisplay :value="info.propertyProcessState" :enum-object="statesObject"/>
        </td>
      </tr>
      <tr v-for="(process,$index) of info.processes" :key="process.id">
        <td>
          Angebot #{{$index+1}}
        </td>
        <td>
          <SaleProcessSmallInfo :process="process"/>
        </td>
      </tr>
      </tbody>
    </v-table>
  </div>
  <div v-else class="d-flex justify-center align-center pa-10">Kein Verkauf</div>
</template>

