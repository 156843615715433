import { createApp } from "vue";
import App from "./App.vue";
import router from "@/plugins/vue-router/router";
import pinia from "@/plugins/pinia";

import "@/style/global.scss";
// Vuetify
import "vuetify/styles";
import { createVuetify } from "vuetify";
import "@mdi/font/css/materialdesignicons.css";
import moment from "moment/moment";
moment.locale("de");
const kreaDark = {
  dark: true,
  colors: {
    accent: "#93B195",
  },
};
import { VCalendar } from "vuetify/labs/VCalendar";
import { VDateInput } from "vuetify/labs/VDateInput";

const vuetify = createVuetify({
  defaults: {
    VTextField: {
      variant: "outlined",
    },
  },
  theme: {
    defaultTheme: "kreaDark",
    themes: {
      kreaDark,
    },
  },
  components: {
    VCalendar,
    VDateInput,
  },
  icons: {
    defaultSet: "mdi", // This is already the default value - only for display purposes
  },
});


//I18n
import { createI18n } from "vue-i18n";

import { messages } from "@/plugins/i18n/messages";
const i18n = createI18n({
  locale: "de",
  messages: messages,
});

createApp(App).use(router).use(pinia).use(vuetify).use(i18n).mount("#app");
