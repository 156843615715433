<script>
import KreaNumberInput from "@/components/KreaNumberInput.vue";
import KreaInput from "@/components/KreaInput.vue";

export default {
  name: "CreateOffer",
  components: {KreaInput, KreaNumberInput},
  props:{
    offer:Object,
    submitText:String
  },
  computed:{
    offerC:{
      get(){
        return this.offer;
      },
      set(value){
        this.$emit('update:offer', value);
      }
    }
  },
}
</script>

<template>
<v-form>
  <KreaNumberInput append-inner-icon="mdi-currency-eur" label="Höhe des Angebots" v-model="offerC.purchasePrice"></KreaNumberInput>
  <KreaInput v-model:input-value="offerC.validUntil" label="Gültig bis" type="date"></KreaInput>
  <v-btn class="mt-5" block color="accent" @click="$emit('submit', offerC)">{{submitText}}</v-btn>
</v-form>
</template>

<style scoped>

</style>