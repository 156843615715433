function generateSVGFromCoordinates(coordinates, width) {
    // Calculate the minimum and maximum values for x and y coordinates
    let minX = Number.MAX_VALUE;
    let minY = Number.MAX_VALUE;
    let maxX = Number.MIN_VALUE;
    let maxY = Number.MIN_VALUE;

    coordinates[0].forEach(coord => {
        minX = Math.min(minX, coord[0]);
        minY = Math.min(minY, coord[1]);
        maxX = Math.max(maxX, coord[0]);
        maxY = Math.max(maxY, coord[1]);
    });

    // Calculate the scale factor based on the width
    const scaleX = width / (maxX - minX);
    const scaleY = width / (maxY - minY);
    const scale = Math.min(scaleX, scaleY);

    // Generate the SVG path
    let path = "M" + (coordinates[0][0][0] - minX) * scale + " " + (coordinates[0][0][1] - minY) * scale;
    coordinates[0].slice(1).forEach(coord => {
        path += " L" + (coord[0] - minX) * scale + " " + (coord[1] - minY) * scale;
    });
    path += " Z";

    // Construct the SVG
    const svg = `<svg xmlns="http://www.w3.org/2000/svg" width="${width}" height="${width}" viewBox="0 0 ${width} ${width}">
                    <path d="${path}" fill="#dce6dd" stroke="#93B195" stroke-width="2" />
                </svg>`;

    return svg;
}

module.exports = { generateSVGFromCoordinates };