<template>
  <v-dialog max-width="600" v-model="show">
    <template #activator="{ props }">
      <v-checkbox
        v-bind="props"
        label="Benutzer benachrichtigen"
        @click.prevent
        v-model="mail.send"
      ></v-checkbox>
    </template>
    <v-card>
      <v-card-text>
        <v-textarea
          hint="Anrede & wichtigste Informationen werden automatisch generiert."
          style="width: 100%"
          rows="5"
          v-model="mail.text"
          label="eigene Nachricht (optional)"
        ></v-textarea>
      </v-card-text>
      <v-card-actions>
        <v-btn variant="plain" @click="cancel">Abbrechen</v-btn>
        <v-btn color="accent" @click="ok">OK</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "NotifyUser",
  data() {
    return {
      show: false,
    };
  },
  props: {
    modelValue: Object,
  },
  emits: ["update:modelValue"],
  computed: {
    mail: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  methods: {
    cancel() {
      this.mail = { send: false, text: null };
      this.show = false;
    },
    ok() {
      this.mail = {
        send: true,
        text: this.mail.text,
      };
      this.show = false;
    },
  },
};
</script>

<style scoped></style>
