<script>
export default {
  name: "AdminWrapper",
};
</script>

<template>
  <v-navigation-drawer order="3">
    <v-list-item
      class="pa-5"
      title="Admin"
      subtitle="Einstellungen"
    ></v-list-item>
    <v-divider></v-divider>
    <v-list-item
      class="text-left"
      prepend-icon="mdi-account-multiple"
      to="/admin/users"
      link
      title="Alle Benutzer"
    ></v-list-item>
  </v-navigation-drawer>
  <router-view />
</template>

<style scoped></style>
