<script>
import {useAuthUserStore} from "@/stores/modules/auth.module";
import {createQAndA, getQAndAByPage} from "@/services/qAndA/qAndA.service";
import QAndAChatItem from "@/components/property/qAndA/QAndAChatItem.vue";
import moment from "moment/moment";
export default {
  name: "QAndAChat",
  components: {QAndAChatItem},
  setup() {
    const authUserStore = useAuthUserStore();
    const loggedInUser = authUserStore.user;
    return {
      loggedInUser
    }
  },
  props: {
    propertyId: {
      type: Number,
      required: true
    },
    userId: {
      type: Number,
      required: true
    }
  },
  created() {
    this.loadItems(0);
  },
  methods: {
    dateDisplay(date){
      if(moment(date).isAfter(moment().subtract(1, 'week'))){
        return moment(date).format('dddd');
      }
      return moment(date).format('DD.MM.YYYY');
    },
    async addItem() {
      if (!this.text) return;
      const distanceFromBottom = document.documentElement.scrollHeight - window.scrollY;

      let item = await createQAndA(this.text, this.propertyId, this.userId);
      this.items.unshift(item.data);
      this.items = this.items.sort((a, b) => b.timestamp - a.timestamp);
      this.$nextTick(() => {
        window.scrollTo(0, document.documentElement.scrollHeight - distanceFromBottom);
      });
      },

    loadItems(page) {
      return new Promise((resolve, reject) => {
        getQAndAByPage(page, this.propertyId, this.userId).then(response => {
          this.page = response.data;
          let items = this.items.concat(this.page.content);
          this.items = items;
          resolve();
        }).catch(error => {
          reject(error);
        });
      });
    },
    async loadMore() {
      // Save the current distance from the bottom of the page
      const distanceFromBottom = document.documentElement.scrollHeight - window.scrollY;

      await this.loadItems(this.page.number + 1);

      // Restore the scroll position after the items have been loaded
      this.$nextTick(() => {
        window.scrollTo(0, document.documentElement.scrollHeight - distanceFromBottom);
      });
    },
    getAuthorColor(name) {
      if (!this.authorColors[name]) {
        // Assign a color from the colors array to the author
        this.authorColors[name] = this.colors[Object.keys(this.authorColors).length % this.colors.length];
      }

      return this.authorColors[name];
    },
      },
  computed:{
    groupedItems() {
      return this.items.reduce((groups, item) => {
        const date = item.timestamp.split('T')[0]; // get the date part of the timestamp
        if (!groups[date]) {
          groups[date] = [];
        }
        groups[date].push(item);
        return groups;
      }, {});
    }
  },
  data() {
    return {
      page: null,
      items:[],
      colors: [
        '#FFFFFF', // White
        '#000000', // Black
        '#FFC107', // Amber
        '#FF5722', // Deep Orange
        '#E91E63', // Pink
        '#9C27B0', // Purple
        '#3F51B5', // Indigo
        '#2196F3', // Blue
        '#03A9F4', // Light Blue
        '#00BCD4', // Cyan
        '#009688', // Teal
        '#4CAF50', // Green
        '#8BC34A', // Light Green
        '#CDDC39', // Lime
        '#FFEB3B', // Yellow
        '#FF9800'  // Orange
      ],
      authorColors: {},
      text:null
    }
  }
}
</script>
<template>
  <div style="position: relative;height:100%;max-height: 100%" class="d-flex flex-column justify-space-between" v-if="page">
    <v-btn variant="plain" class="py-5 mx-auto " block v-if="!page.last" color="white" @click="loadMore">Load More</v-btn>
    <div v-else></div>
    <div>
    <div class="flex-grow-1 chat-container">
      <div v-for="(items, date) in groupedItems" :key="date">
        <div style="height:50px" class="d-flex justify-center align-center text-disabled">{{ dateDisplay(date) }}</div> <!-- This is the heading for each day -->
       <div class="d-flex flex-column-reverse">
          <QAndAChatItem v-for="item in items" :key="item.id" :item="item" :author-color="getAuthorColor(item.author.name)"/>
        </div>
      </div>
    </div>
    <div>
    <v-text-field
      v-model="text"
      label="Schreiben..."
      @keyup.enter="addItem"
      append-inner-icon="mdi-send"
      @click:append-inner="addItem"
    />
    </div>
    </div>
  </div>

</template>

<style scoped>

.chat-container {
  display: flex;
  flex-direction: column-reverse;
  padding: 10px;
  box-sizing: border-box;
  overflow-y: scroll;
  height: 600px;
  flex-grow: 1; /* Make the chat container take up the remaining space */
}

</style>