<template>
  <v-avatar :size="size" class="mr-1">
    <v-img
      rounded
      :src="
        user?.avatar
          ? 'data:image/webp;base64,' + user?.avatar
          : 'https://ui-avatars.com/api/?name=' + name
      "
    ></v-img>
  </v-avatar>
</template>

<script>
export default {
  name: "UserAvatar",
  props: {
    user: Object,
    size: {
      type: Number,
      default: 26,
    },
  },
  computed: {
    name() {
      if (!this.user) return "AN";
      if (this.user.name) return this.user.name;
      return this.user.firstName + "" + this.user.lastName;
    },
  },
};
</script>

<style scoped></style>
