export default class CreateInterestRequest{
    constructor({note,buyIntention=0,userId,userEmail,createUserRequest=null,addedByAgent=false,sendMail=false,customText=null}){
        this.note=note;
        this.userId=userId;
        this.buyIntention=buyIntention;
        this.userEmail=userEmail;
        this.createUserRequest=createUserRequest;
        this.addedByAgent= addedByAgent;
        this.sendEmail=sendMail;
        this.customText=customText;
    }

}