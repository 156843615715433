<script>
import SelectUserDialog from "@/components/form/SelectUserDialog.vue";

export default {
  name: "CreateLogBookPlaceholderUser",
  components: {SelectUserDialog},
  data(){
    return{
      userId:null,
      label:null,
      show:false,
    }
  },
  methods:{
    userSelected(){
      let text = "[user id="+this.userId+" label=\""+this.label+"\"]";
      this.$emit('addUser', text);
      this.show=false;
    }
  }
}
</script>

<template>
<div>
  <SelectUserDialog label="User suchen" title="Nutzer hinzufügen" v-model:show="show" v-model="userId">
    <template #no-data>
      <v-list-item class="pa-2">
        <template #title>Keinen User gefunden.</template>
      </v-list-item>
    </template>
    <template #content>

      <v-text-field hint="bsp: Name des Nutzers" class="mt-5" label="Label" v-model="label"/>
      <small>Es wird automatisch ein Link erstellt, der die Userdaten im Logbuch anzeigt. Das Label ist dabei der Linktext.</small>
      <v-btn class="mt-5" block :disabled="!userId" @click="userSelected">Hinzufügen</v-btn>
    </template>
  </SelectUserDialog>

</div>
</template>

<style scoped>

</style>