<script>
import { addPropertyRelation } from "@/services/property.relation.service";
import SelectUser from "@/components/form/SelectUser.vue";

export default {
  name: "AddRelationToProperty",
  components: { SelectUser },
  data() {
    return {
      show: false,
      userId: null,
    };
  },
  props: {
    propertyId: String,
    relationType: String,
  },
  methods: {
    async addRelation() {
      if (!this.userId) {
        return alert("Bitte wählen Sie einen Benutzer aus");
      }
      console.log("Add Relation");
      const relation = await addPropertyRelation(
        this.propertyId,
        this.userId,
        this.relationType
      );
      this.$emit("relation-added", relation);
      this.show = false;
    },
  },
};
</script>

<template>
  <v-dialog max-width="400" v-model="show">
    <template #activator="{ props }">
      <slot name="activator" v-bind="props">
        <v-btn class="my-5" color="accent" block v-bind="props"
          ><v-icon class="mr-1">mdi-plus</v-icon>Makler hinzufügen</v-btn
        >
      </slot>
    </template>
    <v-card>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <SelectUser v-model="userId" label="User auswählen..." />
          </v-col>
          <v-col cols="12">
            <v-btn block @click="addRelation" color="accent">Hinzufügen</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<style scoped></style>
