<script>
import { getAppointmentByProperty } from "@/services/appointment.service";
import AppointmentList from "@/components/appointment/AppointmentList.vue";
import CreateAppointmentDialog from "@/components/appointment/CreateAppointmentDialog.vue";

export default {
  name: "AppointmentsOfProperty",
  components: { CreateAppointmentDialog, AppointmentList },
  props: {
    propertyId: Number,
    type: String,
  },
  data() {
    return {
      appointments: [],
      show: false,
    };
  },
  created() {
    this.getAppointments();
  },
  methods: {
    async getAppointments() {
      this.appointments = await getAppointmentByProperty(this.propertyId);
    },
  },
};
</script>

<template>
  <v-dialog v-model="show" max-width="550px">
    <template #activator="{ props }">
      <v-btn
        v-tooltip:top="{
          text: 'Termine',
          openDelay: 1000,
        }"
        class="pa-0"
        size="small"
        color="accent"
        tile
        rounded
        @click="getAppointments"
        v-bind="props"
        icon
        ><v-icon size="small">mdi-calendar</v-icon></v-btn
      >
    </template>
    <v-card>
      <v-card-text>
        <AppointmentList :appointments="appointments" />
        <CreateAppointmentDialog
          @termin-created="
            (t) => {
              this.appointments.push(t);
            }
          "
          :type="type"
          :property-id="propertyId"
        >
          <template #activator="props">
            <v-btn class="my-5" color="accent" v-bind="props" block
              ><v-icon>mdi-calendar-plus</v-icon> Termin anlegen</v-btn
            >
          </template>
        </CreateAppointmentDialog>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<style scoped></style>
