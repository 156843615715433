<script>
import SimpleUserListItem from "@/components/user/SimpleUserListItem.vue";
import {
  getMaklersOfProperty,
  removePropertyRelation,
} from "@/services/property.relation.service";
import AddRelationToProperty from "@/components/property/relation/AddRelationToProperty.vue";

export default {
  name: "MaklersOfPropertyView",
  components: { AddRelationToProperty, SimpleUserListItem },
  props: {
    propertyId: String,
  },
  data() {
    return {
      users: [],
      loading: false,
    };
  },
  async created() {
    this.loading = true;
    this.users = await getMaklersOfProperty(this.propertyId);
    this.loading = false;
  },
  methods: {
    async deleteRelation(userId) {
      console.log("Delete Relation");
      let success = removePropertyRelation(this.propertyId, userId, "MAKLER");
      if (success) {
        this.users = this.users.filter((user) => user.id !== userId);
      }
    },
  },
};
</script>

<template>
  <section class="pa-5">
    <v-list class="text-left">
      <v-skeleton-loader
        v-if="loading"
        type="list-item-three-line"
        :loading="loading"
      />
      <div
        v-if="!loading && users.length <= 0"
        class="pa-5 d-flex justify-center align-center"
      >
        Diese Immobilie ist keinem Makler zugeordnet
      </div>
      <div v-else>
        <div
          class="d-flex justify-space-between"
          v-for="user of users"
          :key="user.id"
        >
          <SimpleUserListItem :user="user"></SimpleUserListItem>
          <v-btn
            icon="mdi-delete"
            variant="flat"
            @click="deleteRelation(user.id)"
          ></v-btn>
        </div>
      </div>
    </v-list>
    <AddRelationToProperty
      @relation-added="users.push($event)"
      :propertyId="propertyId"
      :relationType="'MAKLER'"
    />
  </section>
</template>

<style scoped></style>
