import ApiService from "@/services/api.service";

export async function createQAndA(text, propertyId, userId) {
    return await ApiService.post('qAndA/property/'+propertyId+'/user/'+userId+'/add', {
        text: text
    });
}
export async function getQAndAByPage(page, propertyId,userId) {
    return await ApiService.get('qAndA/property/'+propertyId+'/user/'+userId+'/page/'+page);
}

export async function updateQAndAItem(id, text) {
    return await ApiService.put('qAndA/'+id+'/update', {
        text: text
    });
}

export async function addComment(id, text) {
    return await ApiService.post('qAndA/'+id+'/comment/add', {
        text: text
    });
}

export async function getCommentsByPage(id,page) {
    return await ApiService.get('qAndA/'+id+'/comments/page/'+page);
}

