<script>
import CreateAppointmentRequest from "@/models/CreateAppointmentRequest";
import KreaInput from "@/components/KreaInput.vue";
import SelectUser from "@/components/form/SelectUser.vue";
import { createAppointment } from "@/services/appointment.service";
import SelectEnum from "@/components/form/SelectEnum.vue";
import EAppointmentType from "@/components/enums/EAppointmentType";
import { useNotificationStore } from "@/stores/modules/notification.module";
import NotificationElephant from "@/models/notification";
export default {
  name: "CreateAppointment",
  components: { SelectEnum, SelectUser, KreaInput },
  props: {
    type: String,
    propertyId: String,
    userId: String,
  },
  setup() {
    const notificationStore = useNotificationStore();
    return { notificationStore };
  },
  created() {
    this.appointmentRequest.appointmentType = this.type;
  },
  data() {
    return {
      appointmentRequest: new CreateAppointmentRequest({}),
      appointmentTypes: EAppointmentType,
      startValid: true,
      endValid: true,
    };
  },
  emits: ["terminCreated"],
  watch: {
    "appointmentRequest.start": function (newVal) {
      this.startValid = !!newVal;
      console.log("value changed");
    },
    "appointmentRequest.end": function (newVal) {
      this.endValid = !!newVal;
      console.log("value changed");
    },
  },
  methods: {
    async submitForm() {
      const { valid } = await this.$refs.form.validate();
      if (!this.appointmentRequest.start) {
        this.startValid = false;
      }
      if (!this.appointmentRequest.end) {
        this.endValid = false;
      }
      // if (!this.appointmentRequest.start || !this.appointmentRequest.end) {
      //   this.notificationStore.pushError(
      //     "Start- und Endzeit sind erforderlich"
      //   );
      // }

      if (
        valid &&
        this.appointmentRequest.start &&
        this.appointmentRequest.end
      ) {
        this.createAppointment();
        this.$refs.form.reset();
      }
    },
    createAppointment: async function () {
      try {
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        this.appointmentRequest.timezone = timezone;
        console.log("Timezone", timezone);
        if (!this.appointmentRequest.participants)
          this.appointmentRequest.participants = [];
        this.appointmentRequest.propertyId = this.propertyId;
        console.log("Create Appointment", this.appointmentRequest);
        let response = await createAppointment(this.appointmentRequest);
        this.$emit("terminCreated", response);
        this.notificationStore.push(
          new NotificationElephant({
            message: "Termin erfolgreich erstellt",
            color: "SUCCESS",
          })
        );
      } catch (error) {
        this.notificationStore.pushError(
          "Fehler beim Erstellen des Termins. ",
          error
        );
      }
    },
  },
};
</script>

<template>
  <v-form ref="form" @submit.prevent="submitForm">
    <v-text-field
      label="Titel"
      v-model="appointmentRequest.subject"
      :rules="[(v) => !!v || 'Titel ist erforderlich']"
    ></v-text-field>
    <SelectEnum
      v-model="appointmentRequest.appointmentType"
      label="Typ"
      :data="appointmentTypes"
    />
    <v-textarea
      label="Beschreibung"
      v-model="appointmentRequest.description"
    ></v-textarea>
    <KreaInput
      v-bind:inputClass="{ 'text-error': !startValid }"
      type="datetime-local"
      label="Start"
      v-model:input-value="appointmentRequest.start"
    />
    <KreaInput
      v-bind:inputClass="{ 'text-error': !endValid }"
      type="datetime-local"
      label="Ende"
      v-model:input-value="appointmentRequest.end"
    />
    <SelectUser
      class="my-5"
      label="Gäste hinzufügen"
      icon="mdi-account-multiple"
      multiple
      v-model="appointmentRequest.participants"
    />
    <v-text-field
      prepend-inner-icon="mdi-map-marker"
      label="Ort oder Vieokonferenz-Link"
      v-model="appointmentRequest.location"
    ></v-text-field>
    <v-btn color="accent" type="submit" block>Termin anlegen</v-btn>
  </v-form>
</template>

<style scoped>
/* Erstelle ein klasse, die text und border farbe zu rot macht */
</style>
