<template>
    <v-navigation-drawer color="transparent" border="none" class="pb-5 pl-5" temporary width="600" location="right" v-model="value">
      <v-sheet style="min-height:100%" class="rounded-s">
        <slot name="content"></slot>

      </v-sheet>
      </v-navigation-drawer>
</template>
  <script>
    export default {
      name: 'SideBoard',
      props: ['modelValue'],
      emits: ['update:modelValue'],
      watch:{
      },
      computed: {
        value: {
          get() {
            return this.modelValue
          },
          set(value) {
            this.$emit('update:modelValue', value)
          }
        }
      }
    }
  </script>