<template>
  <section class="mt-8" :class="customClass">
    <h3 class="text-left">Nutzerfreigaben für alle Tags verwalten</h3>

    <v-divider
      class="mb-5 mt-1 border-opacity-100"
      color="accent"
      thickness="1"
    ></v-divider>
    <v-combobox
      append-icon="mdi-filter-outline"
      :items="allTags"
      label="Tags filtern"
      v-model="filterTags"
      item-title="tag.name"
      item-value="tag.key"
      density="comfortable"
      multiple
      chips
      clearable
      @keydown.enter.prevent
    ></v-combobox>
    <v-card class="elevation-0">
      <v-list
        class="text-left d-flex flex-column flex-wrap ga-2"
        rounded
        v-if="allTags.length != 0"
      >
        <v-card-text style="max-height: 500px">
          <template v-for="(tag, index) of filteredTags" :key="tag.tag.key">
            <v-list-item rounded class="font-weight-bold">
              <v-list-item-title class="font-weight-medium mb-2">
                {{ tag.tag.page }} {{ tag.tag.name }}
              </v-list-item-title>
              <template v-slot:append>
                <v-dialog width="auto" scrollable v-if="hasRole">
                  <template v-slot:activator="{ props: activatorProps }">
                    <v-btn
                      icon="mdi-account-lock-open"
                      color="grey-darken-3"
                      size="small"
                      class="mr-2"
                      variant="flat"
                      v-tooltip:top="{
                        text: 'Berechtigung erteilen',
                        openDelay: 1000,
                      }"
                      v-bind="activatorProps"
                    ></v-btn>
                  </template>

                  <template v-slot:default="{ isActive }">
                    <v-card :title="`Nutzer für ${tag.tag.name} freigeben`">
                      <v-divider class="mt-3"></v-divider>

                      <v-card-text>
                        <SelectUser v-model="selectedUser"></SelectUser>
                        <v-checkbox
                          class="mt-3 mb-0"
                          v-model="canAccessChildren"
                          label="Zugriff auf untergeordnete Tags erlauben"
                        ></v-checkbox>
                      </v-card-text>

                      <v-divider></v-divider>

                      <v-card-actions>
                        <v-btn
                          text="Abbrechen"
                          @click="
                            isActive.value = false;
                            canAccessChildren = false;
                            selectedUser = null;
                          "
                        ></v-btn>

                        <v-spacer></v-spacer>

                        <v-btn
                          color="accent"
                          text="Freigeben"
                          variant="flat"
                          @click="
                            isActive.value = false;
                            giveAccess(
                              selectedUser,
                              tag.tag.key,
                              canAccessChildren
                            );
                          "
                        ></v-btn>
                      </v-card-actions>
                    </v-card>
                  </template>
                </v-dialog>
              </template>
              <v-chip
                v-for="user in tag.users"
                :key="user.id"
                size="small"
                color="grey"
                class="mr-1 mb-1"
              >
                {{ user.name }}
                <v-icon @click="removeAccess(user.id, tag.tag.key)" class="ml-1"
                  >mdi-close-circle</v-icon
                >
              </v-chip>
            </v-list-item>
            <v-divider
              class="mx-2 my-2"
              v-if="index < allTags.length - 1"
            ></v-divider>
          </template>
        </v-card-text>
      </v-list>

      <div class="my-16 text-center" v-else>Noch keine Tags vorhanden</div>
    </v-card>
  </section>
</template>

<script>
import SelectUser from "@/components/form/SelectUser.vue";
import ApiService from "@/services/api.service";
import { useAuthUserStore } from "@/stores/modules/auth.module";
import { useNotificationStore } from "@/stores/modules/notification.module";

export default {
  name: "ManageTagPermission",
  components: { SelectUser },
  setup() {
    const authStore = useAuthUserStore();
    const notificationStore = useNotificationStore();
    const hasRole = authStore.hasRole(["ROLE_ADMIN", "ROLE_AGENT"]);
    return {
      hasRole,
      notificationStore,
    };
  },
  props: {
    propertyId: String,

    customClass: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      filterTags: [],
      allTags: [],
      selectedUser: null,
      canAccessChildren: false,
    };
  },
  computed: {
    filteredTags() {
      if (!this.filterTags.length) {
        return this.allTags;
      }
      return this.allTags.filter((tag) =>
        this.filterTags.some((filterTag) => filterTag.tag.key === tag.tag.key)
      );
    },
  },
  methods: {
    async getPermissions() {
      try {
        const response = await ApiService.get(
          "permission/tags/property/" + this.propertyId + "/permission/get"
        );
        // Get all unique user IDs
        const userIds = [...new Set(response.data.flatMap((tag) => tag.users))];
        // Get user names
        try {
          const userNamesResponse = await ApiService.post(
            "user/get/by/ids",
            userIds
          );
          const userNames = userNamesResponse.data;
          // Add user names to user objects
          this.allTags = response.data.map((tag) => ({
            ...tag,
            users: tag.users.map((userId) => {
              const user = userNames.find((user) => user.id === userId);
              return { id: userId, name: user ? user.name : undefined };
            }),
          }));
        } catch (error) {
          this.notificationStore.pushError(
            "Fehler beim Laden der Nutzerdaten.",
            error
          );
        }
      } catch (error) {
        this.notificationStore.pushError(
          "Fehler beim Speichern der Änderungen.",
          error
        );
      }
    },
    async giveAccess(user, tag, canAccessChildren) {
      try {
        await ApiService.get(
          "permission/add/user/" +
            user +
            "/property/" +
            this.propertyId +
            "/tag/" +
            tag +
            "/children/" +
            canAccessChildren
        );
      } catch (error) {
        this.notificationStore.pushError(
          "Fehler beim Speichern der Änderungen.",
          error
        );
      } finally {
        this.selectedUser = null;
        this.canAccessChildren = false;
        this.getPermissions();
      }
    },
    async removeAccess(userId, tagKey) {
      try {
        await ApiService.get(
          "permission/remove/user/" +
            userId +
            "/property/" +
            this.propertyId +
            "/tag/" +
            tagKey
        );
        console.log("Removed access for user", userId, "to tag", tagKey);
      } catch (error) {
        this.notificationStore.pushError(
          "Fehler beim Speichern der Änderungen.",
          error
        );
      } finally {
        this.getPermissions();
      }
    },
  },
  created() {
    this.getPermissions();
  },
};
</script>
