<template>
  <SelectUserMenu
    v-model:show="show"
    title="Neuen Besitzer hinzufügen"
    v-model="newOwner.id"
  >
    <template #activator="props">
      <v-chip v-if="!legalOwner" v-bind="props" size="small"
        ><template #prepend></template>Kein Eigentümer angelegt.</v-chip
      >
      <v-chip v-else v-bind="props" size="small"
        ><template #prepend></template><UserAvatar :user="legalOwner" /><span
          v-text="legalOwner.name"
        ></span
      ></v-chip>
    </template>
    <template #content> </template>
    <template #actions>
      <div
        style="width: 100%"
        class="pl-5 d-flex justify-space-between align-center"
      >
        <v-checkbox
          hide-details
          v-model="notifyUser"
          label="User benachrichtigen"
        />
        <v-btn
          :disabled="!newOwner"
          @click="changeLegalOwner"
          class="float-right"
          icon
          ><v-icon>mdi-content-save</v-icon></v-btn
        >
      </div>
    </template>
  </SelectUserMenu>
</template>

<script>
import SelectUserMenu from "@/components/form/SelectUserDialog";
import { changePropertyOwner } from "@/services/property.service";
import UserAvatar from "@/components/user/Avatar.vue";
import {useNotificationStore} from "@/stores/modules/notification.module";
export default {
  setup() {
    const notificationStore = useNotificationStore();
    return { notificationStore };
  },
  name: "AddLegalOwnerToProperty",
  components: { UserAvatar, SelectUserMenu },
  data() {
    return {
      notifyUser: false,
      newOwner: {
        id: null,
      },
      show: false,
    };
  },
  created() {
    console.log("created", this.legalOwner);
    if (this.legalOwner) this.newOwner = this.legalOwner;
  },
  props: {
    legalOwner: Object,
    propertyId: Number,
  },
  methods: {
    async changeLegalOwner() {
      let newOwner = await changePropertyOwner(
        this.propertyId,
        this.newOwner?.id
      );
      this.$emit("changedOwner", newOwner);
      this.notificationStore.pushText("Eigentümer geändert","SUCCESS");
      this.show=false;
    },
  },
};
</script>

<style scoped></style>
