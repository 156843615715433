<template>
  <v-img
      class="mx-auto"
      width="720"
      height="720"
      :lazy-src="smallImage.image ? 'data:image/webp;base64,'+smallImage.image : ''"

      max-width="100%"
      :src="image?.image ? 'data:image/jpeg;base64,'+image?.image : ''"
  >
    <template v-slot:placeholder>
      <div class="d-flex align-center justify-center fill-height">
        <v-progress-circular
            color="grey-lighten-4"
            indeterminate
        ></v-progress-circular>
      </div>
    </template>
  </v-img>
</template>
<script>
import {getGalleryItem} from "@/services/gallery.service";

export default {
  name: 'DetailImage',
  props: {
    smallImage: Object,
    imageId: String,
    show: Boolean
  },
  data() {
    return {
      image: null
    }
  },
  watch: {
    show: {
      immediate: true,
      handler() {
        if(this.show && this.image==null) this.loadImage();
      }
    }
  },
  methods: {
    async loadImage() {
      if (this.show) {
        const image = await getGalleryItem(this.imageId);
        this.image=image;
      }
    }
  }
}
</script>