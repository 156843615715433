<template>
  <v-app>
    <TopBar v-if="!anonymous" />
    <v-main>
      <div
        class="pt-0 rounded"
        style="overflow: hidden; width: 100%; height: 100%"
      >
        <router-view />
      </div>
    </v-main>
    <LeftDrawer v-if="!anonymous" />
    <NotificationWrapper />
  </v-app>
</template>

<script>
import LeftDrawer from "@/components/navigation/LeftDrawer";
import TopBar from "@/components/navigation/TopBar";
import NotificationWrapper from "@/components/notification/NotificationWrapper.vue";
export default {
  name: "App",
  data() {
    return {
      open: true,
    };
  },

  components: {
    NotificationWrapper,
    TopBar,
    LeftDrawer,
  },
  computed: {
    anonymous() {
      return this.$route.meta.anonymous;
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

/* .v-btn::before {
  background-color: transparent;
} */
.v-btn:hover {
  background-color: rgba(147, 177, 149, 0.15);
}
.v-btn i:hover {
  transform: scale(1.15);
}
</style>
