<script>
import moment from "moment/moment";
export default {
  name: "DateDisplay",
  props: {
    date: String,
    format: {
      type: String,
      default: "DD.MM.YYYY",
    },
  },
  computed: {
    betterDate() {
      return moment(this.date).format(this.format);
    },
  },
};
</script>

<template>
  <span v-text="betterDate"></span>
</template>

<style scoped></style>
