<script>
export default {
  name: "SimpleHeading",
  props:{
    title:String,
    subtitle:String
  }
}
</script>

<template>
  <div class="pa-2">
  <h2 v-text="title"></h2>
  <p v-text="subtitle"></p>
  <v-divider
      class="my-5 border-opacity-100"
      color="accent"
      thickness="1"
  ></v-divider>
  </div>
</template>

<style scoped>

</style>
