<template>
  <v-dialog max-width="700" v-model="show">
    <template #activator="{ props }">
      <slot name="activator" v-bind="props">
        <v-btn icon variant="plain" v-bind="props"
          ><v-icon>mdi-book-open-outline</v-icon></v-btn
        >
      </slot>
    </template>
    <v-card>
      <v-card-title>Logbuch</v-card-title>
      <v-card-text>
        <LogbookItem :property-id="propertyId" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import LogbookItem from "@/components/logbook/LogbookItem";
export default {
  name: "LogbookButton",
  components: { LogbookItem },
  data() {
    return {
      show: false,
    };
  },
  props: {
    propertyId: Number,
  },
};
</script>

<style scoped></style>
