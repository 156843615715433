<script>
import {addComment, getCommentsByPage} from "@/services/qAndA/qAndA.service";
import QAndACommentItem from "@/components/property/qAndA/QAndACommentItem.vue";
import moment from "moment";
export default {
  name: "QAndACommentsList",
  components: {QAndACommentItem},
  data(){
    return{
      comments:[],
      page:null,
      loaded:false,
      text:null
    }
  },
  props:{
    qAndAId:{
      type:Number,
      required:true
    },
    load:Boolean
  },
  watch:{
    load:{
      handler() {
        console.log("load", this.load);
        if (this.load && !this.loaded) this.getComments(0);
      },
      immediate: true
      }
  },
  computed:{
    groupedItems() {
      return this.comments.reduce((groups, item) => {
        const date = item.timestamp.split('T')[0]; // get the date part of the timestamp
        if (!groups[date]) {
          groups[date] = [];
        }
        groups[date].push(item);
        return groups;
      }, {});
    }
  },
  methods:{
    addComment(){
      addComment(this.qAndAId, this.text).then((response) => {
        this.comments.unshift(response.data);
        this.text=null;
      });
    },
    getComments(page){
      return new Promise((resolve,reject) => {
        getCommentsByPage(this.qAndAId, page).then((response) => {
          this.page=response.data;
          this.comments = this.comments.concat(response.data.content);
          this.loaded=true;
          resolve();
        }).catch(error => {
          reject(error);
        });
      });
    },
    async loadMore() {
      const distanceFromBottom = document.documentElement.scrollHeight - window.scrollY;
      await this.getComments(this.page.number + 1);
      this.$nextTick(() => {
        window.scrollTo(0, document.documentElement.scrollHeight - distanceFromBottom);
      });
    },
    dateDisplay(date){
      if(moment(date).isAfter(moment().subtract(1, 'week'))){
        return moment(date).format('dddd');
      }
      return moment(date).format('DD.MM.YYYY');
    },
  },
}
</script>

<template>
  <v-btn variant="plain" class="py-5 mx-auto" block v-if="!page?.last" color="white" @click="loadMore">Load More</v-btn>
  <div class="flex-grow-1 chat-container">
    <div v-for="(comments, date) in groupedItems" :key="date">
      <div style="height:50px" class="d-flex justify-center align-center text-disabled">{{ dateDisplay(date) }}</div> <!-- This is the heading for each day -->
      <div class="d-flex flex-column-reverse">
        <QAndACommentItem v-for="comment of comments" :key="comment.id" :comment="comment"/>
      </div>
    </div>
  </div>
  <v-text-field
      v-model="text"
      label="Schreiben..."
      @keyup.enter="addComment"
      append-inner-icon="mdi-send"
      @click:append-inner="addComment"
  />
</template>
<style>

.chat-container {
  display: flex;
  flex-direction: column-reverse;
  padding: 10px;
  box-sizing: border-box;
  overflow-y: scroll;
  height: 600px;
  flex-grow: 1; /* Make the chat container take up the remaining space */
}
</style>