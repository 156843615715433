import ApiService from "@/services/api.service";
import {generateLngLatUrl} from "@/services/distance.service";

export async function loadProperties() {
  let response = await ApiService.get("property/map/get");
  return response.data;
}

export async function test() {
  let response = await ApiService.get("property/1/get");
  console.log(response);
}

function arrayToString(array) {
  function stringify(item) {
    if (Array.isArray(item)) {
      return "[" + item.map(stringify).join(", ") + "]";
    } else {
      return item.toString();
    }
  }

  return stringify(array);
}

export async function getSingleProperty(id) {
  let response = await ApiService.get("property/" + id + "/get");
  return response.data;
}

export async function createBuilding(property) {
  console.log("Coordinates", property.location.coordinates);

  let coordinatesAsString = arrayToString(property.location.coordinates);
  property.location.coordinates = coordinatesAsString;
  console.log(property.location.coordinates);
  console.log("Property", property);
  let response = await ApiService.post("property/building/create", property);
  console.log("Response", response);

  return response.data;
}

export async function createUnitFromBuilding(request) {
  let response = await ApiService.post("property/unit/create", request);
  return response.data;
}

export async function getMyPropertiesByApplicationState(state,relations,page,lngLat){
  let data={
    relationTypes:relations
  }
  let response = await ApiService.post("property/my/applicationState/"+state+"/page/"+page+generateLngLatUrl(lngLat),data);
    return response.data;

}

export async function getAllPropertiesByApplicationState(state,page,lngLat){
    let response = await ApiService.get("property/applicationState/"+state+"/page/"+page+generateLngLatUrl(lngLat));
    return response.data;

}

export async function getAvailablePropertiesForAgent(page,lngLat){
  let response = await ApiService.get("property/agent/available/page/"+page+generateLngLatUrl(lngLat));
  return response.data;
}

export async function getPointOfProperty(propertyId){
  let response = await ApiService.get("property/"+propertyId+"/point/get");
  return response.data;
}


export async function getMyProperetiesByRelation(relation,page,lngLat){
  let response = await ApiService.get("property/my/"+relation+"/page/"+page+generateLngLatUrl(lngLat));
  return response.data;
}

export async function createLandFromBuilding(request) {
  let response = await ApiService.post("property/land/create", request);
  return response.data;
}

export async function updateLand(request) {
  let response = await ApiService.post("land/update", request);
  return response.data;
}

export async function getProperty(id) {
  const response = await ApiService.get("property/" + id + "/get");
  return response.data;
}

export async function getDataAndFacts(id){
  const response = await ApiService.get("property/"+id+"/get/dataAndFacts");
  return response.data;
}

export async function getProperties() {
  const response = await ApiService.get("property/all");
  return response.data;
}

export async function getPropertyStates() {
  const response = await ApiService.get("property/state/all");
  return response.data;
}

export async function changePropertyOwner(propertyId, userId) {
  const response = await ApiService.get(
    "property/" + propertyId + "/owner/set/" + userId
  );
  return response.data;
}

export async function fetchTags() {
  try {
    const response = await ApiService.get("file/tags/get/all");
    const tags = response.data.map((tag) => ({
      key: tag.key,
      name: tag.name,
      page: tag.page
    }));
    return tags;
  } catch (error) {
    console.error(error);
  }
}

export async function fetchFiles(id) {
  try {
    const response = await ApiService.get(
      `property/files/gif/get?propertyId=${id}`
    );
    return response.data;
    // console.log("Files:", this.files);
  } catch (error) {
    console.error(error);
  }
}

export async function fetchFilesByTags(id,tags){
    try {
        const response = await ApiService.post(
        `property/${id}/files/gif/tags`,{tags:tags}
        );
        return response.data;
        // console.log("Files:", this.files);
    } catch (error) {
        console.error(error);
    }

}

export async function changeStatus(propertyId, status) {
  try {
    const response = await ApiService.get(
        `property/${propertyId}/processstatus/${status}/change`
    );
    return response.data;
  }catch (error) {
    console.log(error);
  }
}

export async function getStatusOfProperty(propertyId) {
  const response = await ApiService.get(`property/${propertyId}/status/get`);
  return response.data;
}

export async function completeSaleOfProperty(propertyId,ownerId){
    const response = await ApiService.get(`property/${propertyId}/owner/${ownerId}/sale/complete`);
    return response.data;
}

export async function getPropertySmallInfo(propertyId){
    const response = await ApiService.get(`property/${propertyId}/info/get`);
    return response.data;
}
