export default class InterestedUserResponse {
    constructor(id, name, email, avatar, interest,hasOffer,note,buyIntention, hasLoggedIn) {
        this.id=id;
        this.name=name;
        this.email=email;
        this.avatar= avatar;
        this.interest=interest;
        this.hasLoggedIn = hasLoggedIn;
        this.hasOffer=hasOffer;
        this.buyIntention=buyIntention;
        this.note=note;
    }
}