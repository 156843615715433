<script>
import {getValuation} from "@/services/valuation.service";
import ReadableNumber from "@/components/helpers/ReadableNumber.vue";

export default {
name: "ValuationItem",
  components: {ReadableNumber},
  data(){
  return{
    valuation:null
  }
  },
  props:{
  propertyId:String
  },
  methods:{
  async getValuation(){
    const response = await getValuation(this.propertyId);
    this.valuation = response;
  }
  },
  created() {
    this.getValuation();
  }
}
</script>

<template>
  <div class="d-flex justify-center flex-wrap" style="gap:15px" v-if="valuation">
    <div class="pa-5 elevation-1 bg-accent rounded">
      <p>Sachwert</p>
      <v-dialog max-width="500px">
        <template #activator="{props}">
      <ReadableNumber v-bind="props" :number="valuation.costApproachValue"></ReadableNumber>
        </template>
          <v-card>
            <v-card-text>
              <div class="d-flex flex-wrap">
                <div style="width:100%">
                  <span>Gebäudewert</span> = <span class="">Brutto Marktwert <ReadableNumber :number="valuation.grossFairValue"/></span> - <span>Instanthaltungskosten <ReadableNumber :number="valuation.maintenanceExpenses"/></span>
                </div>
                <v-divider color="accent" class="my-5"/>
              <div style="width:100%">
                <span>Sachwert</span> = <span>Grundstückswert <ReadableNumber :number="valuation.landValue"/></span> + <span>Gebäudewert <ReadableNumber :number="valuation.grossFairValue-valuation.maintenanceExpenses"/></span>
              </div>
              </div>
            </v-card-text>
          </v-card>
      </v-dialog>
    </div>
    <div class="pa-5 elevation-1 bg-accent rounded">
      <p>Vergleichswert</p>
      <ReadableNumber :number="valuation.comparativeApproachValue"/>
    </div>
    <div class="pa-5 elevation-1 bg-accent rounded">
      <p>Ertragswert</p>
      <ReadableNumber :number="valuation.incomeApproachValue"/>
    </div>
  </div>
</template>

<style scoped>

</style>