import mapboxgl from "mapbox-gl";
import HomeControl from "@/mapControls/HomeControl";
import {loadProperties} from "@/services/property.service";
import {useAuthUserStore} from "@/stores/modules/auth.module";
import {useMapStore} from "@/stores/modules/map.module";
const accessToken = 'pk.eyJ1IjoiZGF2aWQtemltbWVydCIsImEiOiJjbHVqcDBicGkwNGVsMmxvMmx4NmV3cTU1In0.sxKk5xlJaOPpsgjIt1oYww';
export function highlightBuildings(map,coordinatesList){
        coordinatesList.forEach(coordinates => {
            const features = map.queryRenderedFeatures(map.project(coordinates), {
                layers: ['add-3d-buildings'],
            });
            // Apply styling to highlighted buildings
            features.forEach(feature => {
                if(map) {
                    map.setFeatureState(
                        {sourceLayer: feature.sourceLayer, source: feature.source, id: feature.id},
                        {hover: true}
                    );
                }
            });
        });
}
export async function loadData(){
    let data = await loadProperties();
    return data;
}
export function addPropertyLayer(map,data) {

    if (!map.getSource('properties')) {
        const ids = data.features
            .map(feature => {
                if (feature.properties.id !== null && feature.properties.id !== undefined) {
                    return feature.properties.id;
                }
            })
            .filter(id => id !== undefined);
        for (let id of ids) {
            if (map) {
                map.setFeatureState(
                    {sourceLayer: "building", source: "composite", id: id},
                    {registered: true}
                );
            }

        }

        map.addSource('properties', {
            type: 'geojson',
            // Use a URL for the value for the data property.
            data: data
        });
    }
        if(!map.getLayer("add-3d-properties")) {
            map.addLayer(
                {
                    'id': 'add-3d-properties',
                    'source': 'properties',
                    //'filter': ['==', 'extrude', 'true'],
                    'type': 'fill-extrusion',
                    'minzoom': 12,
                    'paint': {
                        'fill-extrusion-color': [
                            'case',
                            ['boolean', ['feature-state', 'selected'], false], // If feature is selected
                            '#93B195',
                            '#93B195' // Default color
                        ],
                        // Use an 'interpolate' expression to
                        // add a smooth transition effect to
                        // the buildings as the user zooms in.
                        'fill-extrusion-height': [
                            'interpolate',
                            ['linear'],
                            ['zoom'],
                            15,
                            0,
                            15.05,
                            ['get', 'height']
                        ],
                        'fill-extrusion-base': [
                            'interpolate',
                            ['linear'],
                            ['zoom'],
                            15,
                            0,
                            15.05,
                            ['get', 'min_height']
                        ],
                        'fill-extrusion-opacity': 1
                    }
                }
            );
            map.moveLayer("add-3d-properties","add-3d-buildings");
        }

}
export async function addPropertyLayerSpecificProps(map,geoJson) {
        let data = geoJson;
        const ids = data.features
            .map(feature => {
                if (feature.properties.id !== null && feature.properties.id !== undefined) {
                    return feature.properties.id;
                }
            })
            .filter(id => id !== undefined);
        for(let id of ids){
            if(map) {
                map.setFeatureState(
                    {sourceLayer: "building", source: "composite", id: id},
                    {registered: true}
                );
            }

        }

        map.addSource('properties', {
            type: 'geojson',
            // Use a URL for the value for the data property.
            data: data
        });
        map.addLayer(
            {
                'id': 'add-3d-properties',
                'source': 'properties',
                //'filter': ['==', 'extrude', 'true'],
                'type': 'fill-extrusion',
                'minzoom': 12,
                'paint': {
                    'fill-extrusion-color': [
                        'case',
                        ['boolean', ['feature-state', 'selected'], false], // If feature is selected
                        '#93B195',
                        '#93B195' // Default color
                    ],
                    // Use an 'interpolate' expression to
                    // add a smooth transition effect to
                    // the buildings as the user zooms in.
                    'fill-extrusion-height': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        15,
                        0,
                        15.05,
                        ['get', 'height']
                    ],
                    'fill-extrusion-base': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        15,
                        0,
                        15.05,
                        ['get', 'min_height']
                    ],
                    'fill-extrusion-opacity': 1
                }
            }
        );
        map.moveLayer("add-3d-properties","add-3d-buildings");
}

export function easeTo(map,lng,lat,zoom,duration){
    map.easeTo({center: [lng, lat], zoom: zoom, duration: duration});
}
export function flyToCoordinatesString(map, coordinatesString) {
    const coordinates = JSON.parse(coordinatesString);

    let pointToFlyTo = extractFirstCoordinates(coordinates);

    if (pointToFlyTo) {
        flyTo(map, pointToFlyTo[1], pointToFlyTo[0]);
    } else {
        console.error('No coordinates found');
    }
}

function extractFirstCoordinates(obj) {
    if (Array.isArray(obj) && obj.length === 2 && typeof obj[0] === 'number' && typeof obj[1] === 'number') {
        return obj;
    } else if (Array.isArray(obj) && obj.length > 0) {
        for (let i = 0; i < obj.length; i++) {
            let result = extractFirstCoordinates(obj[i]);
            if (result) {
                return result;
            }
        }
    }
    return null;
}
export function flyTo(map,lng,lat){
    map.flyTo({
        center: [lat, lng],
        zoom: 17
    });
}
export function addControls(map){
    /**
    const compass = new mapboxgl.NavigationControl({
        visualizePitch:true
    });
    map.addControl(compass);
**/
    //Home Control
    const authStore = useAuthUserStore();
    const userHome = authStore.user?.home;

    if(userHome) {
        map.addControl(new HomeControl());
        document.getElementById("mapbox-ctrl-custom-home").addEventListener("click", ()=>{
            const coordinatesString = userHome.mapMarker.coordinates;
            const coordinates = JSON.parse(coordinatesString);
            flyTo(map,coordinates[1],coordinates[0]);
        });

    }

    }
export function setHomeMarker(map){
    const authStore = useAuthUserStore();
    const userHome = authStore.user?.home;
    if(!userHome)return;
        // Create a new marker.
        let coordinates = JSON.parse(userHome.mapMarker.coordinates);
        const el = document.createElement('div');
        el.className = 'homeMarker';
        el.style.width="25px";
        el.style.height="25px";
        new mapboxgl.Marker(el)
            .setLngLat(coordinates)
            .addTo(map);
}
export function setUserLocation(fly){
    const mapStore = useMapStore();
    mapStore.addGeoLocationControl();
    mapStore.geolocate.on('geolocate', function(e) {
        mapStore.userPosition=[e.coords.longitude, e.coords.latitude]
        if(fly) {
            mapStore.map.flyTo({
                center: [e.coords.longitude, e.coords.latitude],
                zoom: 18, //set zoom
                pitch: 40
            });
        }else{
            mapStore.map.jumpTo({
                center: [e.coords.longitude, e.coords.latitude],
                zoom: 18, //set zoom
                pitch: 40
            });
        }

    });
}

export function findPolygonCenter(vertices) {
    const numVertices = vertices.length;
    if (numVertices < 3) {
        return vertices[0]
    }

    const sumX = vertices.reduce((acc, vertex) => acc + vertex[0], 0);
    const sumY = vertices.reduce((acc, vertex) => acc + vertex[1], 0);

    const centerX = sumX / numVertices;
    const centerY = sumY / numVertices;

    return [centerX, centerY];
}


export function getAddressFromLatLng(lngLat){
    // Perform reverse geocoding request
    return fetch('https://api.mapbox.com/geocoding/v5/mapbox.places/' + lngLat.lng + ',' + lngLat.lat + '.json?access_token=' + mapboxgl.accessToken)
        .then(response => response.json())
        .then(data => {
            // Extract the address information from the response
            var address = data.features[0].place_name;

            // Display the address to the user
            return Promise.resolve(address);
        })
        .catch(error => console.error('Error:', error));
}

export function removeFeatures(list1, list2) {
    // Create a map of feature IDs from list2 for faster lookup
    const idMap = {};
    list2.forEach(feature => {
        idMap[feature.id] = true;
    });

    // Filter out features from list1 that are also in list2
    const filteredList = list1.filter(feature => !idMap[feature.id]);

    return filteredList;
}

export async function suggestSearch(searchQuery){
    searchQuery = searchQuery.replaceAll(" ","+");
    const url = `https://api.mapbox.com/search/searchbox/v1/suggest?q=${searchQuery}&language=de&&access_token=${accessToken}&session_token=1`;
    console.log("url",url)
    return await fetchFromApi(url);
}

export async function retreiveItem(mapboxId){
    const url= `https://api.mapbox.com/search/searchbox/v1/retrieve/${mapboxId}?access_token=${accessToken}&session_token=1`;
    return await fetchFromApi(url);
}

async function fetchFromApi(url) {
    try {
        const response = await fetch(url);
        if (!response.ok) {
            console.error('Error fetching Mapbox data:', response.statusText);
            return;
        }
        const data = await response.json();
        console.log("Data",data)
        return data;
    } catch (error) {
        console.error('Error fetching Mapbox data:', error);
        return;
    }
}

export async function getUserLocationWithoutMapbox() {
    return new Promise((resolve, reject) => {
        if ("geolocation" in navigator) {
            console.log("Geolocation is available");
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const lat = position.coords.latitude;
                    const lng = position.coords.longitude;
                    resolve([lng, lat]);
                },
                (error) => {
                    console.error("Error getting user location:", error);
                    reject(error);
                }
            );
        } else {
            console.error("Geolocation is not supported by this browser.");
            reject(new Error("Geolocation is not supported by this browser."));
        }
    });
}
