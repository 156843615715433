import ApiService from "@/services/api.service";
import CreateInterestRequest from "@/models/saleProcess/createInterestRequest";

export async function addInterestToProperty(propertyId,userId,buyIntention,note,createUserRequest,addedByAgent,sendMail,customText){
    console.log("Send Mail",sendMail);
    const data = new CreateInterestRequest({note:note,buyIntention:buyIntention,userId:userId,userEmail:createUserRequest.email,createUserRequest:createUserRequest,addedByAgent:addedByAgent,sendMail:sendMail,customText:customText});
    console.log("Data",data);
    const response = await ApiService.post("interest/property/"+propertyId+"/add",data);
    return response.data;
}

export async function getInterestsOfProperty(propertyId){
    const response = await ApiService.get("interest/property/"+propertyId+"/get");
    return response.data;
}