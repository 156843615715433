import ApiService from "@/services/api.service";
import { useNotificationStore } from "@/stores/modules/notification.module";
import NotificationElephant from "@/models/notification";

export async function createAppointment(appointmentRequest) {
  const response = await ApiService.post(
    "appointment/create",
    appointmentRequest
  );
  return response.data;
}

export async function getMyAppointments() {
  const response = await ApiService.get("appointment/my/get");
  return response.data;
}

export async function getAppointmentById(id) {
  const response = await ApiService.get(`appointment/${id}/get`);
  return response.data;
}

export async function getClosestAppointment() {
  const response = await ApiService.get("appointment/my/closest");
  return response.data;
}

export async function getAppointmentByProperty(propertyId) {
  const response = await ApiService.get(
    `appointment/property/${propertyId}/get`
  );
  return response.data;
}

export async function getAppointmentByUserAndProperty(userId, propertyId) {
  const response = await ApiService.get(
    `appointment/property/${propertyId}/participant/${userId}/get`
  );
  return response.data;
}
export async function getAppointmentByUserAndPropertyByType(
  userId,
  propertyId,
  appointmentType
) {
  const response = await ApiService.post(
    `appointment/property/${propertyId}/participant/${userId}/type/${appointmentType}/get`
  );
  return response.data;
}

//TODO add suggest
export async function sendAppointmentAction(id, action, suggest) {
  const response = await ApiService.post(
    `appointment/${action}/${id}`,
    suggest
  );
  if (response.status === 200) {
    const notificationStore = useNotificationStore();
    let title = "";
    let message = "";

    switch (action) {
      case "accept":
        message = "Termin erfolgreich bestätigt";
        break;
      case "decline":
        message = "Termin erfolgreich abgelehnt";
        break;
      case "suggest":
        message = "Neue Zeit erfolgreich vorgeschlagen";
        break;
    }

    notificationStore.push(
      new NotificationElephant({
        title: title,
        message: message,
        color: "SUCCESS",
      })
    );
  }
  return response;
}
