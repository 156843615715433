export default class MapMeta{
    height;
    minHeight;
    id;

    constructor({height,minHeight,buildingId}) {
        this.height=height;
        this.minHeight=minHeight;
        this.id=buildingId;
    }
}