<template>
  <v-autocomplete
    :density="dense ? 'compact' : 'default'"
    :variant="dense ? 'plain' : 'outlined'"
    :hide-details="dense"
    :label="label"
    :prepend-icon="prependIcon"
    :disabled="disabled"
    :loading="loading"
    :rules="rules"
    v-model="modelValueC"
    :items="items"
    item-title="label"
    item-value="value"
    :multiple="multiple"
    :return-object="returnObject"
    @click="console.log('data', data, 'items', items)"
  ></v-autocomplete>
</template>

<script>
export default {
  name: "SelectEnum",
  props: {
    dense: Boolean,
    prependIcon: String,
    data: Object,
    label: String,
    modelValue: String,
    disabled: Boolean,
    loading: Boolean,
    rules: Array,
    multiple: Boolean,
    returnObject: Boolean,
  },
  emits: ["update:modelValue"],
  computed: {
    items() {
      return this.getEnumList("de", this.data);
    },
    modelValueC: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  methods: {
    getEnumList(language, enumObject) {
      const usageList = [];
      for (const key in enumObject) {
        if (Object.prototype.hasOwnProperty.call(enumObject, key)) {
          const value = enumObject[key];
          const item = {
            label: value[language],
            value: key,
          };
          usageList.push(item);
        }
      }
      return usageList;
    },
  },
};
</script>

<style scoped></style>
