<script>
import { useAuthUserStore } from "@/stores/modules/auth.module";
import { usePropertyStore } from "@/stores/modules/property.module";

export default {
  name: "SavePropertyButton",
  props: {
    icon: Boolean,
    propertyId: Number,
    userPropertyRelations: Array,
    modelValue: Boolean,
  },
  emits: ["update:modelValue"],
  computed: {
    currentUser() {
      return this.userStore.user;
    },
    saved: {
      get() {
        return this.modelValue;
      },
      set(value) {
        console.log("Value", value);
        this.$emit("update:modelValue", value);
      },
    },
  },
  setup() {
    const userStore = useAuthUserStore();
    const propertyStore = usePropertyStore();
    return {
      userStore,
      propertyStore,
    };
  },
  created() {
    console.log(
      "saved?",
      this.userPropertyRelations?.findIndex(
        (relation) =>
          relation.userId === this.currentUser.id &&
          relation.relationType === "SAVED"
      ) != -1
    );
    this.saved =
      this.userPropertyRelations?.findIndex(
        (relation) =>
          relation.userId === this.currentUser.id &&
          relation.relationType === "SAVED"
      ) != -1;
  },
  methods: {
    async addToSaved() {
      let response = await this.propertyStore.addPropertyRelation(
        this.propertyId,
        "SAVED"
      );
      if (response) {
        this.saved = true;
      }
    },
    removeFromSaved() {
      this.propertyStore.removePropertyRelation(this.propertyId, "SAVED");
      this.saved = false;
    },
  },
};
</script>

<template>
  <v-btn
    v-tooltip:top="{
      text: 'aus Beobachtung entfernen',
      openDelay: 1000,
    }"
    color="accent"
    size="small"
    @click.stop="removeFromSaved"
    v-if="saved"
    tile
    rounded
    :icon="icon"
  >
    <v-icon size="small">mdi-heart</v-icon>
  </v-btn>
  <v-btn
    v-tooltip:top="{
      text: 'Beobachten',
      openDelay: 1000,
    }"
    color="accent"
    size="small"
    @click.stop="addToSaved"
    v-else
    tile
    rounded
    :icon="icon"
  >
    <v-icon size="small">mdi-heart-outline</v-icon>
  </v-btn>
</template>

<style scoped></style>
