<script>
import {getMyOfferOfProperty} from "@/services/saleProcess/offer.service";
import CreateOfferDialog from "@/components/property/offer/CreateOfferDialog.vue";
import DateDisplay from "@/components/helpers/DateDisplay.vue";
import EOfferStatus from "@/components/enums/EOfferStatus";
import EnumDisplay from "@/components/helpers/EnumDisplay.vue";
import CurrencyDisplay from "@/components/helpers/CurrencyDisplay.vue";
import OfferActionButtons from "@/components/property/offer/OfferActionButtons.vue";
import SaleProcessOfProperty from "@/components/property/SaleProcessOfProperty.vue";
import SimpleHeading from "@/components/SimpleHeading.vue";

export default {
  name: "OfferDetails",
  components: {
    SimpleHeading,
    SaleProcessOfProperty, OfferActionButtons, CurrencyDisplay, EnumDisplay, DateDisplay,CreateOfferDialog},
  props:{
    propertyId:String
  },
  data(){
    return{
      offerStates: EOfferStatus,
      offer: {
        purchasePrice: null,
        validUntil:null
      }
    }
  },
  created() {
    this.getOffer();
  },
  methods:{
    async getOffer() {
      try{
        this.offer = await getMyOfferOfProperty(this.propertyId);
      }catch (e) {
        this.offer = null;
      }
    }
  }
}
</script>

<template>
  <div>
    <SaleProcessOfProperty :property-id="propertyId"/>
  </div>
  <div v-if="offer">
    <SimpleHeading title="Angebot" subtitle="Einige Informationen zu Ihrem Angebot"/>
<v-table>
  <tbody>
  <tr :class="offer.status==='COUNTER_OFFER' ? 'text-decoration-line-through' : ''" >
    <td class="">
      Angebotspreis
    </td>
    <td>
      <CurrencyDisplay :number="offer.purchasePrice"/>
    </td>
  </tr>
  <tr v-if="offer.status==='COUNTER_OFFER' && offer.counterOffer">
    <td>
      Gegenangebot
    </td>
    <td>
      <CurrencyDisplay :number="offer.counterOffer"/>
    </td>
  </tr>
  <tr>
    <td>
      Gültig bis
    </td>
    <td>
      <DateDisplay :date="offer.validUntil"/>
    </td>
  </tr>
  <tr>
    <td>
      Status
    </td>
    <td>
     <EnumDisplay  :enum-object="offerStates" :value="offer.status"/>
    </td>
  </tr>
  </tbody>
</v-table>
  </div>
  <div v-else>
    <CreateOfferDialog @offerCreated="(data) => {this.offer = data}" :property-id="propertyId"/>
  </div>
  <div class="py-5">
    <OfferActionButtons v-if="offer" :property-id="propertyId" v-model:offer="offer"/>
  </div>

</template>
