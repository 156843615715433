import { defineStore } from "pinia";
import {
  getAvailablePropertiesForAgent,
  getMyProperetiesByRelation,
  getMyPropertiesByApplicationState,
} from "@/services/property.service";

const state = () => ({
  availableProperties: {
    loading: false,
    content: [],
    currentPage: null,
  },
  agentProperties: {
    loading: false,
    content: [],
    currentPage: null,
  },
  archivedProperties: {
    loading: false,
    content: [],
    currentPage: null,
  },
});

export const useMaklerPropertyStore = defineStore("maklerPropertyStore", {
  state: state,

  getters: {},

  actions: {
    async fetchArchivedProperties(page, lngLat) {
      this.archivedProperties.loading = true;
      try {
        const relations = ["MAKLER"];
        const response = await getMyPropertiesByApplicationState(
          "ARCHIVED",
          relations,
          page,
          lngLat
        );
        const response2 = await getMyPropertiesByApplicationState(
          "SOLD",
          relations,
          page,
          lngLat
        );
        console.log("Response", response);
        console.log("Response2", response2);

        let properties = response.content;
        properties = properties.concat(response2.content);
        let currentPage = response;
        this.archivedProperties.content = properties;
        this.archivedProperties.currentPage = currentPage;
        this.archivedProperties.loading = false;
      } catch (e) {
        this.archivedProperties.loading = false;
      } finally {
        this.archivedProperties.loading = false;
      }
    },
    async fetchAvailableProperties(page, lngLat) {
      this.availableProperties.loading = true;
      try {
        const response = await getAvailablePropertiesForAgent(page, lngLat);
        let properties = response.content;
        let currentPage = response;
        this.availableProperties.content = properties;
        this.availableProperties.currentPage = currentPage;
        this.availableProperties.loading = false;
        console.log("Available Properties", properties);
      } catch (e) {
        this.availableProperties.loading = false;
      } finally {
        this.availableProperties.loading = false;
      }
    },
    async fetchAgentProperties(page, lngLat) {
      this.agentProperties.loading = true;
      try {
        const response = await getMyProperetiesByRelation(
          "MAKLER",
          page,
          lngLat
        );
        let properties = response.content;
        let currentPage = response;
        this.agentProperties.content = properties;
        this.agentProperties.currentPage = currentPage;
        this.agentProperties.loading = false;
      } catch (e) {
        this.agentProperties.loading = false;
      } finally {
        this.agentProperties.loading = false;
      }
    },
  },
});
