
class HomeControl {
    onAdd(map){
        this.map = map;
        this.container = document.createElement('div');
        this.container.className = 'mapboxgl-ctrl mapboxgl-ctrl-group';
        this.container.innerHTML = '<button id="mapbox-ctrl-custom-home"><i style="color:black" class="mdi-home mdi v-icon notranslate" aria-hidden="true"></i></button>';
        return this.container;
    }
    onRemove(){
        this.container.parentNode.removeChild(this.container);
        this.map = undefined;
    }
}

export default HomeControl;