<script>
import CreateOffer from "@/components/property/offer/CreateOffer.vue";
import {updateOffer} from "@/services/saleProcess/offer.service";

export default {
  name: "EditOfferDialog",
  components: {CreateOffer},
  data(){
    return {
      dialog: false,
    }
  },
  props:{
    offer:Object,
    propertyId:String,
    disabled:Boolean,
  },
  computed:{
    offerC:{
      get(){
        return this.offer;
      },
      set(value){
        this.$emit('update:offer',value);
      }
    }
  },
  methods:{
    async updateOffer() {
      const data = await updateOffer(this.offer, this.propertyId);
      this.offerC=data;
      this.dialog = false;
    }
  }
}
</script>

<template>
<v-dialog v-model="dialog" max-width="500">
  <template v-slot:activator="{ props}">
    <v-btn :disabled="disabled" color="accent" variant="text" v-bind="props">Angebot bearbeiten</v-btn>
  </template>
  <v-card>
    <v-card-text>
      <CreateOffer v-if="offer" v-model:offer="offerC" @submit="updateOffer" submitText="Änderungen speichern"></CreateOffer>
    </v-card-text>
  </v-card>
</v-dialog>
</template>

<style scoped>

</style>
