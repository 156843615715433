const EPropertyRelationType = {
    OWNER: { de: "Eigentümer", en: "Owner"},
    INTERESTED: { de: "Interessiert", en: "Interested"},
    SAVED: { de: "Gespeichert", en: "Saved"},
    OFFERED: { de: "Angeboten", en: "Offered"},
    SOLD: { de: "Verkauft", en: "Sold"},
    MARKLER: { de: "Makler", en: "Markler"},
};

export default EPropertyRelationType;
