<template>
  <span>
    <ReadableNumber :number="number" />
    €
  </span>
</template>

<script>
import ReadableNumber from "@/components/helpers/ReadableNumber.vue";
export default {
  name: "CurrencyDisplay",
  components: { ReadableNumber },
  props: {
    number: Number,
  },
};
</script>

<style scoped></style>
