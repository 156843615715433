<template>
  <div class="mx-auto px-5 mb-3" style="max-width: 400px">
    <div
      v-if="$vuetify.display.smAndDown"
      class="d-flex align-center justify-center"
    >
      <dotlottie-player
        src="https://lottie.host/e3423e9f-332e-408a-acca-d7214fb68192/StzLDKuoAc.json"
        background="transparent"
        speed="1"
        loop
        autoplay
      ></dotlottie-player>
    </div>
    <v-img :src="require('@/assets/rubikon_logo 1.png')" width="110"></v-img>

    <h1 class="my-5">Anmeldung</h1>
    <!-- <h4 class="mb-5 font-weight-regular">Welcome back! Select method to log in.</h4>
    <v-btn height="50px" style="border-width:2px;-webkit-box-shadow: 0px 10px 40px -10px rgba(0,0,0,0.1);
-moz-box-shadow: 0px 10px 20px -10px rgba(0,0,0,0.1);
box-shadow: 0px 10px 20px -10px rgba(0,0,0,0.1);border-color:#EEEEEE;border-radius: 50px" outlined class="mx-auto my-1 align-self-center" :loading="loading" width="100%" :href="'https://bdme.auth.eu-central-1.amazoncognito.com/login?client_id=6fb837a28n4589dkr8r5c9n6vb&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri='+frontendhost+'/login'">
      <span>Google / Facebook</span>
    </v-btn>
    <div class="mt-5 mb-2 d-flex align-center" style="gap:20px">
      <v-divider></v-divider><p style="width: 750px" class="text--disabled">Or continue with email</p><v-divider/>
    </div> -->
    <v-form>
      <v-text-field
        :rules="usernameRules"
        prepend-inner-icon="mdi-email-outline"
        label="Email"
        v-model="loginRequest.email"
      ></v-text-field>
      <v-text-field
        autocomplete="current-password"
        prepend-inner-icon="mdi-key-outline"
        label="Passwort"
        v-model="loginRequest.password"
        :rules="passwordRules"
        type="password"
        required
      ></v-text-field>
      <v-switch
        color="accent"
        label="Angemeldet bleiben"
        v-model="loginRequest.rememberMe"
      />
      <v-btn @click="login" color="accent" block>Einloggen</v-btn>
    </v-form>
    <v-btn
      class="my-3 float-right"
      variant="plain"
      size="small"
      href="/u/reset/password/request"
      >Passwort vergessen?</v-btn
    >
  </div>
</template>

<script>
import { useAuthUserStore } from "@/stores/modules/auth.module";

export default {
  name: "LoginComponent",
  setup() {
    const authUserStore = useAuthUserStore();

    return {
      // you can also access the whole store in your component by returning it
      authUserStore,
    };
  },
  computed: {
    from() {
      return this.$route.query.from;
    },
  },
  data() {
    return {
      loginRequest: {
        email: null,
        password: null,
        rememberMe: false,
      },
      passwordRules: [(v) => !!v || this.$t("Eingabe erforderlich")],
      usernameRules: [(v) => !!v || this.$t("Eingabe erforderlich")],
    };
  },
  methods: {
    async login() {
      try {
        await this.authUserStore.login(this.loginRequest);
      } catch (e) {
        console.log(e);
      }
      if (this.from) location.href = this.from;
      else location.href = "/";
    },
  },
};
</script>

<style scoped></style>
