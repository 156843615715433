<template>
<v-sheet rounded  class="pa-5">
  <v-list-item v-if="propertyState.mandant">
    <template #prepend>
      <UserAvatar :size="70" :user="propertyState.mandant"/>
    </template>
    <template #title>
      <span v-text="propertyState.mandant.name"></span>
    </template>
    <template #subtitle>
      <div>
        <p><span>Kaufintention</span><span><span v-text="propertyState.interest.buyIntention"></span>/10</span></p>
        <p v-text="propertyState.interest.note"></p>
      </div>
    </template>
  </v-list-item>
  <div class="d-flex justify-space-between">
    <div style="max-width: 90%">
      <div class="mt-2 d-flex justify-start">
        <v-chip class="text-disabled" small>{{propertyState.property.label}} - Objekt ID {{propertyState.property.id}}</v-chip>
      </div>
      <div class="pa-3 text-left">
        <h3>Aktueller Status</h3>
        <div  v-if="!editNotes"  style="max-height: 100px; overflow-y:scroll"><small class="text-disabled" v-text="propertyStateC.notes"></small>
        </div>
        <v-textarea :counter="200" :maxlength="200"  v-else v-model="propertyStateC.notes" outlined dense rows="3" placeholder="Notizen">
        </v-textarea>

        <v-btn v-if="editNotes" @click="saveNotes" size="x-small" elevation="0" tile rounded class="ml-1" icon><v-icon color="accent">mdi-content-save</v-icon></v-btn>
        <v-btn @click="editNotes=!editNotes" size="x-small" elevation="0" tile rounded class="ml-1" icon><v-icon color="accent">mdi-pencil</v-icon></v-btn>
      </div>
    </div>
    <div class="d-flex pa-3 flex-column" style="width: 10%">
      <!--TODO: On click show files that the user uploaded -->
      <PropertyFilesPopup :property="propertyStateC.property" />
      <ShowOnMapButton :property="propertyStateC.property"/>
      <AppointmentsOfSaleProcess :property-id="propertyState.property.id" :user-id="propertyState.mandant.id"/>
    </div>
  </div>


  <v-stepper editable flat alt-labels>
    <v-stepper-window>
      <v-stepper-window-item>
        <v-table>
          <tbody>
          <tr>
            <td>
             Addresse
            </td>
            <td>
              <p><span class="mr-2" v-text="propertyState.property.address.zipCode"></span><span v-text="propertyState.property.address.city"></span></p>

              <p v-text="propertyState.property.address.street"></p>
            </td>
          </tr>
          <tr v-if="propertyState.property.plotArea">
            <td>Grundstücksgröße</td>
            <td>
              <span v-text="propertyState.property.plotArea"></span> m²
            </td>
          </tr>
          <tr v-if="propertyState.property.usableArea">
            <td>Größe</td>
            <td>

              <span v-text="propertyState.property.usableArea"></span> m²
            </td>
          </tr>
          <tr v-if="propertyState?.offer?.purchasePrice">
            <td>
              Angebotspreis
            </td>
            <td>
              <CurrencyDisplay :number="propertyState.offer.purchasePrice"/>
            </td>
          </tr>
          <!--<tr v-if="propertyState.property.saleGrossPrice">
            <td>
              angegebener Kaufpreis
            </td>
            <td>
              <CurrencyDisplay :number="propertyState.property.saleGrossPrice"/>
            </td>
          </tr>
          -->
          </tbody>
        </v-table>
      </v-stepper-window-item>
      <v-stepper-window-item>
        <v-table>
          <tbody>
          <tr :class="propertyStateC.offer.status==='COUNTER_OFFER' ? 'text-decoration-line-through' : ''" >
            <td class="">
              Angebotspreis
            </td>
            <td>
              <CurrencyDisplay :number="propertyState.offer.purchasePrice"/>
            </td>
          </tr>
          <tr v-if="propertyStateC.offer.status==='COUNTER_OFFER' && propertyState.offer.counterOffer">
            <td>
              Gegenangebot
            </td>
            <td>
              <CurrencyDisplay :number="propertyState.offer.counterOffer"/>
            </td>
          </tr>
          <tr>
            <td>Gültig bis:</td>
            <td><span>
              <v-icon color="error" v-if="validUntil.state==='EXPIRED'">mdi-close-circle</v-icon>
              <v-icon color="warning" v-if="validUntil.state==='EXPIRES_SOON'">mdi-alert-circle</v-icon>
              <v-icon color="success" v-if="validUntil.state==='VALID'">mdi-check-circle</v-icon>

            </span><span v-text="validUntil.date"></span></td>
          </tr>
          <tr>
            <td>
              Status
            </td>
            <td>
              <EnumDisplay :enum-object="offerStates" :value="propertyState.offer.status"/>
            </td>
          </tr>

          </tbody>
        </v-table>
        <OfferActionButtonsOwner @changeOffer="propertyStateC.offer=$event" @changeStatus="propertyStateC.offer.status=$event" :offerId="propertyStateC.offer.id" :user-id="propertyStateC.offer?.offerFrom" :propertyId="propertyState.property.id"/>
      </v-stepper-window-item>
      <v-stepper-window-item>
<v-table v-if="propertyState.sale">
          <tbody>
          <tr>
            <td>
              Verkaufspreis
            </td>
            <td>
              <CurrencyDisplay :number="propertyState.sale.purchasePrice"/>
            </td>
          </tr>
          <tr>
            <td>
              Status
            </td>
            <td>
              <SelectEnum @change="updateSaleState" :data="offerStates" :value="propertyState.sale.status"/>
              <EnumDisplay :enum-object="offerStates" :value="propertyState.sale.status"/>
            </td>
          </tr>
          </tbody>
        </v-table>
      </v-stepper-window-item>
    </v-stepper-window>
    <v-stepper-header>
      <v-stepper-item
          class="pa-1 custom-stepper"
          subtitle="Interesse"
          color="success"
      >
        <template #icon>
          <v-icon>mdi-check</v-icon>
        </template>
      </v-stepper-item>
      <v-stepper-item
          class="pa-1 custom-stepper"
          subtitle="Angebot"
          :color="offerColor.color"
      >
        <template #icon>
          <v-icon>{{offerColor.icon}}</v-icon>
        </template>
      </v-stepper-item>


      <!--<v-stepper-item

          class="pa-1 custom-stepper"
          subtitle="Verkauf"
          :color="saleColor.color"
      >
        <template #icon>
          <v-icon>{{saleColor.icon}}</v-icon>
        </template>
      </v-stepper-item>-->
    </v-stepper-header>
  </v-stepper>
</v-sheet>
</template>

<script>
import UserAvatar from "@/components/user/Avatar.vue";
import CurrencyDisplay from "@/components/helpers/CurrencyDisplay.vue";
import EnumDisplay from "@/components/helpers/EnumDisplay.vue";
import EOfferStatus from "@/components/enums/EOfferStatus";
import {changesNotes} from "@/services/saleProcess/process.service";
import {useMapStore} from "@/stores/modules/map.module";
import {useAuthUserStore} from "@/stores/modules/auth.module";
import AppointmentsOfSaleProcess from "@/components/agent/property/AppointmentsOfSaleProcess.vue";
import moment from "moment/moment";
import SelectEnum from "@/components/form/SelectEnum.vue";
import {changeSaleStatus} from "@/services/saleProcess/sale.service";
import ShowOnMapButton from "@/components/property/ShowOnMapButton.vue";
import PropertyFilesPopup from "@/components/property/PropertyFilesPopup.vue";
import OfferActionButtonsOwner from "@/components/property/offer/OfferActionButtonsOwner.vue";

export default {
  name: "PropertyItemState",

  components: {
    OfferActionButtonsOwner,
    PropertyFilesPopup,
    ShowOnMapButton,
    SelectEnum,
    AppointmentsOfSaleProcess, EnumDisplay, CurrencyDisplay, UserAvatar},
  setup(){
    const mapStore = useMapStore();
    const userStore = useAuthUserStore();
    return {mapStore,userStore}
  },
  props:{
    propertyState:Object
  },
  emits: [
    'update:propertyState'
  ],
  methods:{
    async updateSaleState() {
      let newStatus = await changeSaleStatus(this.propertyState.property.id, this.propertyState.sale.sellerId, this.propertyState.sale.status)
      this.propertyStateC.sale.status = newStatus;
    },
    showOnMap(){
      this.mapStore.flyToBuilding(this.propertyState.property);
    },
    async saveNotes() {
      this.editNotes = false;
      this.propertyStateC.notes = await changesNotes(this.propertyStateC.id, this.propertyStateC.notes);
    }
  },
  computed:{
    currentUser(){
      return this.userStore.user;
    },
    propertyStateC:{
      get(){
        return this.propertyState
      },
      set(value){
        this.$emit('update:propertyState',value)
      }
    },
    validUntil(){
      moment.locale('de');
      let date = moment(this.propertyState.offer.validUntil);
      let state = "VALID";
      if(date.isBefore(moment())){
        state = "EXPIRED";
      }else if(date.isBefore(moment().add(7,'days'))){
        state = "EXPIRES_SOON";
      }
      else{
        state= "VALID";
      }
      return {
        date: date.format("LL"),
        state: state
    }},
    offerColor(){
      let offer = this.propertyState.offer;
      if(offer){
        switch(offer.status) {
          case "PENDING":
            if(this.validUntil.state==="EXPIRED")
              return {
                color: "error",
                textColor: "white",
                icon: "mdi-close"
              };
            else if(this.validUntil.state==="EXPIRES_SOON")
              return {
                color: "warning",
                textColor: "white",
                icon: "mdi-alert"
              }
            else{
            return {
              color: "accent",
              textColor: "white",
              icon: "mdi-progress-question"
            }}
          case "ACCEPTED":
            return {
              color: "success",
              textColor: "white",
              icon: "mdi-check"
            }
          case "DECLINED":
            return {
              color: "error",
              textColor: "white",
              icon: "mdi-close"
            }
          case "WITHDRAWED":
            return {
              color: "error",
              textColor: "white",
              icon: "mdi-close"
            }
          case "COUNTER_OFFER":
            return{
              color: "warning",
              textColor: "white",
              icon: "mdi-cog"
            }
        }
      }
      else {
        return {
          color: "grey",
          textColor: "white",
          icon: "mdi-sleep"
        }
      }
      return {
        color: "grey",
        textColor: "white",
        icon: "mdi-sleep"
      }
    },
    saleColor(){
      let sale= this.propertyState.sale;
      if(sale){
        switch(sale.status) {
          case "PENDING":
            return {
              color: "grey",
              textColor: "white",
              icon: "mdi-sleep"
            };
          case "IN_PROGRESS":
            return {
              color: "accent",
              textColor: "white",
              icon: "mdi-cog"
            }
          case "SOLD":
            return {
              color: "success",
              textColor: "white",
              icon: "mdi-check"
            }
          case "ARCHIVED":
            return {
              color: "grey",
              textColor: "white",
              icon: "mdi-archive"
            }
        }}
      else{
        return {
          color: "grey",
          textColor: "white",
          icon: "mdi-sleep"
        }
      }
      return {
        color: "grey",
        textColor: "white",
        icon: "mdi-sleep"
      }
    }

  },
  data(){
    return{
      offerStates:EOfferStatus,
      editNotes:false,
    }
  },
}
</script>

<style scoped>

</style>