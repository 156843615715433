<script>
export default {
  props: {
    appointments: Array,
  },
  computed: {
    events() {
      return this.appointments.map((appointment) => ({
        title: appointment.subject,
        start: new Date(appointment.start),
        end: new Date(appointment.end),
        color: this.getColor(appointment.appointmentType),
      }));
    },
  },
  methods: {
    getColor(appointmentType) {
      switch (appointmentType) {
        case "VIEWING":
          return "blue";
        case "INSPECTION":
          return "green";
        case "HANDOVER":
          return "orange";
        case "RETURN":
          return "red";
        default:
          return "grey";
      }
    },
  },
  data: () => ({
    type: "month",
    types: ["month", "week", "day"],
    weekday: [0, 1, 2, 3, 4, 5, 6],
    weekdays: [
      { title: "Mon - Sun", value: [1, 2, 3, 4, 5, 6, 0] },
      { title: "Mon - Fri", value: [1, 2, 3, 4, 5] },
    ],
    value: [new Date()],
  }),
};
</script>

<template>
  <div>
    <v-sheet class="d-flex my-5 justify-space-between" color="transparent">
      <v-row class="d-flex" justify="space-between">
        <v-col cols="12" sm="auto">
          <v-btn
            class="ma-1"
            @click="type = 'month'"
            icon="mdi-view-module"
            :color="type === 'month' ? 'grey-darken-2' : ''"
          >
          </v-btn>
          <v-btn
            class="ma-1"
            @click="type = 'week'"
            icon="mdi-view-week"
            :color="type === 'week' ? 'grey-darken-2' : ''"
          >
          </v-btn>
          <v-btn
            class="ma-1"
            @click="type = 'day'"
            icon="mdi-view-day"
            :color="type === 'day' ? 'grey-darken-2' : ''"
          >
          </v-btn>
        </v-col>
        <v-col cols="12" sm="auto">
          <v-select
            v-model="weekday"
            :items="weekdays"
            item-title="title"
            item-value="value"
            class="ma-2"
            label="Wochentage"
            variant="outlined"
            dense
            density="compact"
            hide-details
          ></v-select>
        </v-col>
      </v-row>
    </v-sheet>
    <v-sheet>
      <v-calendar
        ref="calendar"
        v-model="value"
        :events="events"
        :view-mode="type"
        :weekdays="weekday"
      ></v-calendar>
    </v-sheet>
  </div>
</template>

<style scoped></style>
