<template>
<div>

  <v-table v-if="dataAndFacts">
    <tbody class="text-left">
    <tr>
      <td>Label</td>
      <td v-text="name || '-'"></td>
    </tr>
      <tr>
        <td>ObjektTyp</td>
        <td ><span v-text="dataAndFacts.propertyType || '-'"></span></td>
      </tr>
      <tr>
        <td>Nutzungsart</td>
        <td><EnumDisplay :value="dataAndFacts.usageType" :enum-object="usageTypes"/></td>
      </tr>
      <tr>
        <td>Baujahr</td>
        <td v-text="dataAndFacts.constructionYear || '-'"></td>
      </tr>

      <tr>
        <td>Letzte Modernisierung</td>
        <td><span v-text="dataAndFacts.lastRefurbishmentYear || '-'"></span></td>
      </tr>
      <tr v-if="dataAndFacts.rented">
        <td>Rented</td>
        <td><v-icon color="accent">mdi-sticker-check</v-icon></td>
      </tr>
    <tr>
      <td>
        Jährliche Mieteinnahmen
      </td>
      <td>
        <CurrencyDisplay :number="dataAndFacts.grossPotentialIncome"/>
      </td>
    </tr>
      <tr v-if="dataAndFacts.ownerOccupation">
        <td>Durch Eigentümer bewohnt</td>
        <td><v-icon color="accent">mdi-sticker-check</v-icon></td>
      </tr>
    <tr class="font-weight-black">
      <td>
        Kaufpreis
      </td>
      <td>
        <CurrencyDisplay :number="dataAndFacts.saleGrossPrice"/>
      </td>
    </tr>
    <tr>
      <td>
        Provision
      </td>
      <td>
        <PercentDisplay :number="dataAndFacts.courtageSalePercent"/>
      </td>
    </tr>

    </tbody>
  </v-table>
</div>
</template>

<script>
import CurrencyDisplay from "@/components/helpers/CurrencyDisplay";
import PercentDisplay from "@/components/helpers/PercentDisplay";
import EnumDisplay from "@/components/helpers/EnumDisplay";
import EUsageType from "@/components/enums/EUsageType";
export default {
  name: "PropertyDataAndFacts",
  components: { EnumDisplay, PercentDisplay, CurrencyDisplay},
  props:{
    dataAndFacts:Object,
    name:String,
  },
  data(){
    return{
      usageTypes:EUsageType
    }
  }
}
</script>

<style scoped>

</style>