<script>
import {acceptOffer, counterOffer, declineOffer, pendingOffer} from "@/services/saleProcess/offer.service";

export default {
  name: "OfferActionButtonsOwner",
  methods:{
    async accept() {
      if (confirm("Möchten Sie das Angebot wirklich annehmen?\nDer Käufer wird benachrichtigt.")) {
        if (this.propertyId && this.userId) {
          let status = await acceptOffer(this.propertyId,this.userId);
          this.$emit('changeStatus',status)
        }
      }
    },
    async deny() {
      if (confirm("Möchten Sie das angebot wirklich ablehnen?\nDer Käufer wird benachrichtigt.")) {
        if (this.propertyId && this.userId){
          let status = await declineOffer(this.propertyId,this.userId);
          this.$emit('changeStatus',status)
        }
      }
    },
    async pedingOffer() {
      if(confirm("Möchten Sie das Angebot auf wartend setzen?\nDer Käufer wird benachrichtigt.")){
        if(this.propertyId && this.userId){
          let status = await pendingOffer(this.propertyId,this.userId);
          this.$emit('changeStatus',status)
        }
      }
    },
    async counter(){
      let value= prompt("Geben Sie ein Gegenangebot ein.");
      value=value.replaceAll(',','');
      value =value.replaceAll('.','');
      if(value){
        let offer = await counterOffer(this.propertyId,this.userId, value);
        this.$emit('changeOffer',offer);
      }
    }

  },
  emits:['changeStatus'],
  props:{
    offerId:Number,
    propertyId:Number,
    userId:Number
  }
}
</script>

<template>
  <div class="pa-5 d-flex flex-wrap" style="width:100%">
    <v-btn class="mb-1" size="small" block color="success" @click="accept">Angebot annehmen</v-btn>
    <v-btn class="mb-1" size="small" block color="warning" @click="counter">Gegenangebot</v-btn>
    <v-btn class="mb-1" size="small" block variant="outlined" color="red" @click="deny">Angebot ablehnen</v-btn>

    <v-btn  size="small" block variant="outlined" color="grey" @click="pedingOffer">Angebot auf wartend setzen</v-btn>
  </div>
</template>

<style scoped>

</style>