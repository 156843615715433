<template>
<v-list-item class="my-2" align="left" lines="3">
  <template #prepend>
    <UserAvatar :user="interestedUser"/>
  </template>
  <template #title><span v-text="interestedUser.name"></span></template>
  <template #subtitle><div>
    <p><span v-text="interestedUser.email"></span> <span v-text="interestedUser.buyIntention"></span>/10</p>
    <p v-text="interestedUser.note"></p>
  </div></template>
  <template #append>
    <v-icon v-if="!interestedUser.hasLoggedIn">mdi-ghost</v-icon>
  </template>
</v-list-item>
</template>

<script>
import InterestedUserResponse from "@/models/interest/interestedUserResponse";
import UserAvatar from "@/components/user/Avatar";

export default {
  name: "InterestedUserListItem",
  components: {UserAvatar},
  props:{
    interestedUser: InterestedUserResponse
  }
}
</script>


<style scoped>

</style>