<template>
  <div style="width: fit-content; position: relative">
    <v-avatar
      size="large"
      style="border: 4px solid"
      :style="{ borderColor: randomColor }"
      color="white"
    >
      <UserAvatar size="44" :user="participant.user" />
    </v-avatar>
    <v-icon
      class="rounded-circle pa-3 bg-accent"
      size="x-small"
      style="position: absolute; top: -5px; right: -5px"
      v-if="participant.host"
      >mdi-crown</v-icon
    >
  </div>
</template>

<script>
import UserAvatar from "@/components/user/Avatar.vue";

export default {
  name: "MeetingParticipantAvatar",
  components: { UserAvatar },
  data() {
    return {
      // Initialize random color
      randomColor: this.getRandomColor(),
    };
  },
  props: {
    participant: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getRandomColor() {
      // Generate random color in hexadecimal format
      return "#" + Math.floor(Math.random() * 16777215).toString(16);
    },
  },
};
</script>

<style scoped></style>
