<script>
import {useAuthUserStore} from "@/stores/modules/auth.module";
import MandateContract from "@/components/property/contract/MandateContract.vue";
import UpdateMandateContractDialog from "@/components/property/contract/UpdateMandateContractDialog.vue";
import SignMandateContract from "@/components/property/contract/SignMandateContract.vue";
import {getContract} from "@/services/saleProcess/contract.service";
import {isOwnerOfProperty} from "@/services/user.service";

export default {
  name: "MandateContractWrapper",
  components: {SignMandateContract, UpdateMandateContractDialog, MandateContract},
  data(){
    return{
      status:null,
      currentUserIsOwner:false,
      contract:null
    }
  },
  async created() {

    this.contract = await getContract(this.propertyId);
    this.status=this.contract.status;
    this.currentUserIsOwner = await isOwnerOfProperty(this.propertyId);
  },
  props:{
    edit:Boolean,
    propertyId:String
  },
  setup(){
    const authStore = useAuthUserStore();
    const hasRole = authStore.hasRole(["ROLE_ADMIN", "ROLE_AGENT"]);
    return{
      hasRole
    }
  },
}
</script>

<template>
  <div>
  <MandateContract :contract="contract"/>
  <UpdateMandateContractDialog @update="(data) => {contract=data}" v-if="hasRole && status!='SIGNED' && edit" :property-id="propertyId"/>
    <SignMandateContract v-if="(hasRole || currentUserIsOwner) && status!='SIGNED'" :propertyId="propertyId"/>
  </div>
</template>

<style scoped>

</style>
