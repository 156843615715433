<script>
import KreaNumberInput from "@/components/KreaNumberInput.vue";
import KreaInput from "@/components/KreaInput.vue";
import EVerkaufsumfang from "@/components/enums/EVerkaufsumfang";
import EContractStatus from "@/components/enums/EContractStatus";
import SelectEnum from "@/components/form/SelectEnum.vue";
import DocumentUploadAsFormField from "@/components/property/documents/DocumentUploadAsFormField.vue";
import {
  getContractOfProperty,
  updateContract,
} from "@/services/saleProcess/contract.service";
import SelectUser from "@/components/form/SelectUser.vue";
export default {
  name: "CreateMandateContract",
  components: {
    SelectUser,
    SelectEnum,
    KreaInput,
    KreaNumberInput,
    DocumentUploadAsFormField,
  },
  props: {
    propertyId: String,
  },
  data() {
    return {
      loading: false,
      showUser: false,
      verkaufsumfang: EVerkaufsumfang,
      status: EContractStatus,
      request: {
        verkaufsumfang: null,
        purchasePriceMin: 0,
        sellerId: null,
        status: null,
        expirationDate: null,
        file: null,
      },
    };
  },
  created() {
    this.getContract();
  },
  methods: {
    async save() {
      console.log("Save", this.request);
      let data = await updateContract(this.request);
      this.request = data;
      this.$emit("update", data);
    },
    async getContract() {
      this.loading = true;
      let data = await getContractOfProperty(this.propertyId);
      this.request = data;
      this.loading = false;
    },
  },
};
</script>

<template>
  <v-form>
    <v-row>
      <v-col cols="12">
        <SelectEnum
          :data="verkaufsumfang"
          v-model="request.verkaufsumfang"
          label="Verkaufsumfang"
        ></SelectEnum>
      </v-col>
      <v-col cols="12">
        <KreaNumberInput
          v-model="request.purchasePriceMin"
          label="Kaufpreis ab"
        ></KreaNumberInput>
      </v-col>
      <v-col cols="12">
        <KreaInput
          type="date"
          v-model:input-value="request.expirationDate"
          label="Gültig bis:"
        ></KreaInput>
      </v-col>
      <v-col cols="12">
        <SelectEnum
          :data="status"
          v-model="request.status"
          label="Status"
        ></SelectEnum>
      </v-col>
      <v-col cols="12">
        <SelectUser
          v-if="!loading"
          label="Verkäufer"
          v-model="request.sellerId"
        >
          <template #no-data>
            <slot name="no-data">Kein User gefunden</slot>
          </template>
        </SelectUser>
      </v-col>
      <v-col>
        <DocumentUploadAsFormField
          :tags="['mandatecontract']"
          :propertyId="propertyId"
          label="Mandatsvertrag auswählen"
          v-model="request.file"
        />
      </v-col>
      <v-col cols="12">
        <v-btn color="accent" block @click="save">Speichern</v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<style scoped></style>
