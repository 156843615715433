<template>
<v-list-item class="my-5">
  <template #prepend>
    <v-avatar v-text="index"></v-avatar>
  </template>
  <template #title>
    <span>

      <template v-for="(segment, index) in segments" :key="index">
        <span v-if="typeof segment === 'string'" v-html="segment"></span>
        <component v-else :is="segment.type" v-bind="segment.props" />
      </template>
    </span>
  </template>
  <template #append>
    <LogbookClickableUser :id="entry.authorId" button></LogbookClickableUser>
  </template>
  <template #subtitle>
    <span v-text="timestamp"></span>
  </template>
</v-list-item>
</template>

<script>
import LogbookClickableBuilding from "@/components/logbook/LogbookClickableBuilding.vue";
import LogbookClickableUser from "@/components/logbook/LogbookClickableUser.vue";
// import other components here

export default {
  name: "LogbookEntry",
  props:{
    entry: Object,
    index:Number
  },
  computed: {
    segments() {
      const patterns = [
        { regex: /\[building id=(\d+) label="([^"]*)"\]/g, type: 'LogbookClickableBuilding', props: ['id', 'label'] },
        { regex: /\[user id=(\d+) label="([^"]*)"\]/g, type: 'LogbookClickableUser', props: ['id', 'label'] },
        // add other patterns here
      ];
      let lastIndex = 0;
      const segments = [];
      for (const pattern of patterns) {
        let match;
        while ((match = pattern.regex.exec(this.entry.text)) !== null) {
          if (match.index > lastIndex) {
            segments.push(this.entry.text.slice(lastIndex, match.index));
          }
          const props = {};
          pattern.props.forEach((prop, index) => {
            props[prop] = match[index + 1];
          });
          segments.push({ type: pattern.type, props });
          lastIndex = pattern.regex.lastIndex;
        }
      }
      if (lastIndex < this.entry.text.length) {
        segments.push(this.entry.text.slice(lastIndex));
      }
      console.log("Segments", segments)
      return segments;
    },
    timestamp() {
      let moment = require('moment');
      return moment(this.entry.timestamp).format("LLL")
    }
  },
  components: {
    LogbookClickableBuilding,
    LogbookClickableUser
    // register other components here
  }
}
</script>

<style scoped>

</style>