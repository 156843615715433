<template>
  <v-autocomplete
    prepend-inner-icon="mdi-magnify"
    v-model="selectedItem"
    :items="autocompleteItems"
    :label="label"
    variant="outlined"
    :density="density"
    v-model:search="searchText"
    :loading="loading"
    item-value="mapbox_id"
    @update:modelValue="selectItem"
    @keydown.enter="pressEnter"
    item-title="name"
    no-filter
    @update:search="fetchItems"
    clearable
  >
    <template #selection="{ item }">
      <div class="text-truncate"><span v-text="item.raw.name"></span></div>
    </template>
    <template #no-data>
      <span v-if="alreadySearched">Nichts gefunden</span>
    </template>
    <template #item="{ props, item }">
      <v-list-item v-bind="props" :title="item.raw.name">
        <template #subtitle>
          <span v-text="item.raw.place_formatted"></span>
        </template>
      </v-list-item>
    </template>
  </v-autocomplete>
</template>

<script>
import { suggestSearch } from "@/services/map.service";

import { retreiveItem } from "@/services/map.service";
import { useMapStore } from "@/stores/modules/map.module";

export default {
  name: "SearchComponent",
  setup() {
    const mapStore = useMapStore();

    return { mapStore };
  },
  props: {
    modelValue: {
      type: Array,
      default: null,
    },
    label: {
      type: String,
      default: "Ort",
    },
    density: {
      type: String,
      default: "compact",
    },
    adress: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      searchText: null,
      alreadySearched: false,
      loading: false,
      selectedItem: null,
      autocompleteItems: [],
      debounceTimeout: null,
    };
  },
  computed: {
    modelValueC: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  methods: {
    pressEnter() {
      this.fetchItems(this.searchText);
    },
    async selectItem(selectedItem) {
      this.loading = true;
      let data = await retreiveItem(selectedItem);
      console.log("Data", data);
      if (data && data.features && data.features.length > 0) {
        if (this.$vuetify.display.mdAndUp) {
          this.mapStore.flyToGeometry(data.features[0].geometry);
          this.mapStore.addMarker(data.features[0].geometry.coordinates);
        }
        this.$emit("update:adress", data.features[0].properties.full_address);
        console.log("Adress", data.features[0].properties.full_adress);
        this.modelValueC = data.features[0];
      }
      this.loading = false;
    },
    async fetchItems(searchQuery) {
      console.log(searchQuery);
      console.log("Fetch", searchQuery);
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(async () => {
        try {
          if (!searchQuery) return;
          const response = await suggestSearch(searchQuery);
          if (!response.suggestions && response.suggestions.length <= 0) {
            console.log("He");
            return;
          }
          this.autocompleteItems = response.suggestions; // Assuming API returns an array of items
        } catch (error) {
          console.error("Error fetching items:", error);
        } finally {
          this.alreadySearched = true;
        }
      }, 800); // Debounce time to prevent too many API call
    },
  },
};
</script>
