<template>
  <QAndAChat :property-id="1" :user-id="3"/>
</template>

<script>

import ApiService from "@/services/api.service"
import QAndAChat from "@/components/property/qAndA/QAndAChat.vue";
export default {
  name: "TestComponent",
  components: {QAndAChat},
  methods:{
    getUnauthRequest(){
      ApiService.get("test/unauthorized");
    }
  }
}
</script>

<style scoped>

</style>