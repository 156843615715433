<template>
<span v-text="number || '-'"></span>%
</template>

<script>
export default {
  name: "PercentDisplay",
  props:{
    number:Number
  }
}
</script>

<style scoped>

</style>