<script>

import {getSingleUser} from "@/services/user.service";
import UserAvatar from "@/components/user/Avatar.vue";

export default {
  name: "LogbookClickableUser",
  components: {UserAvatar},
  props:{
    id:String,
    label:String,
    button:Boolean
  },
  methods:{
    async load() {
      if (!this.data) {
        this.loading = true;
        const data = await getSingleUser(this.id);
        this.data = data;
        this.loading = false;
      }
    }
  },
  data(){
    return {
      loading:false,
      data:null,
      show: false,
    }
  }
}
</script>

<template>
  <v-menu v-model="show" offset-y>
    <template #activator="{ props }">
      <a v-if="!button" @click="load" class="text-accent cursor-pointer" v-bind="props">
        <span>{{label}}</span>
      </a>
      <v-icon v-else @click="load" v-bind="props">mdi-badge-account</v-icon>
    </template>
    <v-skeleton-loader v-if="loading" type="list-item-two-line" />
    <v-list v-else>
      <v-list-item>
        <template #prepend>
          <UserAvatar user="data" size="40px"/>
        </template>
        <v-list-item-title>{{data.name}}</v-list-item-title>
        <v-list-item-subtitle>{{data.email}}</v-list-item-subtitle>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<style scoped>

</style>