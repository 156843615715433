<template>
  <v-form @submit.prevent="createUser">
    <div class="d-flex justify-space-between" style="gap: 5px">
      <v-text-field label="Vorname" v-model="request.firstName"></v-text-field>
      <v-text-field label="Nachname" v-model="request.lastName"></v-text-field>
    </div>
    <v-text-field label="Email" v-model="request.email"></v-text-field>
    <NotifyUser v-model="notificationMail" />
    <div class="d-flex justify-space-between">
      <v-btn variant="text" @click="cancel">Abbrechen</v-btn
      ><v-btn type="submit" color="accent" text="Benutzer anlegen"></v-btn>
    </div>
  </v-form>
</template>

<script>
import { CreateUserRequestSimple } from "@/models/create/CreateUserRequestSimple";
import { inviteUser } from "@/services/user.service";
import NotificationMail from "@/models/mail";
import NotifyUser from "@/components/mail/NotifyUser.vue";

export default {
  name: "CreateUserSimple",
  components: { NotifyUser },
  data() {
    return {
      loading: false,
      request: new CreateUserRequestSimple({}),
      notificationMail: new NotificationMail({}),
    };
  },
  props: {
    predefinedEmail: String,
  },
  watch: {
    predefinedEmail(newVal) {
      console.log("Newval", newVal);
      if (newVal.includes("@")) this.request.email = newVal;
    },
  },
  emits: ["userCreated"],
  methods: {
    async createUser() {
      this.loading = true;
      this.request.customText = this.notificationMail.text;
      this.request.sendEmail = this.notificationMail.send;
      let user = await inviteUser(this.request);
      this.$emit("userCreated", user);
      this.loading = false;
    },
    cancel() {
      this.$emit("cancel");
    },
  },
};
</script>

<style scoped></style>
