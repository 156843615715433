

const EAppointmentType =  {
    VIEWING:{en:"Viewing",de:"Besichtigung"},
    MEETING:{en:"Meeting",de:"Meeting"},
    NOTARY_APPOINTMENT:{en:"Notary appointment",de:"Notartermin"},
    HANDOVER:{en:"Handover",de:"Übergabe"},
    OTHER:{en:"Other",de:"Andere"}
}
export default EAppointmentType;
