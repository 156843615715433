<script>
import {usePropertyStore} from "@/stores/modules/property.module";
import PropertyItemUser from "@/components/property/PropertyItemUser.vue";
import {useMapStore} from "@/stores/modules/map.module";

export default {
  name: "OwnedProperties",
  components: {PropertyItemUser},
  setup(){
    const propertyStore = usePropertyStore();
    const mapStore= useMapStore();
    return{
      propertyStore,
      mapStore
    }
  },
  computed:{
    properties() {
      return this.propertyStore.ownedProperties.content;
    },
    page(){
      return this.propertyStore.ownedProperties.currentPage;
    },
    loading(){
      return this.propertyStore.ownedProperties.loading;
    },
    userLocation(){
      return this.mapStore.getUserPositionLngLat;
    }
  },
  created() {
    this.loadPage(0);
  },
  methods:{
    loadPage(page){
      this.propertyStore.fetchOwnedProperties(page,this.userLocation);
    }
  }
}
</script>

<template>
  <v-skeleton-loader
      v-if="loading"
      type="image"
  >
  </v-skeleton-loader>
  <div v-else-if="!properties || properties.length<=0">
    <div class="text-center">Keine eigenen Immobilien</div>
  </div>
  <div v-else style="gap:15px" class="d-flex justify-start flex-column">
    <PropertyItemUser show-saved v-for="property of properties" :key="property.id" :property="property"/>
    <v-pagination :start="0" v-model="page.number" :length="page.totalPages" @update:modelValue="loadPage"/>
  </div>

</template>

<style scoped>

</style>