<template>
  <v-dialog v-model="show" max-width="500px">
    <template v-slot:activator="{ props: activatorProps }">
      <slot name="activator" :activator="activatorProps"> </slot>
    </template>
    <v-sheet>
      <CreateBuilding
        @success="success"
        @close="close"
        :property="property"
        :click-event="clickEvent"
      />
    </v-sheet>
  </v-dialog>
</template>

<script>
import CreateBuilding from "@/components/property/CreateBuilding.vue";
export default {
  emits: ["success"],
  name: "CreateBuidlingPopup",
  components: { CreateBuilding },
  props: {
    property: Object,
    clickEvent: Object,
  },
  data() {
    return {
      show: false,
    };
  },
  methods: {
    success() {
      this.$emit("success");
      this.close();
    },
    close() {
      this.show = false;
    },
  },
};
</script>

<style scoped></style>
