export default class Appointment {
    constructor({subject, description, location, start, end, participants, propertyId, appointmentType}) {
        this.subject = subject;
        this.description = description;
        this.location = location;
        this.start = start;
        this.end = end;
        this.participants = participants;
        this.propertyId = propertyId;
        this.appointmentType = appointmentType;
    }

    validate() {
        const errors = [];

        if (!this.subject || this.subject.trim() === "") {
            errors.push("Subject must not be blank");
        }
        if (!this.start) {
            errors.push("Start time must not be blank");
        }
        if (!this.end) {
            errors.push("End time must not be blank");
        }
        if(this.start && this.end && this.start > this.end){
            errors.push("Start time must be before end time");
        }
        if (!this.participants || this.participants.length === 0) {
            errors.push("Participants list must not be empty");
        }
        if (!this.propertyId) {
            errors.push("Property ID must not be blank");
        }
        if (!this.appointmentType) {
            errors.push("Appointment type must not be blank");
        }

        return errors;
    }
}