<template>
  <section>
    <v-card>
      <v-list
        class="text-left d-flex flex-column flex-wrap ga-2"
        rounded
        v-if="filteredFiles.length != 0"
      >
        <v-card-text style="max-height: 500px">
          <div v-for="(file, index) of filteredFiles" :key="file.id">
            <v-list-item
              @click="selected = file"
              color="accent"
              class="py-2"
              :active="selected?.id === file.id"
              rounded
              :title="file.filename"
            >
              <template v-slot:prepend>
                <v-avatar color="grey-darken-1">
                  <v-icon :icon="getIcon(file.type)" color="white"></v-icon>
                </v-avatar>
              </template>
              <template v-slot:append>
                <v-btn
                  icon="mdi-file-download-outline"
                  color="grey-darken-3"
                  size="small"
                  variant="flat"
                  @click="downloadFile(file.id, file.filename)"
                ></v-btn>
              </template>

              <v-chip
                v-for="tag of file.tags"
                :key="tag.key"
                size="x-small"
                color="grey"
                class="mr-1 mb-1"
              >
                {{ tag.name }}
              </v-chip>
            </v-list-item>
            <v-divider
              class="mx-6 mt-2"
              v-if="index < filteredFiles.length - 1"
            ></v-divider>
          </div>
        </v-card-text>
      </v-list>

      <div class="my-16 text-center" v-else>Noch keine Dokumente vorhanden</div>
    </v-card>
  </section>
</template>

<script>
import ApiService from "@/services/api.service";
import { useAuthUserStore } from "@/stores/modules/auth.module";

export default {
  setup() {
    const authStore = useAuthUserStore();
    const hasRole = authStore.hasRole(["ROLE_ADMIN", "ROLE_AGENT"]);
    return {
      hasRole,
    };
  },
  props: {
    modelValue: Object,
    files: Array,
    allTags: Array,
  },
  name: "DocumentSelectFromList",
  data() {
    return {
      bytes: [],
      newTags: [],
      filterTags: [],
    };
  },
  computed: {
    selected: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    filteredFiles() {
      return this.files;
    },
  },
  methods: {
    getIcon(fileType) {
      //   console.log("fileType:", fileType);
      switch (fileType) {
        case "application/pdf":
          return "mdi-file-pdf-box";
        case "image/png":
          return "mdi-file-image";
        case "image/jpg":
          return "mdi-file-image";
        case "image/jpeg":
          return "mdi-file-image";
        case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
          return "mdi-file-document";
        default:
          return "mdi-file";
      }
    },
    async downloadFile(id, name) {
      try {
        const response = await ApiService.post(
          "file/get/bytes",
          { text: id },
          { responseType: "blob" }
        );
        const blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", name); // set the file name and extension here
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error(error);
      }
    },
    async saveTags(id) {
      //   console.log("selectedTags:", this.newTags, id);
      try {
        const response = await ApiService.post("file/tags/set", {
          id: id,
          tags: this.newTags,
        });
        // console.log(this.newTags);
        if (response.status === 200) {
          this.updateFileTags(id, this.newTags);
        }
      } catch (error) {
        console.error(error);
      }
    },
    updateFileTags(id, newTags) {
      const file = this.files.find((file) => file.id === id);
      if (file) {
        file.tags = newTags.map((tagKey) =>
          this.allTags.find((tag) => tag.key === tagKey)
        );
      }
    },
    openDialog(file) {
      this.newTags = file.tags.map((tag) => tag.key);
    },
  },
};
</script>
