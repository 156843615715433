import ApiService from "@/services/api.service";

export async function getContractOfProperty(id) {
    let response = await ApiService.get("mandate/get/" + id+"/edit");
    return response.data;
}

export async function updateContract(request){
    let response = await ApiService.post("mandate/update",request);
    return response.data;
}

export async function getContract(id){
    const response = await ApiService.get("mandate/get/"+id+"/response");
    return response.data;
}

export async function signContract(id){
    const response = await ApiService.get("mandate/sign?estateId="+id);
    return response.data;
}

export async function getContractStatus(id){
    const response = await ApiService.get("mandate/get/"+id+"/status");
    return response.data;

}