<script>
import {getSingleUser} from "@/services/user.service";
import SimpleUserListItem from "@/components/user/SimpleUserListItem.vue";

export default {
  name: "ExclusivePropertyBadge",
  components: {SimpleUserListItem},
  data() {
    return {
      user: null,
      show: false,
      loaded: false,
      loading: false,
    }
  },
  props: {
    userId: Number
  },
  methods: {
    async fetchUser() {
      console.log("hi")
      if(this.loaded) return;
      this.loading = true;
      let response = await getSingleUser(this.userId);
      this.user = response;
      this.loading = false;
      this.loaded=true;
    }
  }
}
</script>

<template>
  <v-menu v-model="show" offset-y>
    <template v-slot:activator="{ props }">
      <v-chip @click="fetchUser" v-bind="props" size="small" color="accent"><span>Exclusive</span></v-chip>
    </template>
    <v-skeleton-loader v-if="loading" type="list-item-three-line"></v-skeleton-loader>
    <v-list v-else-if="user">

      <SimpleUserListItem :user="user"/>
    </v-list>
  </v-menu>
</template>

<style scoped>

</style>