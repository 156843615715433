<script>
import CurrencyDisplay from "@/components/helpers/CurrencyDisplay.vue";
import DateDisplay from "@/components/helpers/DateDisplay.vue";
import DocumentItem from "@/components/property/documents/DocumentItem.vue";
import UserChip from "@/components/helpers/UserChip.vue";
import EContractStatus from "@/components/enums/EContractStatus";

export default {
  name: "MandateContract",
  components: { UserChip, DocumentItem, DateDisplay, CurrencyDisplay },

  data() {
    return { EContractStatus };
  },
  props: {
    contract: Object,
  },
  created() {},
  methods: {},
};
</script>

<template>
  <v-table rounded class="my-5 px-5" v-if="contract">
    <tbody class="text-left">
      <tr>
        <td>Verkaufsumfang</td>
        <td v-if="contract.verkaufsumfang">{{ contract.verkaufsumfang }}</td>
        <td v-else>N/A</td>
      </tr>
      <tr>
        <td>Kaufpreis ab</td>
        <td><CurrencyDisplay :number="contract.purchasePriceMin" /></td>
      </tr>
      <tr>
        <td>Verkäufer</td>
        <td><UserChip :user="contract.seller" /></td>
      </tr>
      <tr>
        <td>Status</td>
        <td>
          {{
            contract.status && EContractStatus[contract.status]
              ? EContractStatus[contract.status].de
              : "N/A"
          }}
        </td>
      </tr>
      <tr>
        <td>Ablaufdatum</td>
        <td>
          <DateDisplay
            v-if="contract.expirationDate"
            :date="contract.expirationDate"
          />
          <span v-else>N/A</span>
        </td>
      </tr>
      <tr>
        <td>Dokument</td>
        <td class="d-flex justify-content-start align-center">
          <DocumentItem
            :showIcon="false"
            hide-tags
            color="grey"
            :max-width="500"
            v-if="contract.contract"
            :file="contract.contract"
          />
          <span v-else>N/A</span>
        </td>
      </tr>
    </tbody>
  </v-table>
</template>

<style scoped></style>
