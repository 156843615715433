<script>
import {getMyPropertyStatus} from "@/services/saleProcess/process.service";
import SimpleUserListItem from "@/components/user/SimpleUserListItem.vue";
import UserChip from "@/components/helpers/UserChip.vue";
import EnumDisplay from "@/components/helpers/EnumDisplay.vue";
import ESaleProcessStatus from "@/components/enums/ESaleProcessStatus";
import SimpleHeading from "@/components/SimpleHeading.vue";
export default {
  name: "SaleProcessOfProperty",
  components: {SimpleHeading, EnumDisplay, UserChip, SimpleUserListItem},
  data(){
    return{
      object:null,
      show:false,
      saleStatus:ESaleProcessStatus
    }
  },
  props:{
    propertyId:String
  },
  async created() {
    try{
      this.object = await getMyPropertyStatus(this.propertyId);
    }catch (e) {
      console.log(e)
    }
  }
}
</script>

<template>
  <SimpleHeading v-if="object" title="Prozessstatus" subtitle="Einige Informationen zum laufenden Verkaufsprozess"/>
 <v-table v-if="object">
   <tbody>
   <tr>
     <td>
       Status
     </td>
     <td>
       <EnumDisplay :value="object.saleStatus" :enum-object="saleStatus"/>
     </td>
   </tr>
   <tr>
     <td>
       Eigentümer
     </td>
     <td>
       <SimpleUserListItem :user="object.owner"/>
     </td>
   </tr>
   <tr v-for="(makler,$index) of object.makler" :key="makler.id">
     <td>
       Makler {{$index+1}}
     </td>
     <td>
       <UserChip :user="makler"/>
     </td>
   </tr>
   <tr v-if="object.notes">
      <td>
        Notizen
      </td>
      <td>
        {{object.notes}}
      </td>
   </tr>
   </tbody>
 </v-table>
</template>

<style scoped>

</style>
