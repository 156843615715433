<template>
  <slot>
    <v-btn
      v-tooltip:top="{
        text: 'Details bearbeiten',
        openDelay: 1000,
      }"
      color="accent"
      size="small"
      :href="'/property/' + propertyId + '/edit'"
      tile
      rounded
      icon
    >
      <v-icon size="small" class="">mdi-pencil</v-icon>
    </v-btn>
  </slot>
</template>
<script>
export default {
  name: "EditPropertyButton",
  props: {
    propertyId: Number,
  },
};
</script>
