<template>
  <v-navigation-drawer
    border="none"
    :permanent="$vuetify.display.mdAndUp ? true : false"
    class="pb-5 pr-5"
    color="transparent"
    v-model="leftDrawer"
    elevation="0"
    @click:outside="toggleLeftDrawer()"
  >
    <v-sheet style="height: 100%" class="rounded-e">
      <v-list nav class="text-left">
        <v-list-item
          active-class="active-tab"
          to="/dashboard"
          @click="$vuetify.display.smAndDown ? toggleLeftDrawer() : null"
          link
          prepend-icon="mdi-monitor-dashboard"
        >
          <template #title>Dashboard</template>
        </v-list-item>
        <v-list-item
          v-if="isRoleUser"
          to="/properties"
          active-class="active-tab"
          @click="$vuetify.display.smAndDown ? toggleLeftDrawer() : null"
          link
          prepend-icon="mdi-home"
        >
          <template #title>Immobilien</template>
        </v-list-item>
        <v-list-item
          v-if="isRoleMakler || isRoleAdmin"
          to="/agent/properties"
          active-class="active-tab"
          @click="$vuetify.display.smAndDown ? toggleLeftDrawer() : null"
          link
          prepend-icon="mdi-home"
        >
          <template #title>Immobilien</template>
        </v-list-item>
        <!-- <v-list-item link prepend-icon="mdi-handshake">
          <template #title>Partnernetzwerk</template>
        </v-list-item> -->

        <!-- <v-list-item link prepend-icon="mdi-file-cabinet">
          <template #title>Verträge</template>
        </v-list-item> -->

        <!-- <v-list-item link prepend-icon="mdi-chat">
          <template #title>Nachrichten</template>
        </v-list-item> -->
        <v-list-item
          to="/"
          active-class="active-tab"
          @click="$vuetify.display.smAndDown ? toggleLeftDrawer() : null"
          link
          prepend-icon="mdi-map"
        >
          <template #title>Karte</template>
        </v-list-item>
        <v-list-item
          to="/profile"
          active-class="active-tab"
          link
          prepend-icon="mdi-cog"
          @click="$vuetify.display.smAndDown ? toggleLeftDrawer() : null"
        >
          <template #title>Einstellungen</template>
        </v-list-item>

        <v-list-item
          v-if="isRoleAdmin"
          to="/admin"
          active-class="active-tab"
          @click="$vuetify.display.smAndDown ? toggleLeftDrawer() : null"
          link
          prepend-icon="mdi-account-cog"
        >
          <template #title>Verwaltung</template>
        </v-list-item>
      </v-list>
      <div style="position: absolute; bottom: 50px" class="mx-5">
        <!-- <v-btn
          style="float: left"
          small
          variant="text"
          color="grey"
          flat
          class="text-capitalize"
          ><v-icon small>mdi-help</v-icon> Hilfe</v-btn
        > -->
        <br /><v-btn
          style="float: left"
          small
          variant="text"
          color="red"
          class="text-capitalize"
          @click="$router.push('/logout')"
          ><v-icon class="mr-1" small>mdi-logout</v-icon> Logout</v-btn
        >
      </div>
    </v-sheet>
  </v-navigation-drawer>
</template>

<script>
import { useNavStore } from "@/stores/modules/nav.module";
import { useAuthUserStore } from "@/stores/modules/auth.module.js";
import { set } from "lodash";

export default {
  name: "LeftDrawer",
  setup() {
    const navStore = useNavStore();
    const authStore = useAuthUserStore();
    const isRoleAdmin = authStore.hasRole(["ROLE_ADMIN"]);
    const isRoleMakler = authStore.hasRole(["ROLE_MAKLER"]);
    const isRoleUser = authStore.hasRole(["ROLE_USER"]);

    return {
      navStore,
      authStore,
      isRoleAdmin,
      isRoleMakler,
      isRoleUser,
    };
  },
  methods: {
    toggleLeftDrawer() {
      this.navStore.toggleLeftDrawer();
    },
  },
  computed: {
    leftDrawer: {
      get() {
        return this.navStore.leftDrawer;
      },
      set(value) {
        set(this.navStore, "leftDrawer", value);
      },
    },
  },
  data() {
    return {
      open: true,
    };
  },
};
</script>

<style scoped>
@media (max-width: 600px) {
  .navigation-drawer {
    width: 100% !important;
  }
}
.v-list-item:hover {
  background-color: rgba(147, 177, 149, 0.15);
}
.active-tab {
  background-color: rgba(147, 177, 149, 0.08);
}
</style>
