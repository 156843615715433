import AuthService from "@/services/auth.service";
// Pinia Store
import { defineStore } from "pinia";
import { useNotificationStore } from "@/stores/modules/notification.module";
import NotificationElephant from "@/models/notification";
import ApiService from "@/services/api.service";
import axios from "axios";
import host from "@/services/host";
const API_URL =host+'/api/auth/';

const user = (localStorage.getItem("user")!='undefined' && localStorage.getItem("user")) ? JSON.parse(localStorage.getItem("user")) : null;
console.log(user);
const state = () =>
  user
    ? { status: { loggedIn: true, identified: false }, user }
    : { status: { loggedIn: false, identified: false }, user: null };
export const useAuthUserStore = defineStore("authUser", {
  state: state,

  // getters
  getters: {
    isAdmin() {
      if (
        this.user &&
        this.user.roles &&
        this.user.roles.includes("ROLE_ADMIN")
      )
        return true;
      else return false;
    },
  },

  // actions
  actions: {
    hasRole(roles) {
      if (!this.user || !this.user.roles || this.user.roles.length === 0) {

        return false;
      }
      return roles.some((role) => this.user.roles.includes(role));
    },
    login(user) {
      return axios.post(API_URL + 'signin', user).then(
        (user) => {
          this.loginSuccess(user.data);
        },
        (error) => {
          console.log("Error while login", error);
          this.loginFailure();
          ApiService.handleElephantError(error?.response);
        }
      );
    },
    refreshLogin(token) {
      return AuthService.refreshToken(token).then(
        (user) => {
          console.log("Response", user);
          this.loginSuccess(user);
          return Promise.resolve(user);
        },
        (error) => {
          console.log("Error refreshing token", error.response.data);
          this.loginFailure();
          return Promise.reject(error);
        }
      );
    },
    logout() {
      //AuthService.logout();
      this.status.loggedIn = false;
      this.user = null;
      localStorage.removeItem("user");
    },
    register(user) {
      return AuthService.register(user).then(
        (response) => {
          this.registerSuccess();
          if (response) return Promise.resolve(response.data);
          else return null;
        },
        (error) => {
          console.log("Error", error);
          this.registerFailure();
          return Promise.reject(error);
        }
      );
    },
    loginSuccess(user) {
      this.status.loggedIn = true;
      this.user = user;
      localStorage.setItem('user', JSON.stringify(user));
      console.log("Set local User Storage");
    },
    loginFailure() {
      this.status.loggedIn = false;
      this.user = null;
    },
    registerSuccess() {
      const notificationStore = useNotificationStore();
      notificationStore.push(
        new NotificationElephant({
          title: "Registrierung erfolgreich.",
          message: null,
          color: "SUCCESS",
        })
      );
      this.status.loggedIn = false;
    },
    registerFailure() {
      this.status.loggedIn = false;
    },
    updateUser() {
      if (this.user == null) return null;
    },
    verifyEmail() {
      this.user.emailVerified = true;
      this.updateLoggedInUser(this.user);
    },

    updateAvatar(base64String){
      let user = JSON.parse(localStorage.getItem("user"));
      user.avatar=base64String;
        localStorage.setItem("user", JSON.stringify(user));
        this.user = user;
    },
    updateLoggedInUser(newUser) {
      let user = JSON.parse(localStorage.getItem("user"));
      let rolesTmp = user.roles;
      let merged = { ...user, ...newUser };

      merged.roles = rolesTmp;
      localStorage.setItem("user", JSON.stringify(merged));

      this.user = merged;
    },
  },
});
